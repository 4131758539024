import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import EventListener from "react-event-listener";

class ConfirmPrompt extends PureComponent {
    onEscapeKey = (event) => {
        if (event.key === "Escape") {
            this.cancelOnClick();
        }
    };

    okOnClick = () => {
        this.props.ok();
    };

    cancelOnClick = () => {
        this.props.cancel();
    };

    render() {

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.close}
            >
                <DialogTitle id="confirm-prompt-title">
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-prompt-message">
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        key="confirm-prompt-ok"
                        id="confirm-prompt-ok"
                        variant="contained"
                        onClick={this.okOnClick}
                        primary="true"
                    >OK</Button>

                    <Button
                        key="confirm-prompt-cancel"
                        id="confirm-prompt-cancel"
                        variant="text"
                        onClick={this.cancelOnClick}
                    >Cancel</Button>

                </DialogActions>
            </Dialog>

        );
    }
}

ConfirmPrompt.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    ok: PropTypes.func,
    cancel: PropTypes.func,
};


export default ConfirmPrompt;


// <React.Fragment>
//     <EventListener target="window" onKeyUp={this.onEscapeKey}/>
//     <Dialog title={this.props.title}
//             open={this.props.open}
//             actions={actions}
//             modal
//     >
//         <div>
//             {this.props.message}
//         </div>
//     </Dialog>
// </React.Fragment>
