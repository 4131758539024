import * as types from "../constants/actionConstants";
import * as system from "../constants/systemConstants";
import axios from "axios";
import {getToken} from "./loginActions";


export function uploadDocumentRequest(form) {
    return (dispatch) => {
        let authorizationToken = getToken();
        let config = {
            headers: {
                Authorization: "Bearer " + authorizationToken
            }
        };

        axios.post(system.QD_BATCH_URL, form, config)
            .then(response => dispatch(uploadSuccess(response)))
                .catch(error => dispatch(uploadFail(error)));
    };
}

export function uploadSuccess(response) {
    return {
        type: types.BATCH_UPLOAD_SUCCESS,
        response,
    };
}

export function uploadFail(error) {
    return {
        type: types.BATCH_UPLOAD_FAIL,
        message: error.message,
        response: error.response
    };
}

export function uploadClear(response) {
    return {
        type: types.BATCH_UPLOAD_CLEAR,
        response,
    };
}
