import * as types from "../constants/actionConstants";

const initialState = [];

function notesReducer(state = initialState, action) {
    switch(action.type) {
        case types.QUESTIONNAIRE_NOTES_GET_SUCCESS:
            return action.list;

        default:
            return state;
    }
}

export default notesReducer;
