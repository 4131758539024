import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import reducers from "../reducers";
import createDebounce from "redux-debounced";

const store = function configureStore(initialState) {
    return createStore(reducers, initialState, compose(
        applyMiddleware(createDebounce(), thunk, reduxImmutableStateInvariant()),
        window.devToolsExtension ? window.devToolsExtension() : f => f
    ));

};


export default store;
