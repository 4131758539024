import React, { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


export default function ArgoRadioGroupHook(props) {
    const [error, setError] = useState(null);

    const fileName = props.fileName === undefined ? "NoFileName" : props.fileName;
    const fieldName = props.fieldName === undefined ? "NoFieldName" : props.fieldName;

    const index = props.index === undefined ? "" : props.index; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-ArgoRadioGroupHook-" + fieldName + index;

    const label = props.label === undefined ? "" : props.label;

    const fontSize = props.fontSize === undefined ? "18px" : props.fontSize;

    const value = props.value === undefined ? "" : props.value;

    const autoFocus = props.autoFocus === undefined ? true : false;

    const onChange = props.onChange === undefined ? function () {} : props.onChange;

    const display = props.display === undefined ? "inline" : "none";

    const style = props.style === undefined ? {} : props.style;

    let checked = [false, false];
    if(props.checked === true) checked = [true, false];
    else if(props.checked === false) checked = [false, true];

    const radioButtonList = props.radioButtonList === undefined ? ["Yes","No"] :  props.radioButtonList;
    const radioButtonLabel = props.radioButtonLabel === undefined ? "" :  props.radioButtonLabel;
    const labelPlacement = props.labelPlacement === undefined ? "start" :  props.labelPlacement;


    const handleOnChange = (event) => {
        onChange(event, fieldName, index)
    };


    return (
        <FormControl sx={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
            {label !== "" ?
                <FormLabel
                    id={uniqueIdentifier + "-form-label"}
                    name={uniqueIdentifier + "-form-label"}
                    sx={{fontSize: fontSize, color: "black", "&.Mui-focused": {color: 'black'}}}>{label}
                </FormLabel>
                : ""
            }
            <RadioGroup
                id={uniqueIdentifier}
                name={uniqueIdentifier}
                value={value}
                row
                onChange={handleOnChange}
            >
                {
                    radioButtonList.map((row, index) => {
                        return (<FormControlLabel key={uniqueIdentifier + index} labelPlacement={labelPlacement}  value={index} label={radioButtonLabel === "" ? row : row[radioButtonLabel]} control={<Radio/>} checked={checked[index]}/>);
                    })
                }
            </RadioGroup>
        </FormControl>
    );
}


// sx={{ marginBottom: 4}}

// onKeyDown={handleOnKeyDown}
// sx={{display: display}}

// const textfieldStyling = {
//     "& label": {
//         //display: "none",
//         //visibility: "hidden",
//         //whiteSpace: "normal",
//         color: "secondary.main",
//         marginLeft: "65%",
//         width: 100,
//         "&.Mui-focused": {
//             marginLeft: 0
//             //display: "none"
//         }
//     }
//     // "& legend": {
//     //   display: "none"
//     // }
// };


// const handleOnKeyDown = (event) => {
//     onChange(event, props.fieldName)
// };
