import * as types from "../constants/actionConstants";

const initialState = {};

function questionnaireReferenceReducer(state = initialState, action) {
    switch (action.type) {
        case types.QUESTIONNAIRE_REFERENCE_GET_SUCCESS:
            return {
                ...action.referenceObj
            };

        case types.QUESTIONNAIRE_REFERENCE_CLEAR:
            return {
                ...{}
            };

        default:
            return state;
    }
}

export default questionnaireReferenceReducer;
