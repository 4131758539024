import * as types from "../constants/actionConstants";

const initialState = {
    template: {
        templateKey: "",
        name: "",
        nameQualifier: null,
        description: "",
        script: "",
        metadata: {}
    },
    groupKeys: [],
    options: {
        hasScript: false,
        hasDescription: false,
        validate: [],
        isValidating: false
    }
};

export default function categoryEditorReducer(state = initialState, action) {
    let addGroup;
    switch (action.type) {
        case types.CATEGORY_EDITOR_INITIALIZE:
            return {
                ...state,
                ...action.payload
            };

        case types.CATEGORY_EDITOR_UPDATE:
            return {
                ...state,
                template: {
                    ...state.template,
                    ...action.payload.template
                },
                options: {
                    ...state.options,
                    ...action.payload.options
                }
            };

        case types.CATEGORY_EDITOR_CLEAR:
            return {
                ...initialState
            };

        case types.CATEGORY_EDITOR_ADD_GROUP:// eslint-disable-line no-case-declarations
            addGroup = [
                ...state.groupKeys,
                action.group
            ];
            return {
                ...state,
                groupKeys: addGroup
            };

        case types.GROUP_TEMPLATE_CREATE_SUCCESS:
            addGroup = [
                ...state.groupKeys,
                action.groupTemplate.templateKey
            ];
            return {
                ...state,
                groupKeys: addGroup
            };

        case types.CATEGORY_EDITOR_REMOVE_GROUP:// eslint-disable-line no-case-declarations
            let removeGroup = state.groupKeys.filter((element, index) => {
                return index !== action.index;
            });
            return {
                ...state,
                groupKeys: removeGroup
            };

        case types.CATEGORY_EDITOR_REORDER:// eslint-disable-line no-case-declarations
            let groupKeys = [...state.groupKeys];
            let target = (action.isUp)? -1 : 1;
            target = target + action.currentIndex;

            if (target < 0) { target=0;}
            else if (target > groupKeys.length -1) {target = groupKeys.length -1;}

            let removed = groupKeys.splice(action.currentIndex, 1);
            groupKeys.splice(target, 0, removed[0]);
            return {
                ...state,
                groupKeys
            };

        case types.CATEGORY_EDITOR_VALUE_EXISTS:
            return {
                ...state,
                options: {
                    ...state.options,
                    validate: action.list,
                    isValidating: false
                }
            };
        case types.CATEGORY_EDITOR_VALUE_VALIDATING:
            return {
                ...state,
                options: {
                    ...state.options,
                    isValidating: true
                }
            };


        default:
            return state;
    }
}
