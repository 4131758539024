import * as types from "../constants/actionConstants";

const initialState = [];

// AN array of all statistics for all published version of a templete key
function statisticsAllReducer(state = initialState, action) {
    switch(action.type) {
        case types.QUESTIONNAIRE_STATISTICS_GET_ALL_SUCCESS:
            return action.list;

        default:
            return state;
    }
}

export default statisticsAllReducer;
