import * as types from "../constants/actionConstants";
import TemplateApi from "../api/TemplateApi";
import * as TEMPLATE from "../constants/templateConstants";
import * as portalTypes from "../portal/constants/actionConstants";


const initializeCategoryEditorCreate = (template) => {
    if (!template) {
        template = {
            metadata: {}
        };
    }

    return {
        type: types.CATEGORY_EDITOR_INITIALIZE,
        payload: {
            template: {
                templateKey: (template.templateKey)? template.templateKey : "",
                name: (template.name)? template.name : "",
                nameQualifier: (template.nameQualifier)? template.nameQualifier : null,
                description: (template.description)? template.description : "",
                metadata: {
                    ...template.metadata
                }
            },
            groupKeys: [],
            options: {
                hasDescription: !!template.description,
                createMode: true,
                validate: [],
                isValidating: false
            }
        }
    };
};

const initializeCategoryEditorUpdate = (template) => {return {
        type: types.CATEGORY_EDITOR_INITIALIZE,
        payload: {
            template: template,
            groupKeys: template.groupKeys,
            options: {
                hasScript: !!template.script,
                hasDescription: !!template.description,
                createMode: false,
                validate: [],
                isValidating: false
            }
        }
    };
};

const updateCategoryTemplate = (template, options) => {
    return (dispatch) => {
        dispatch({type: types.CATEGORY_EDITOR_UPDATE, payload: {template, options}});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT})
    };
};

const clearCategoryEditor = () => {
    return (dispatch) => {
        dispatch({type: types.CATEGORY_EDITOR_CLEAR});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT})
    };
};

const checkValue = (filter) => {
    return (dispatch) => {
        dispatch({type: types.CATEGORY_EDITOR_VALUE_VALIDATING});
        dispatch(checkQuestionCategoryExists(filter))
    };
};

const checkQuestionCategoryExists = (filter) => {
    let thunk = (dispatch) => {
        TemplateApi.checkQuestionCategoryExists(filter).then((data) => {
            dispatch({type: types.CATEGORY_EDITOR_VALUE_EXISTS, list: data.collection});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__categoryExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};


const validateCategoryNameAndQualifierUnique = (name, qualifier) => {

    let nameQualifier = qualifier === null ? "" : qualifier;

    let thunk = (dispatch) => {
        TemplateApi.checkTemplateNameAndQualifierExists(TEMPLATE.TYPE.CATEGORY, name, nameQualifier).then((data) => {
            dispatch({type: types.CATEGORY_EDITOR_VALUE_EXISTS, list: data.collection, depth: 0});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__questionExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};

// ---------------------------------------------------------------------------------------------------------------------
// Categories can have associated groups
// ---------------------------------------------------------------------------------------------------------------------

const addCategoryGroup = (group) => {
    return (dispatch) => {
        dispatch({type: types.CATEGORY_EDITOR_ADD_GROUP, group: group});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT})
    };
};

const removeCategoryGroup = (index) => {
    return (dispatch) => {
        dispatch({type: types.CATEGORY_EDITOR_REMOVE_GROUP, index: index});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT})
    };
};

const reorderCategoryGroup = (isUp, currentIndex) => {
    return (dispatch) => {
        dispatch({type: types.CATEGORY_EDITOR_REORDER, isUp: isUp, currentIndex: currentIndex});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT})
    };
};


export {
    initializeCategoryEditorCreate,
    initializeCategoryEditorUpdate,
    updateCategoryTemplate,
    clearCategoryEditor,
    checkValue,
    validateCategoryNameAndQualifierUnique,
    addCategoryGroup,
    removeCategoryGroup,
    reorderCategoryGroup
};
