import * as types from "../constants/actionConstants";
import * as TEMPLATE from "../constants/templateConstants";
import {getList} from "./templateListActions";


const setFilter = (pageable, filter, templateType, templateMode) => {

    let newFilter = filter;
    newFilter.pageNumber = 1;

    return (dispatch) => {
        // Note: user constant "TEMPLATE.FILTER[templateMode][templateType]" to get the name of the filter to update
        dispatch({type: types.SET_FILTER, filterName: TEMPLATE.FILTER[templateMode][templateType], filter: newFilter});
        dispatch(getList(pageable, filter, templateType, templateMode));
    };
};

const clearFilter = (pageable, filter, templateType, templateMode) => {

    let newFilter = filter;

    newFilter.pageNumber = 1;
    newFilter.title = "";
    newFilter.nameQualifier = "";
    newFilter.updated = "";
    newFilter.updatedBy = "";
    newFilter.status = "";
    newFilter.type = "";
    newFilter.integrationType = "";
    newFilter.questionType = "";
    newFilter.outstanding = false;
    newFilter.mode = ""; // used by the selectExistingFilter to know which templateType (category, group or question)
    newFilter.list = []; // used by the selectExistingFilter to store DDLB items

    return (dispatch) => {
        // Note: user constant "TEMPLATE.FILTER[templateMode][templateType]" to get the name of the filter to update
        dispatch({type: types.CLEAR_FILTER, filterName: TEMPLATE.FILTER[templateMode][templateType], filter: newFilter});
        dispatch(getList(pageable, filter, templateType, templateMode));
    };
};

export {
    setFilter,
    clearFilter
};
