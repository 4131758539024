import * as types from "../constants/actionConstants";

const initialState = {
    template: {
        id: "",
        name: "",
        trackable: false
    },
    questionKeys: [],
    options: {
        validate: [],
        isValidating: false
    }
};

export default function tagEditorReducer(state = initialState, action) {
    let addQuestion;
    switch (action.type) {
        case types.TAG_EDITOR_INITIALIZE:
            return {
                ...state,
                ...action.payload
            };

        case types.TAG_EDITOR_UPDATE:

            let obj = {
                ...state,
                template: {
                    ...state.template,
                    ...action.payload.template
                },
                questionKeys: action.payload.template.questionKeys === undefined ? [] : action.payload.template.questionKeys,
                options: {
                    ...state.options,
                    ...action.payload.options
                }
            };
            return obj;

        case types.TAG_EDITOR_CLEAR:
            return {
                ...initialState
            };


        case types.TAG_EDITOR_ADD_QUESTION:// eslint-disable-line no-case-declarations

            addQuestion = [
                ...state.questionKeys,
                action.question
            ];
            return {
                ...state,
                questionKeys: addQuestion
            };

        case types.TAG_EDITOR_REMOVE_QUESTION:// eslint-disable-line no-case-declarations
            let removeQuestion = state.questionKeys.filter((element, index) => {
                return index !== action.index;
            });
            return {
                ...state,
                questionKeys: removeQuestion
            };


        case types.TAG_EDITOR_VALUE_EXISTS:
            return {
                ...state,
                options: {
                    ...state.options,
                    validate: action.list,
                    isValidating: false
                }
            };

        case types.TAG_EDITOR_VALUE_VALIDATING:
            return {
                ...state,
                options: {
                    ...state.options,
                    isValidating: true
                }
            };

        default:
            return state;
    }
}
