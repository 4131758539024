import * as types from "../constants/actionConstants";
import theme from "../style/globalTheme";

const initialState = theme;

export default function portalThemeReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_THEME:
            return Object.assign({}, { theme: action.payload } );
        default:
            return state;
    }
}