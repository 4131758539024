import {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

export default function SetPortalUrlHook(props) {

    const url = props.url;

    const navigate = useNavigate();

    useEffect(() => {
        let currURL =  new URL(window.location.href);

        if (currURL.pathname === "/") {
            navigate(url);
        }
    });
}