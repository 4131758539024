import * as types from "../constants/actionConstants";

export const setTheme = (theme) => {
    return {
        type: types.SET_THEME,
        payload: theme
    };
};

// export const updateTheme = (theme) => {
//     return (dispatch) => {
//         dispatch({
//             type: types.THEME_UPDATED,
//             payload: theme
//         });
//     };
// };