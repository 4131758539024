import * as types from "../constants/actionConstants";

const initialState = {
    loggedIn: false,
    message: "",
    loading: false,
    username: ""
};

function loginReducer(state = initialState, action) {
    switch (action.type) {
        case types.TOKEN_GET:
            return {
                loggedIn: false,
                loading: true,
                message: "",
                username: action.rememberMe? action.username : ""
            };

        case types.TOKEN_SET:
            return {
                loggedIn: true,
                loading: false,
                message: "",
                username: state.username
            };

        case types.TOKEN_CLEAR:
        case types.TOKEN_REJECT_LOGIN:
            return {
                loggedIn: false,
                loading: false,
                message: action.message,
                username: state.username
            };

        case types.TOKEN_INITIALIZE:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default loginReducer;
