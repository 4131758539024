import React, {Component} from 'react';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {TableGrid, TableView} from '../../containers';
import Fab from '@mui/material/Fab';
import AddIcon from "@mui/icons-material/Add";
import {openDrawer} from "../../actions/drawerActions";
import {getTemplateList, getTemplateReferences, updatePageAndGetList, updateSortAndGetList} from "../../actions/templateListActions";
import CategoryReviewDrawer, {categoryReviewDrawerProps} from "../drawers/CategoryReviewDrawer";
import CategoryCreateEditDrawer, {categoryCreateEditDrawerProps} from "../drawers/CategoryCreateEditDrawer";
import ReferencesDrawer, {referencesDrawerProps} from "../drawers/ReferencesDrawer";
import FilterPopOver from "../dialogs/filters/FilterPopOver";
import formatCopyText from "../../utilities/formatCopyText";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {QD_URL} from "../../constants/systemConstants";
import {QUESTIONNAIRE_COMPLEX} from "../../constants/questionnaireType";
import ArgoTemplateListCreateFabHook from "../common/ArgoTemplateListCreateFabHook";
import ArgoListTitleAndLoadingAnimationHook from "../common/ArgoListTitleAndLoadingAnimationHook";
import * as TEMPLATE from "../../constants/templateConstants";
import * as SYSTEM from "../../constants/systemConstants";
import * as metadataTemplateTypes from "../../constants/metadataTemplateTypes";


class CategoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filter: false,
            createMode: false,
            forcedDelay: false
        };
    }

    componentDidMount(){
        this.setState({loading: true}, () => this.props.getTemplateList(this.props.filter, TEMPLATE.TYPE.CATEGORY, TEMPLATE.MODE.CURRENT));
    }

    componentWillReceiveProps(nextProps) {

        // Called twice, first pass set filter false, then on second call list is loaded and loading animation can be stopped
        if (this.state.filter) {
            this.setState({filter: false});
        }
        else if (this.state.loading) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemCOnstants.js
                let delay = setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false});
            }
        }
    }

    // Used to test loading animation rendering
    delayedSetLoadingFalse = () => {
        this.setState({loading: false});
    }

    setLoadingStateTrue = (callback, parm1 = null, calledFrom = "") => {
        // If a parameter is passed then pass it to call back, should be the template of the item to be saved edit or created
        if (parm1 === null) {
            this.setState({loading: true, filter: false}, () => callback());
        } else {
            this.setState({loading: true, filter: false}, () => callback(parm1));
        }
    }

    openEdit = (templateKey, id, templateMode) => {
        if (!templateKey && this.props.match && this.props.match.params) {
            templateKey = this.props.match.params.templateKey;
        }
        this.props.openDrawer(categoryCreateEditDrawerProps, <CategoryCreateEditDrawer templateKey={templateKey} templateId={id} filter={this.props.filter} templateMode={templateMode} setLoadingState={this.setLoadingStateTrue}/>);
    };

    openCreate = (e) => {
        this.props.openDrawer(categoryCreateEditDrawerProps, <CategoryCreateEditDrawer createMode={true} setLoadingState={this.setLoadingStateTrue}/>);
        // if (e !== undefined) {
        //     e.preventDefault();
        // }
        this.setState({createMode: true});
    };

    openPreview = (templateKey, id, name, row, event) => {
        if (!templateKey && this.props.match && this.props.match.params) {
            templateKey = this.props.match.params.templateKey;
        }
        formatCopyText(row, event, "Category:");
        this.props.openDrawer(categoryReviewDrawerProps, <CategoryReviewDrawer templateKey={templateKey} templateId={id}/>);
    };

    viewReferences = (templateKey, id) => {
        this.props.getTemplateReferences(TEMPLATE.TYPE.CATEGORY, id);
        this.props.openDrawer(referencesDrawerProps, <ReferencesDrawer templateId={id} templateType={TEMPLATE.TYPE.CATEGORY} title="This category is referenced in the following:"/>);
    };

    onPageChange = (page, size, event) => {
        this.setState({loading: true, filter: true}, () => this.props.updatePageAndGetList(page, size, this.props.filter, TEMPLATE.TYPE.CATEGORY, TEMPLATE.MODE.CURRENT, event));
    };

    onSortChange = (sort, direction) => {
        this.setState({loading: true, filter: true}, () => this.props.updateSortAndGetList(sort, direction, this.props.filter, TEMPLATE.TYPE.CATEGORY, TEMPLATE.MODE.CURRENT));
    };

    render() {
        // const {
        //     categoryTemplateList
        // } = this.props;

        const customActions = [
            {
                func: this.openPreview,
                label: 'Review'
            },
            {
                func: this.openEdit,
                label: "Edit"
            },
            {
                func: this.viewReferences,
                label: "References"
            }
        ];

        // Used by the versions list and stored in the version filters
        let defaultActions = {
            edit: this.openEdit,
            create: this.openCreate,
            review: this.openPreview
        };

        return (
            <div id="CategoryList-container-div" style={{height: "100%", padding: "15px", marginTop: '64px'}}>

                <ArgoListTitleAndLoadingAnimationHook fileName="CategoryList" listTitle="Categories" loading={this.state.loading} filter={this.state.filter}/>

                <div style={{clear: "both"}}></div>

                <Card id="CategoryList-card"
                      variant="outlined"
                      sx={{border: "1px solid lightgray", height: "100%", boxShadow: "2px 4px whitesmoke"
                      }}>
                    <CardContent id="CategoryList-card-content">

                        <TableView
                            id="CategoryList-table-view"
                            templateType={TEMPLATE.TYPE.CATEGORY}
                            templateMode={TEMPLATE.MODE.CURRENT}
                            setLoadingState={this.setLoadingStateTrue}
                            filter={<FilterPopOver page="categories"
                                                   templateType={TEMPLATE.TYPE.CATEGORY}
                                                   templateMode={TEMPLATE.MODE.CURRENT}
                                                   filterName={TEMPLATE.FILTER.current.category}
                                                   submitValue={this.props.getTemplateList}
                                                   statuses={["All", "Draft", "Published"]}
                                                   setLoadingState={this.setLoadingStateTrue}
                            />}
                        >

                            <TableGrid
                                id="CategoryList-table-grid"
                                displayType={"category"}
                                dataSource={this.props.categoryTemplateList}
                                nameDrawer={this.openPreview}
                                link={QD_URL +"/category-editor/"}
                                customActions={customActions}
                                pageState = {this.props.filter}
                                onPageChange = {this.onPageChange}
                                sortState = {this.props.filter}
                                onSortChange = {this.onSortChange}
                                type={metadataTemplateTypes.CategoryTemplate}
                                templateType={TEMPLATE.TYPE.CATEGORY}
                                templateMode={TEMPLATE.MODE.CURRENT}
                                defaultActions={defaultActions}
                                showReferencesAndVersionsSameColumn={true}
                            />
                        </TableView>

                        <ArgoTemplateListCreateFabHook
                            fileName="CategoryList"
                            fieldName="fab"
                            hoverText="Create Tag"
                            onClick={this.openCreate}
                        />

                    </CardContent>

                </Card>

            </div>
        );
    }
}

CategoryList.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getTemplateList, getTemplateReferences, updatePageAndGetList, updateSortAndGetList, openDrawer}, dispatch);
}

function mapStateToProps(state) {
    return {
        categoryTemplateList: state.categoryTemplateList,
        filter: state.filter[TEMPLATE.FILTER.current.category] || {}  //Get correct filter from constant
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
