import * as types from "../constants/actionConstants";

const initialState = [];

function referencesReducer(state = initialState, action) {
    switch(action.type) {
        case types.REFERENCE_LOAD_SUCCESS:
            return [
                ...action.references.collection
            ];

        case types.REFERENCE_CLEAR:
            return initialState;

        default:
            return state;
    }
}

export default referencesReducer;
