import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import * as color from "../../constants/colorConstants";


// This fixes the DDLB transparency issue
// const theme = createTheme({
//     backgroundColor: "white"
// });


export default function ArgoSelectWithRenderValueHook(props) {
    const [isSelected, setIsSelected] = useState(false);

    const fileName = props.fileName === undefined ? "NoFileName" : props.fileName;
    const fieldName = props.fieldName === undefined ? "NoFieldName" : props.fieldName;

    const index = props.index === undefined ? "" : props.index; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-ArgoSelectWithRenderValueHook-" + fieldName + index;
    const uniqueIdentifierLabel = uniqueIdentifier + "-label";

    const variant = props.variant === undefined ? "standard" : props.variant;
    const label = props.label === undefined ? "" : props.label;

    const width = props.width === undefined ? "" : props.width;
    const height = props.height === undefined ? '' : props.height;

    const padBottomWhenSelected = props.padBottomWhenSelected === undefined ? false : props.padBottomWhenSelected;
    const paddingBottom = props.paddingBottom === undefined ? "0px" : props.paddingBottom;

    const autoFocus = props.autoFocus === undefined ? false : false;
    const onChange = props.onChange === undefined ? function () {} : props.onChange;
    const display = props.display === undefined ? "inline" : "none";
    const disabled = props.disabled === undefined ? false : props.disabled;

    const menuItems = props.menuItems === undefined ? [] : props.menuItems;

    const valueProperty = props.valueProperty === undefined ? "value" : props.valueProperty;
    const textProperty = props.textProperty === undefined ? "text" : props.textProperty;

    const defaultValue = props.defaultValue === undefined ? "" : props.defaultValue; // Default to first item in menuitem list menuItems[0][valueProperty] ????


    const handleChange = (event) => {

        setIsSelected(true);
        onChange(event, fieldName, menuItems[event.target.value][textProperty].props.title, index);
    };

    return (menuItems.length > 0 ?
        <FormControl variant={variant} sx={{width: width, paddingBottom: defaultValue === "" ? "7px" : paddingBottom}} disabled={disabled}>
            <InputLabel id={uniqueIdentifierLabel}>{label}</InputLabel>
            <Select
                id={uniqueIdentifier}
                name={uniqueIdentifier}
                labelId={uniqueIdentifierLabel}
                onChange={handleChange}
                defaultValue={defaultValue}
                renderValue={(selected) => (menuItems[selected][textProperty])}
            >
                {menuItems.map((item, index) => {
                    return (<MenuItem key={uniqueIdentifier + index} value={item[valueProperty]}>{item[textProperty]}</MenuItem>);
                })}
            </Select>
            <FormHelperText sx={{width: "14px"}}>&nbsp;</FormHelperText>
        </FormControl>
        :
        <div></div>
    );
}

// select property displayEmpty={true} may be needed
