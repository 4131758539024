import React, {Component} from 'react';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IntegrationCreateEditDrawer, {integrationCreateEditDrawerProps} from "../drawers/IntegrationCreateEditDrawer";
import IntegrationReviewDrawer, {integrationReviewDrawerProps} from "../drawers/IntegrationReviewDrawer";
import {TableView, TableGrid} from '../../containers';
import ConfirmPrompt from "../dialogs/ConfirmPrompt";
import FilterPopOver from "../dialogs/filters/FilterPopOver";
import ArgoTemplateListCreateFabHook from "../common/ArgoTemplateListCreateFabHook";
import ArgoListTitleAndLoadingAnimationHook from "../common/ArgoListTitleAndLoadingAnimationHook";
import {getTemplateList, updatePageAndGetList, updateSortAndGetList} from "../../actions/templateListActions";
import {openDrawer} from "../../actions/drawerActions";
import {deleteIntegration} from "../../actions/integrationActions";
import formatCopyText from "../../utilities/formatCopyText";
import {IntegrationTypesFilter} from "../../constants/integrationConstants";
import * as TEMPLATE from "../../constants/templateConstants";
import * as SYSTEM from "../../constants/systemConstants";


class IntegrationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteConfirmationDialogOpen: false,
            deleteName: "",
            deleteId: null,
            loading: false,
            filter: false
        };
    }

    componentDidMount(){
        this.setState({loading: true}, () => this.props.getTemplateList(this.props.filter, TEMPLATE.TYPE.INTEGRATION, TEMPLATE.MODE.ALL));
    }

    componentWillReceiveProps(nextProps) {

        // Called twice, first pass set filter false, then on second call list is loaded and loading animation can be stopped
        if (this.state.filter) {
            this.setState({filter: false});
        }
        else if (this.state.loading) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemCOnstants.js
                let delay = setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false});
            }
        }
    }

    // Used to test loading animation rendering
    delayedSetLoadingFalse = () => {
        this.setState({loading: false});
    }

    setLoadingStateTrue = (callback, parm1 = null, calledFrom = "") => {
        // If a prameter is passed then pass it to call back, should be the template of the item to be saved edit or created
        if (parm1 === null) {
            this.setState({loading: true, filter: false}, () => callback());
        } else {
            this.setState({loading: true, filter: false}, () => callback(parm1));
        }
    }

    openCreate = (e) => {
        this.setState({createMode: true});
        this.props.openDrawer(integrationCreateEditDrawerProps, <IntegrationCreateEditDrawer createMode={true} setLoadingState={this.setLoadingStateTrue}/>);
        //e.preventDefault();
    };

    openEdit = (placeHolder, id) => {
        this.props.openDrawer(integrationCreateEditDrawerProps, <IntegrationCreateEditDrawer id={id} filter={this.props.filter} setLoadingState={this.setLoadingStateTrue}/>); // placeHolder is just a spacer param
    };

    openPreview = (placeHolder, id, name, row, event) => {
        formatCopyText(row, event, "Integration:");
        this.props.openDrawer(integrationReviewDrawerProps, <IntegrationReviewDrawer id={id}/>); // placeHolder is just a spaceer param
    };

    onPageChange = (page, size, event, test) => {
        this.setState({loading: true, filter: true},() => this.props.updatePageAndGetList(page, size, this.props.filter, TEMPLATE.TYPE.INTEGRATION, TEMPLATE.MODE.ALL, event));
    };

    onSortChange = (sort, direction) => {
        this.setState({loading: true, filter: true}, () => this.props.updateSortAndGetList(sort, direction, this.props.filter, TEMPLATE.TYPE.INTEGRATION, TEMPLATE.MODE.ALL));
    };

    handleDelete = (id) => {
        let name = "";
        for (let i = 0; i <= this.props.integrationList.length; i++) {
            if (this.props.integrationList[i].id === id) {
                name = this.props.integrationList[i].name;
                break;
            }
        }
        this.setState({deleteConfirmationDialogOpen: true, deleteName: name, deleteId: id})
    };

    handleCloseDeleteConfirmationDialog = () => {
        this.setState({deleteConfirmationDialogOpen: false, deleteName: "", deleteId: null});
    };

    handleDeleteConfirmed = (event) => {
        this.props.deleteIntegration(this.state.deleteId, this.props.filter);
        this.handleCloseDeleteConfirmationDialog();
    };

    render() {

        const {integrationList} = this.props;

        // Add custom actions to row actions
        const customActions = [
            {
                func: this.openPreview,
                label: "Review"
            },
            {
                func: this.openEdit,
                label: "Edit"
            },
            {
                func: this.handleDelete,
                label: "Delete"
            }
        ];

        return (
            <div id="IntegrationList-container-div" style={{height: "100%", padding: "15px", marginTop: '64px'}}>

                <ArgoListTitleAndLoadingAnimationHook fileName="IntegrationList" listTitle="Integrations" loading={this.state.loading} filter={this.state.filter}/>

                <div style={{clear: "both"}}/>

                <Card id="IntegrationList-card" variant="outlined" sx={{border: "1px solid lightgray", height: "100%", boxShadow: "2px 4px whitesmoke", overflow: "auto"}}>

                    <CardContent id="IntegrationList-card-content">

                        <TableView
                            templateType={TEMPLATE.TYPE.INTEGRATION}
                            templateMode={TEMPLATE.MODE.ALL}
                            setLoadingState={this.setLoadingStateTrue}
                            filter={<FilterPopOver page="integrations"
                                                   templateType={TEMPLATE.TYPE.INTEGRATION}
                                                   templateMode={TEMPLATE.MODE.ALL}
                                                   filterName={TEMPLATE.FILTER.all.integration}
                                                   submitValue={this.props.getTemplateList}
                                                   integrationTypes={IntegrationTypesFilter}
                                                   showIntegrationTypes={true}
                                                   showQualifier={false}
                                                   setLoadingState={this.setLoadingStateTrue}

                            />}
                        >
                            <TableGrid
                                id="IntegrationsList-table-grid"
                                displayType={"integrations"}
                                dataSource={integrationList}
                                nameDrawer={this.openPreview}
                                customActions={customActions}
                                pageState={this.props.filter}
                                onPageChange={this.onPageChange}
                                sortState={this.props.filter}
                                onSortChange={this.onSortChange}
                                templateType={TEMPLATE.TYPE.INTEGRATION}
                                templateMode={TEMPLATE.MODE.ALL}
                                showQualifier={false}
                                showStatus={false}
                                showVersionCount={false}
                                showIntegrationType={true}
                                showGridActionVersions={false}
                                uniqueIdentifier={"id"}
                            />
                        </TableView>

                        <ArgoTemplateListCreateFabHook
                            fileName="IntegrationList"
                            fieldName="fab"
                            hoverText="Create Integration"
                            onClick={this.openCreate}
                        />

                        <ConfirmPrompt
                            open={this.state.deleteConfirmationDialogOpen}
                            title="Delete Integration"
                            message={"Are you sure you want to delete the integration named: " + this.state.deleteName + "?"}
                            ok={this.handleDeleteConfirmed.bind(this)}
                            cancel={this.handleCloseDeleteConfirmationDialog}
                        />

                    </CardContent>

                </Card>

            </div>
        );
    }
}

IntegrationList.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getTemplateList, updatePageAndGetList, updateSortAndGetList, openDrawer, deleteIntegration}, dispatch);
}

function mapStateToProps(state) {
    return {
        integrationList: state.integrationList,
        filter: state.filter[TEMPLATE.FILTER.all.integration] || {}  //Get correct filter from constant
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationList);




