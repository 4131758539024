import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Drawer from "@mui/material/Drawer";
import {closeDrawer} from '../../actions/drawerActions';

class MainDrawer extends Component {

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
    };

    render() {

        const {
            view,
            open,
            closeDrawer,
            props
        } = this.props;

        if (!view) {
            return null;
        }

        let viewComponent = (view)? React.cloneElement(view, {closeDrawer: closeDrawer}) : null;

        return (
            <Drawer
                open={open}
                anchor={props.anchor}
                docked={this.props.docked}
                opensecondary={this.props.opensecondary}
                PaperProps={{
                    sx: { width: props.width },
                }}
            >
                {viewComponent}
            </Drawer>
        );
    }
}

MainDrawer.propTypes = {
    closeDrawer: PropTypes.func,
    open: PropTypes.bool,
    view: PropTypes.node,
    props: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return {
        closeDrawer: bindActionCreators(closeDrawer, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        open: state.openCloseSecondaryDrawer.open,
        view: state.openCloseSecondaryDrawer.view,
        props: state.openCloseSecondaryDrawer.props
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainDrawer);
