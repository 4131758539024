import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from '../../actions/drawerActions';
import {deleteQuestionnaire} from "../../actions/questionnaireTemplateActions";
import ArgoAppBar from "../common/ArgoAppBar";
import ArgoToolbarHook from "../common/ArgoToolbarHook";
import ArgoTextFieldHook from "../common/ArgoTextFieldHook";
import * as TEMPLATE from "../../constants/templateConstants";


class QuestionnaireDeleteDraftDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes : "",
            noteErrorText: " ",
        };
    }

    componentDidMount() {
        this.checkForErrorsOnPage();
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "DELETE":
                this.props.deleteQuestionnaire(this.props.deleteDrawerTemplate.id, {notes: this.state.notes}, this.props.deleteDrawerTemplate.templateKey, this.props.filter,  this.props.versionsFilter);
                break;
            case "CANCEL":
                this.props.closeDrawer();
                break;
            // no default
        }
    }

    onTextChange = (event, fieldName) => {
        this.setState({notes: event.target.value}, () => {this.checkForErrorsOnPage()});
    };

    checkForErrorsOnPage() {
        // Remove spaces to ensure there is a note, DELETE Button will be diabled until there are no errors on the page
        let noteErrorText = " ";
        if (this.state.notes.trim() <= 0) {
            noteErrorText = "Delete note required";
        }
        this.setState({noteErrorText: noteErrorText});
    }

    render() {

        return (
            <div>
                <ArgoAppBar
                    title={"Delete Draft:"}
                    titleFor={this.props.deleteDrawerTemplate.name}
                    showMenuIconButton={false}
                    noIcon={true}
                    isDrawer={true}
                    width={questionnaireDeleteDraftDrawerProps.width}
                />

                <div style={{height: '64px'}}></div>

                <ArgoToolbarHook
                    fileName="QuestionnaireDeleteDraftDrawer"
                    fieldName="delete-or-cancel"
                    buttonTitles={["DELETE", "CANCEL"]}
                    onClick={this.handleToolbarButtonClick}
                    toolbarErrorText={this.state.noteErrorText}
                    width={questionnaireDeleteDraftDrawerProps.width}
                />

                <div id="QuestionnaireDeleteDraftDrawer-page-div"
                     style={{height: "100%", paddingLeft: "30px", paddingRight: "30px"}}>
                    <ArgoTextFieldHook
                        fileName="QuestionnaireDeleteDraftDrawer"
                        fieldName="notes"
                        label="UNote *"
                        value={this.state.notes}
                        multiline={true}
                        autoFocus={true}
                        onChange={this.onTextChange}
                        errorText={this.state.noteErrorText}
                    />
                </div>

                <div>&nbsp;</div>

                <div style={{fontSize: "12px"}}>* Indicates required field</div>

            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDrawer: bindActionCreators(closeDrawer, dispatch),
        deleteQuestionnaire: bindActionCreators(deleteQuestionnaire, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        deleteDrawerTemplate: state.openCloseSecondaryDrawer.template,
        filter: state.filter["questionnaires"],
        versionsFilter: state.filter[TEMPLATE.FILTER.versions.questionnaire]
    };
}

const questionnaireDeleteDraftDrawerProps = {
    opensecondary: true,
    width: "50%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireDeleteDraftDrawer);
export {questionnaireDeleteDraftDrawerProps};
