import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from '../../actions/drawerActions';
import {retireQuestionnaire} from "../../actions/questionnaireTemplateActions";
import ArgoAppBar from "../common/ArgoAppBar";
import ArgoToolbarHook from "../common/ArgoToolbarHook";
import ArgoTextFieldHook from "../common/ArgoTextFieldHook";
import * as TEMPLATE from "../../constants/templateConstants";


// Questionnaires published with a future date can be retired, or questionnaires past their published end date (InActive = true)
class QuestionnaireRetireInActiveDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes : "",
            errorMessage: "Retire note required.",
        };
    }

    componentDidMount() {
        this.checkForErrorsOnPage()
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "RETIRE":
                this.props.retireQuestionnaire(this.props.questionnaireRow.id, {notes: this.state.notes}, this.props.questionnaireRow.templateKey, this.props.filter, this.props.versionsFilter);
                break;
            case "CANCEL":
                this.props.closeDrawer();
                break;
            // no default
        }
    }

    onTextChange = (event, fieldName) => {
        this.setState({notes: event.target.value}, () => {this.checkForErrorsOnPage()});
    };

    checkForErrorsOnPage() {
        // Remove spaces to ensure there is a note, RETIRE Button will be diabled until there are no errors on the page
        let errorMessage = " ";
        if (this.state.notes.trim() <= 0) {
            errorMessage = "Retire note required.";
        }
        this.setState({errorMessage: errorMessage, toolbarErrorText: errorMessage});
    }

    render() {

        return (
            <div>
                <ArgoAppBar
                    title={"Retire:"}
                    titleFor={this.props.retireDrawerTemplate.name}
                    showMenuIconButton={false}
                    noIcon={true}
                    isDrawer={true}
                    width={questionnaireRetireInActiveDrawerProps.width}
                />

                <div style={{height: '64px'}}></div>

                <ArgoToolbarHook
                    fileName="QuestionnaireRetireInActiveDrawer"
                    fieldName="retire-or-cancel"
                    buttonTitles={["RETIRE", "CANCEL"]}
                    onClick={this.handleToolbarButtonClick}
                    leftButtonDisabled={this.state.errorMessage !== " "}
                    toolbarErrorText={this.state.toolbarErrorText}
                    width={questionnaireRetireInActiveDrawerProps.width}
                />

                <div id="QuestionnaireRetireInActiveDrawer-page-div"
                     style={{paddingLeft: "20px", paddingRight: "20px", marginTop: "30px"}}>
                    <ArgoTextFieldHook
                        fileName="QuestionnaireRetireInActiveDrawer"
                        fieldName="notes"
                        label="Note"
                        value={this.state.notes}
                        multiline={true}
                        autoFocus={true}
                        onChange={this.onTextChange}
                        errorText={this.state.errorMessage}
                    />
                </div>

            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDrawer: bindActionCreators(closeDrawer, dispatch),
        retireQuestionnaire: bindActionCreators(retireQuestionnaire, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        retireDrawerTemplate: state.openCloseSecondaryDrawer.template,
        filter: state.filter["questionnaires"],
        versionsFilter: state.filter[TEMPLATE.FILTER.versions.questionnaire]
    };
}

const questionnaireRetireInActiveDrawerProps = {
    opensecondary: true,
    width: "55%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireRetireInActiveDrawer);
export {questionnaireRetireInActiveDrawerProps};
