import * as types from "../constants/actionConstants";

const initialState = {
    integration: {
        disabled: false,
        id: "",
        name: "",
        integrationType: "",
        integrationUrl: "",
        apiKey1: "",
        apiKey2: ""
    },
    options: {
        validate: [],
        isValidating: false
    }
};

export default function integrationEditorReducer(state = initialState, action) {

    switch (action.type) {
        case types.INTEGRATION_EDITOR_INITIALIZE:
            return {
                ...action.payload
            };

        case types.INTEGRATION_EDITOR_GET_SUCCESS:

            return {
                ...state,
                integration: {
                    ...action.integration
                },
                options: {
                    ...state.options,
                }
            };


        case types.INTEGRATION_EDITOR_UPDATE_STORE:
            return {
                ...state,
                integration: {
                    ...state.integration,
                    ...action.payload.integration
                },
                options: {
                    ...state.options,
                    ...action.payload.options
                }
            };

        case types.INTEGRATION_EDITOR_CLEAR:
            return {
                ...initialState
            };


        case types.INTEGRATION_EDITOR_CHECK_NAME_FOR_DUP:
            return {
                ...state,
                options: {
                    ...state.options,
                    validate: action.integration,
                    isValidating: false
                }
            };

        case types.INTEGRATION_EDITOR_VALUE_VALIDATING:

            return {
                ...state,
                options: {
                    ...state.options,
                    isValidating: true
                }
            };

        default:
            return state;
    }
}
