import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import QuestionViewerExpandable from "../questions/QuestionViewerExpandable";
import ArgoReviewTemplate from "../../common/ArgoReviewTemplate";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import AnswerViewer from "../answers/AnswerViewer";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import {getGroupTemplate} from "../../../actions/groupTemplateActions";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import statusFormatter from "../../../utilities/StatusFormatter";
import * as metadataUtils from "../../../utilities/metadata";
import * as TEMPLATE from "../../../constants/templateConstants";



class GroupReview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openQuestionReview: false,
            selectedQuestionInfoKey: "",
            groupLoaded: false,
            questionExpandedArray: [], // For questions assigned to group
            isExpanded: true,
            buttonTitleSelected: "",
            hidePortalPreview: false,
            questionTemplates: {},
            portalPreviewLoaded: false,
            questionsLoaded: false,
            showGroupBackButton: true,
            initialScroll: false
        };
    }

    componentDidMount() {
        if (this.props.templateKey || this.props.templateId) {
            this.props.getGroupTemplate(this.props.templateKey, this.props.templateId);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (!this.state.initialScroll) {
            let page = document.getElementById(this.props.scrollTo);
            if (page) {
                page.scrollIntoView(false);
                this.setState({initialScroll: true});
            }
        }
    }


    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }


    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        if (buttonTitle === 'BACK') this.setState({openQuestionReview: false, showGroupBackButton: true});
    }

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {

        this.props.setStateCallBack({showGroupBackButton: this.state.showGroupBackButton})
        if (this.state.openQuestionReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.QUESTION}
                templateKey={this.state.selectedQuestionInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        const template = this.props.template;

        return (
            template ?
                <div id="groupReview-container-div" style={{flexGrow: "2", padding: "32px"}}>

                    <div style={{minHeight: "min-content"}}>

                        <Typography variant="h6" component="div">
                            Group Information:
                        </Typography>

                        <div>&nbsp;</div>

                        <div id="GroupReview-complex-question-type-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Status:</div>
                            <div>&nbsp;</div>
                            <div>{statusFormatter(this.props.template, 0, 0, true)}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="GroupReview-complex-question-type-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Likert:</div>
                            <div>&nbsp;</div>
                            <div>{template.answerOptionTemplates.length ? "Yes" : "No"}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="GroupReview-complex-question-name-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Group:</div>
                            <div>&nbsp;</div>
                            <div>{template.name}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="GroupReview-complex-question-qualifier-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Qualifier:</div>
                            <div>&nbsp;</div>
                            <div>{template.nameQualifier}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="GroupReview-complex-question-description-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Description:</div>
                            <div>&nbsp;</div>
                            <div>
                                {template.description && <div style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                                    <InfoIcon style={{minWidth: "36px"}}/>
                                    <div>&nbsp;</div>
                                    <div>{template.description}</div>
                                </div>}
                            </div>
                        </div>

                        <div>&nbsp;</div>

                        <div>
                            {metadataUtils.listMetadata(this.props.template, "Group")}
                        </div>

                    </div>

                    {/*// ===========================================================================================================*/}
                    {/*// GROUP LIKERT ANSWERS */}
                    {/*// ===========================================================================================================*/}

                    {(this.props.template.answerOptionTemplates.length > 0) ?
                        <AnswerViewer
                            answerOptionTemplates={this.props.template.answerOptionTemplates}
                            parentsLabel="Group"
                        />
                        : ""
                    }

                    {/*// ===========================================================================================================*/}
                    {/*// ASSIGNED QUESTIONS LIST - NOTE: If (this.props.template.answerOptionTemplates.length > 0) Group is a LIKERT */}
                    {/*// ===========================================================================================================*/}

                    <QuestionViewerExpandable
                        parentType={TEMPLATE.TYPE.GROUP}
                        parentTemplate={this.props.template}
                        questionKeys={this.props.questionKeys}
                        showIconButtonReorder={false}
                        showIconButtonEdit={false}
                        showIconButtonRemove={false}
                        setStateCallback={this.setStateCallback}
                        hidePortalPreview={(this.props.template.answerOptionTemplates.length > 0)}
                        isLikert={this.props.template.answerOptionTemplates.length > 0}
                    />

                    {/*// ===========================================================================================================*/}
                    {/*// PORTAL PREVIEW */}
                    {/*// ===========================================================================================================*/}

                    <div id="GroupReview-space-above-portal-preview" style={{height: "20px"}}>&nbsp;</div>

                    <ArgoPortalPreview
                        calledBy="GroupReview"
                        templateType={TEMPLATE.TYPE.GROUP}
                        template={this.props.template}
                    />

                </div>
                : ""

        );
    }
}

GroupReview.defaultProps = {
    template: undefined,
    setStateCallBack: function () {},
    scrollTo: "",
    width: "100%"
};

GroupReview.propTypes = {
    questionKeys: PropTypes.array,
    templateKey: PropTypes.string,
    templateId: PropTypes.string,
    style: PropTypes.object,
    setStateCallBack: PropTypes.func,
    scrollTo: PropTypes.string,
    width: PropTypes.string
};

function mapStateToProps(state, props) {

    let mappedState = {
        questionTemplates: state.questionTemplate
    };

    if (props.templateKey && state.groupTemplate[props.templateKey]) {
        mappedState.template = state.groupTemplate[props.templateKey];
        mappedState.questionKeys = state.groupTemplate[props.templateKey].questionKeys || [];
    }

    return mappedState;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getGroupTemplate, getQuestionTemplate}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupReview);
