import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {removeAnswer, reorderAnswer} from "../../../actions/answerEditorActions";
import ArgoIconButtonHook from "../../common/ArgoIconButtonHook";
import ArgoReorderArrowsHook from "../../common/ArgoReorderArrowsHook";
import ArgoTemplateListDividerHook from "../../common/ArgoTemplateListDividerHook";
import ArgoExpandCollapseButtonHook from "../../common/ArgoExpandCollapseButtonHook";
import AnswerEditorDetails from "./AnswerEditorDetails";
import * as metadataUtils from "../../../utilities/metadata";
import * as expander from "../../../utilities/expander";
import * as questionTypes from "../../../constants/questionTypeConstants";
import * as metadataTemplateTypes from "../../../constants/metadataTemplateTypes";
import * as TEMPLATE from "../../../constants/templateConstants";


class AnswerEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expander: {},
            expandAll: true,
            metadataErrorText: "",
            metadataErrorFlag: false
        };
    }

    componentDidMount() {
        // Must create array keys for answers
        const answerKeys = Object.values(this.props.answerOptionTemplates).map(template => template.templateKey)
        this.setState({expander: expander.presetExpander(answerKeys, this.props.answerOptionTemplates)});
    }

    componentWillReceiveProps(nextProps) {

        this.isNewAnswerAdded(nextProps);
        if(JSON.stringify(Object.keys(this.props.answerOptionTemplates)) !== JSON.stringify(Object.keys(nextProps.answerOptionTemplates))){
            const answerKeys = Object.values(nextProps.answerOptionTemplates).map(template => template.templateKey)
            this.setState({expander: expander.presetExpander(answerKeys, nextProps.answerOptionTemplates)});
        }
    }

    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }

    isNewAnswerAdded = (props) => {

        let answerOptionTemplates = props.answerOptionTemplates;

        if (Object.keys(this.state.expander).length !== answerOptionTemplates.length) {

            for (let i = 0; i < answerOptionTemplates.length; i++) {
                if (answerOptionTemplates[i].templateKey === "") {
                    let tempExpander = this.state.expander;
                    let open = false;
                    if (tempExpander.length >= i) {
                        open = tempExpander[i].open;
                    }
                    tempExpander[answerOptionTemplates[i].index] = {open: open, template: answerOptionTemplates[i]};
                    this.setState({expander: tempExpander});
                } else if (!this.state.expander.hasOwnProperty(answerOptionTemplates[i].templateKey)) {
                    let tempExpander = this.state.expander;
                    let open = false;
                    if (tempExpander.length >= i) {
                        open = tempExpander[i].open;
                    }
                    tempExpander[answerOptionTemplates[i].templateKey] = {open: open, template: answerOptionTemplates[i]};
                    this.setState({expander: tempExpander});
                }
            }

        }

    }

    disableIfYesNoQuestionType = () => {
        if (this.props.questionTemplate === null) {
            return false; // This condition is when in group mode
        }
        let type = this.props.questionTemplate.questionType;
        return (type === questionTypes.YES_NO);
    }

    disableIfStateOrAddressQuestionType = () => {

        if (this.props.questionTemplate === null) {
            return false; // This condition is when in group mode
        }
        let type = this.props.questionTemplate.questionType;
        return (type === questionTypes.STATE_ONLY || type === questionTypes.ADDRESS_FULL);
    }

    // -----------------------------------------------------------------------------------------------------------------
    // EXPANDER FUNCTIONS ----------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    onClickIconButtonReorder = (event, fieldName, index, depth, direction) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.reorderAnswer(this.props.groupTemplateMode, (direction === "up"), index, this.props.questionDepth);
    };

    onClickIconButtonRemove = (event, fieldName, index) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.removeAnswer(this.props.groupTemplateMode, index, this.props.questionDepth);
    };

    isAccordionOpen = (answerOption) => {

        // NOTE: In create mode answerOption.templateKey is "" so use answer index

        let open = false;

        if (answerOption.templateKey === "") {
            open = this.state.expander[answerOption.index].open;

        } else {
            open = this.state.expander[answerOption.templateKey].open;
        }

        return open;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {
        const {palette} = this.context;  // From blueTheme.js

        let iconAllowed = false;
        if (this.props.isLikertAnswer) {
            iconAllowed = true;
        }
        else {
            switch (this.props.questionTemplate.questionType) {
                case questionTypes.YES_NO:
                case questionTypes.RADIO:
                    iconAllowed = true;
                    break;
                default:
                    iconAllowed = false;
            }
        }

        let answerOptionTemplates = this.props.answerOptionTemplates;

        return (
            Object.keys(this.state.expander).length > 0  ?
                <div id={"AnswerEditor-container-div"} style={{width: "100%"}}>

                    {/*// -===========================================================================================================*/}
                    {/*// -ANSWER LIST DIVIDER */}
                    {/*// -===========================================================================================================*/}

                    {this.props.hasAnswers ?
                        <ArgoTemplateListDividerHook
                            fileName="AnswerEditor"
                            parentsLabel={(this.props.displayAsLikert ? "Likert " : "") + questionTypes.getLabel[this.props.questionTemplate.questionType] + " Question"}
                            childLabel={["answer", "answers"]}
                            arrayLength={answerOptionTemplates.length}
                        />
                        : ""
                    }

                    {/*// -===========================================================================================================*/}
                    {/*// -ANSWER EXPAND/COLLAPSE ALL ANSWERS BUTTON */}
                    {/*// -===========================================================================================================*/}

                    <div id="AnswerEditor-Answer-ExpandCollapseButton-container-div1" style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
                        {(answerOptionTemplates && answerOptionTemplates.length > 0) &&

                        <div id="AnswerEditor-Answer-ExpandCollapseButton-container-div2" style={{display: "flex", marginLeft: "auto"}}>
                            <ArgoExpandCollapseButtonHook
                                fileName="AnswerEditor"
                                expandAll={this.state.expandAll}
                                onClick={expander.expandCollapseAll.bind(this, TEMPLATE.TYPE.ANSWER, this.state.expander, this.state.expandAll, Object.keys(this.state.expander), false, this.setStateCallback)}
                            />
                        </div>
                        }
                    </div>

                    {/*// -===========================================================================================================*/}
                    {/*// -ANSWER ACCORDION */}
                    {/*// -===========================================================================================================*/}

                    <div>&nbsp;</div>
                    {
                    answerOptionTemplates.map((answerOption, index) =>
                        <div key={"AnswerEditor-Accordion-container-div-" + index}>
                            <Accordion key={"AnswerEditor-Accordion" + index}
                                       id={"AnswerEditor-Accordion" + index}
                                       variant="outlined"
                                       disableGutters={true}
                                       expanded={this.isAccordionOpen(answerOption)}
                                       onChange={expander.expandCollapseSelected.bind(this, this.state.expander, (answerOption.templateKey === "" ? answerOption.index : answerOption.templateKey), this.setStateCallback)}
                                       sx={{width: "100%"}}>
                                <AccordionSummary
                                    id={"AnswerEditor-AccordionSummary-" + index}
                                    expandIcon={<ExpandMoreIcon style={{color: expander.ICON_COLOR}}/>}
                                    sx={{
                                        height: "30px",
                                        backgroundColor: expander.ACCORDION_BG_COLOR,
                                        width: "100%",
                                        '&:hover': {bgcolor: expander.ACCORDION_BG_COLOR_HOVER}
                                    }}
                                >
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                                        <ArgoReorderArrowsHook
                                            fileName="AnswerEditor"
                                            fieldName="container-div"
                                            onClick={this.onClickIconButtonReorder}
                                            index={index}
                                            arrayLength={this.props.arrayLength}
                                            isFirst={index === 0 || this.disableIfStateOrAddressQuestionType()}
                                            isLast={index === (answerOptionTemplates.length - 1)  || this.disableIfStateOrAddressQuestionType()}
                                            upArrowColor={index === 0 ? expander.ICON_COLOR_HIDDEN : expander.ICON_COLOR}
                                            downArrowColor={(index === (answerOptionTemplates.length - 1)) ? expander.ICON_COLOR_HIDDEN : expander.ICON_COLOR}
                                        />

                                        <Typography variant="subtitle1" component="div"
                                                    sx={{
                                                        paddingLeft: "10px",
                                                        color: expander.TEXT_COLOR_SECONDARY,
                                                        paddingTop: "3px"
                                                    }}>
                                            {"Text:"}
                                        </Typography>

                                        <Typography variant="h6" component="div"
                                                    sx={{
                                                        paddingLeft: "10px",
                                                        color: expander.ICON_COLOR,
                                                        whiteSpace: 'nowrap',
                                                        wordWrap: 'break-word',
                                                        overflow: "hidden",
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: 1000
                                                    }}>
                                            {answerOption.optionText}
                                        </Typography>

                                        <Typography variant="subtitle1" component="div" sx={{
                                            paddingLeft: "10px",
                                            color: expander.TEXT_COLOR_SECONDARY,
                                            paddingTop: "3px"
                                        }}>
                                            {"Actions:"}
                                        </Typography>

                                        <Typography variant="subtitle1" component="div"
                                                    sx={{paddingLeft: "15px", color: expander.ICON_COLOR, paddingTop: "3px"}}>
                                            {"(" + answerOption.importActionKeys.length + ")"}
                                        </Typography>

                                        <Typography variant="subtitle1" component="div" sx={{
                                            paddingLeft: "10px",
                                            color: expander.TEXT_COLOR_SECONDARY,
                                            paddingTop: "3px"
                                        }}>
                                            {"Show Group:"}
                                        </Typography>

                                        <Typography variant="subtitle1" component="div"
                                                    sx={{paddingLeft: "15px", color: expander.ICON_COLOR, paddingTop: "3px"}}>
                                            {"(" + (answerOption.importGroupKeys.length ? "Yes" : "No") + ")"}
                                        </Typography>

                                        <Typography variant="subtitle1" component="div" sx={{
                                            paddingLeft: "10px",
                                            color: expander.TEXT_COLOR_SECONDARY,
                                            paddingTop: "3px"
                                        }}>
                                            {"Metadata:"}
                                        </Typography>

                                        <Typography variant="subtitle1" component="div"
                                                    sx={{paddingLeft: "15px", color: expander.ICON_COLOR, paddingTop: "3px"}}>
                                            {"(" + metadataUtils.countNumberOfVisibleMetadataEntries(answerOption.metadata) + ")"}
                                        </Typography>

                                        <div id={"AnswerEditor-icon-button-container-div" + index}
                                             style={{display: "flex", marginLeft: "auto"}}>

                                            {(this.disableIfStateOrAddressQuestionType() || this.disableIfYesNoQuestionType()) ? "" :
                                                <ArgoIconButtonHook
                                                    fileName="AnswerEditor"
                                                    fieldName="remove"
                                                    index={index}
                                                    onClick={this.onClickIconButtonRemove}
                                                    icon={<HighlightOffOutlinedIcon style={{color: expander.ICON_COLOR}}/>}
                                                />
                                            }

                                            <div>&nbsp;</div>

                                        </div>

                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>

                                        <AnswerEditorDetails
                                            index={index}
                                            answerOption={answerOption}
                                            answerOptionTemplates={answerOptionTemplates}
                                            questionDepth={this.props.questionDepth}
                                            displayAsLikert={this.props.displayAsLikert}
                                            parentSetStateCallback={this.props.setStateCallback}
                                            answerEditorSetStateCallback={this.setStateCallback}
                                            groupTemplateMode={this.props.groupTemplateMode}
                                            setMetadataErrorText={this.props.setMetadataErrorText}
                                        />

                                    </div>
                                </AccordionDetails>
                            </Accordion>



                            {this.isAccordionOpen(answerOption) ? <div>&nbsp;</div> : ""}
                        </div>
                    )}
                </div>
            : ""

        );
    }
}

AnswerEditor.defaultProps = {
    disableRemoveIcon: false,
    isLikertAnswer: false,
    groupTemplateMode: false
};

AnswerEditor.propTypes = {
    groupTemplateMode: PropTypes.bool,
    questionDepth: PropTypes.number,
    disableRemoveIcon: PropTypes.bool,
    isLikertAnswer: PropTypes.bool,
    displayAsLikert: PropTypes.bool,
    templateKey: PropTypes.string,
    hasAnswers: PropTypes.bool,
    template: PropTypes.object,
    answerOptionTemplates: PropTypes.array,
    setMetadataErrorText: PropTypes.func
};

function mapStateToProps(state, props) {

    let isGroupLikertType = (props.groupTemplateMode && props.isLikertAnswer);

    return {
        // answerOption: isGroupLikertType ? state.groupEditor.template.answerOptionTemplates[props.index] : state.questionEditor[props.questionDepth].answerOptionTemplates[props.index],
        // answerOptionLength: isGroupLikertType ? state.groupEditor.template.answerOptionTemplates.length : state.questionEditor[props.questionDepth].answerOptionTemplates.length,
        //answerOptionTemplates: isGroupLikertType ? state.groupEditor.template.answerOptionTemplates : state.questionEditor[props.questionDepth].answerOptionTemplates,
        actionTypeList: state.actionTypeList,
        groupTemplateList: state.groupTemplateList,
        groupTemplate: state.groupTemplate,
        questionTemplate: isGroupLikertType ? null : state.questionEditor[props.questionDepth].template,
        metadataDefinition: state.metadataDefinition[metadataTemplateTypes.AnswerTemplate]
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({removeAnswer, reorderAnswer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerEditor);
