import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import ReviewViewer from "../qd/integrations/IntegrationReview";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class IntegrationReviewDrawer extends Component {

    render() {

        return (
            <ClickAwayListener onClickAway={this.props.closeDrawer}>
                <div style={{height: "100%"}}>
                    <ArgoAppBar
                        title="Review Integration"
                        showMenuArrowButton="LEFT"
                        onLeftIconButtonTouchTap={this.props.closeDrawer}
                        isDrawer={true}
                        width={integrationReviewDrawerProps.width}
                    />

                    <div style={{height: '64px'}}></div>

                    <div>
                        <ReviewViewer id={this.props.id}/>
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

IntegrationReviewDrawer.propTypes = {
    id: PropTypes.string
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer}, dispatch);
}

const integrationReviewDrawerProps = {
    opensecondary: true,
    width: "50%",
    docked: false,
    anchor: "right"
};

export default connect(null, mapDispatchToProps)(IntegrationReviewDrawer);
export {integrationReviewDrawerProps};
