
export const LIKERT = "LIKERT";  // group template.questionGroupType
export const DEFAULT = "DEFAULT"; // group template.questionGroupType


export const getGroupLabel = {
    [LIKERT]: "Likert",
    [DEFAULT]: ""
};


