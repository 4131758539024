import * as React from "react";

function SvgNegative(props) {
  return (
    <svg width={40} height={40} {...props}>
      <defs>
        <rect id="negative_svg__a" x={0} y={0} width={36} height={36} rx={18} />
        <path id="negative_svg__c" d="M8 2H0V0h8z" />
      </defs>
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <mask id="negative_svg__b" fill="#fff">
          <use xlinkHref="#negative_svg__a" />
        </mask>
        <rect
          stroke="#979797"
          strokeWidth={1.8}
          x={0.9}
          y={0.9}
          width={34.2}
          height={34.2}
          rx={17.1}
        />
        <g mask="url(#negative_svg__b)">
          <path fill={props.fill} fillOpacity={props.opacity} d="M-.3 0h36v36h-36z" />
        </g>
        <g mask="url(#negative_svg__b)">
          <g transform="translate(14 17)">
            <mask id="negative_svg__d" fill="#fff">
              <use xlinkHref="#negative_svg__c" />
            </mask>
            <g mask="url(#negative_svg__d)" fill="#FFF">
              <path d="M0-5h8V7H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgNegative;
