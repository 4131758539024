import * as types from "../constants/actionConstants";

const initialState = [];

function actionTemplateListReducer(state = initialState, action) {
    switch (action.type) {
        case types.ACTION_TEMPLATE_LIST_CLEAR:
            return initialState;

        case types.ACTION_TEMPLATE_LIST_GET_SUCCESS:
        case types.ACTION_TEMPLATE_LIST_CURRENT_GET_SUCCESS:
            return action.list;

        default:
            return state;
    }
}

export default actionTemplateListReducer;
