import TemplateApi from "../api/TemplateApi";
import * as types from "../constants/actionConstants";
import {closeDrawer} from "./drawerActions";
import {initGetTemplateList} from "./templateListActions";
import * as TEMPLATE from "../constants/templateConstants";


export function getActionTemplate(templateKey, id) {
    let thunk = (dispatch)=>{
        if (id){
            TemplateApi.getActionById(id).then((actionTemplate) =>{
                dispatch({type: types.ACTION_TEMPLATE_GET_SUCCESS , actionTemplate});
            });
        }
        else {
            TemplateApi.getCurrentAction(templateKey).then((actionTemplate) =>{
                dispatch({type: types.ACTION_TEMPLATE_GET_SUCCESS , actionTemplate});
            });
        }
    };

    thunk.meta = {
        debounce: {
            time: 2000,
            key: id || templateKey,
            leading: true,
            trailing: false
        }
    };

    return thunk;
}


export function createActionTemplate(actionTemplateObject) {
    return (dispatch)=>{
        TemplateApi.createActionTemplate(actionTemplateObject).then((actionTemplate) =>{
            dispatch({type: types.ACTION_TEMPLATE_CREATE_SUCCESS, actionTemplate});

            if (actionTemplateObject.answerIndex || actionTemplateObject.answerIndex === 0) {
                dispatch({type: types.QUESTION_EDITOR_ADD_ACTION, actionTemplate, answerIndex: actionTemplateObject.answerIndex, depth: 0});
            }
        });
    };
}


export function saveActionTemplateAndCloseDrawer(actionTemplateObject, filter, versionsFilter, templateMode) {
    return (dispatch)=>{
        TemplateApi.saveActionTemplate(actionTemplateObject).then((actionTemplate) =>{
            dispatch({type: types.ACTION_TEMPLATE_UPDATE_SUCCESS , actionTemplate});
            dispatch(closeDrawer());

            // Best to load question list page in all cases
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.ACTION, TEMPLATE.MODE.CURRENT));
            if (templateMode === TEMPLATE.MODE.VERSIONS) {
                let tempVersionsFilter = {...versionsFilter};
                tempVersionsFilter.templateName = actionTemplateObject.name;
                tempVersionsFilter.templateKey = actionTemplateObject.templateKey;
                dispatch(initGetTemplateList(tempVersionsFilter, TEMPLATE.TYPE.ACTION, TEMPLATE.MODE.VERSIONS));
            }
        });
    };
}


export function clearActionTemplate() {
    return (dispatch) => {
        dispatch({type: types.ACTION_TEMPLATE_CLEAR});
    };
}


export function createActionTemplateAndCloseDrawer(actionTemplateObject, filter, onSuccess = ()=>{}) {
    return (dispatch)=>{
        TemplateApi.createActionTemplate(actionTemplateObject).then((actionTemplate) =>{
            dispatch({type: types.ACTION_TEMPLATE_CREATE_SUCCESS, actionTemplate});
            onSuccess();
            dispatch(closeDrawer());
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.ACTION, TEMPLATE.MODE.CURRENT));

            if (actionTemplateObject.answerIndex || actionTemplateObject.answerIndex === 0) {
                dispatch({type: types.QUESTION_EDITOR_ADD_ACTION, actionTemplate, answerIndex: actionTemplateObject.answerIndex, depth: 0});
            }
        });
    };
}




