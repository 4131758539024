export const INSTANT_MODE  = "INSTANT_MODE";
export const TEMPLATE_MODE  = "TEMPLATE_MODE";

export const SKIP_FIRST_QUESTION  = "SKIP_FIRST_QUESTION";
export const SHOW_FIRST_QUESTION  = "SHOW_FIRST_QUESTION";

export const EDITABLE_SURVEY  = "EDITABLE_SURVEY";
export const READONLY_SURVEY  = "READONLY_SURVEY";

export const READONLY_SURVEY_URL_SHOW_HEADER_NONE  = "READONLY_SURVEY_URL_SHOW_HEADER_NONE";
export const READONLY_SURVEY_URL_SHOW_HEADER_TRUE  = "READONLY_SURVEY_URL_SHOW_HEADER_TRUE";
export const READONLY_SURVEY_URL_SHOW_HEADER_FALSE  = "READONLY_SURVEY_URL_SHOW_HEADER_FALSE";
export const READONLY_SURVEY_URL_SHOW_HEADER_FILTERED  = "READONLY_SURVEY_URL_SHOW_HEADER_FILTERED";

// Check for screen size

export const displayOneQuestionPerPage = () => {

    let onePerPage = false;

    let screenHeight = window.screen.height;
    let screenWidth = window.screen.width;

    if ((screenHeight < 800) || (screenWidth < 450)) {
        onePerPage = true;
    }

    return onePerPage;
};

// Possible fix for phone change direction and refresh NPS (Net Promoter Score) from 11 strait to pyramid/honeycomb
// https://stackoverflow.com/questions/62472029/react-native-detect-screen-rotation-change-using-portrait-mode
