import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {TableGrid, TableView} from '../../containers';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {getTemplateList, updatePageAndGetList, updateSortAndGetList} from "../../actions/templateListActions";
import {openDrawer} from "../../actions/drawerActions";
import FilterPopOver from "../dialogs/filters/FilterPopOver";
import ArgoListTitleAndLoadingAnimationHook from "../common/ArgoListTitleAndLoadingAnimationHook";
import TagCreateEditDrawer, {tagCreateEditDrawerProps} from "../drawers/TagCreateEditDrawer";
import TagReviewDrawer, {tagReviewDrawerProps} from "../drawers/TagReviewDrawer";
import ArgoTemplateListCreateFabHook from "../common/ArgoTemplateListCreateFabHook";
import formatCopyText from "../../utilities/formatCopyText";
import {QD_URL} from "../../constants/systemConstants";
import * as TEMPLATE from "../../constants/templateConstants";
import * as SYSTEM from "../../constants/systemConstants";
import * as metadataTemplateTypes from "../../constants/metadataTemplateTypes";


class TagList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filter: false,
            createMode: false
        };
    }

    componentDidMount() {
        this.setState({loading: true}, () => this.setState({loading: true}, () => this.props.getTemplateList(this.props.filter, TEMPLATE.TYPE.TAG, TEMPLATE.MODE.ALL)));
    }

    componentWillReceiveProps(nextProps) {

        // Called twice, first pass set filter false, then on second call list is loaded and loading animation can be stopped
        if (this.state.filter) {
            this.setState({filter: false});
        }
        else if (this.state.loading) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemCOnstants.js
                let delay = setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false, createMode: false});
            }

        }
    }

    // Used to test loading animation rendering
    delayedSetLoadingFalse = () => {
        this.setState({loading: false});
    }

    setLoadingStateTrue = (callback, parm1 = null, calledFrom = "") => {
        // If a prameter is passed then pass it to call back, should be the template of the item to be saved edit or created
        if (parm1 === null) {
            this.setState({loading: true, filter: false}, () => callback());
        } else {
            this.setState({loading: true, filter: false}, () => callback(parm1));
        }
    }

    openCreate = (event) => {
        this.props.openDrawer(tagCreateEditDrawerProps, <TagCreateEditDrawer createMode={true} setLoadingState={this.setLoadingStateTrue}/>);
        this.setState({createMode: true});
    };

    openEdit = (templateKey, id) => {
        this.props.openDrawer(tagCreateEditDrawerProps, <TagCreateEditDrawer id={id} filter={this.props.filter} setLoadingState={this.setLoadingStateTrue}/>);
    };

    openPreview = (templateKey, id, name, row, event) => {
        formatCopyText(row, event, "Tag:");
        this.props.openDrawer(tagReviewDrawerProps, <TagReviewDrawer id={id}/>);
    };

    onPageChange = (page, size, event) => {
        this.setState({loading: true, filter: true}, () => this.props.updatePageAndGetList(page, size, this.props.filter, TEMPLATE.TYPE.TAG, TEMPLATE.MODE.ALL, event));
    };

    onSortChange = (sort, direction) => {
        this.setState({loading: true, filter: true}, () => this.props.updateSortAndGetList(sort, direction, this.props.filter, TEMPLATE.TYPE.TAG, TEMPLATE.MODE.ALL));
    };


    render() {
        let {
            tagList
        } = this.props;

        const customActions = [
            {
                func: this.openPreview,
                label: "Review"
            },
            {
                func: this.openEdit,
                label: "Edit"
            }
        ];

        return (
            <div id="TagList-container-div" style={{height: "100%", padding: "15px", marginTop: '64px'}}>

                <ArgoListTitleAndLoadingAnimationHook fileName="TagList" listTitle="Tags" loading={this.state.loading} filter={this.state.filter}/>

                <div style={{clear: "both"}}></div>

                <Card id="TagList-card"
                      variant="outlined"
                      sx={{border: "1px solid lightgray", height: "100%", boxShadow: "2px 4px whitesmoke"
                      }}>
                    <CardContent id="TagList-card-content">

                        <TableView
                            id="TagList-table-view"
                            templateType={TEMPLATE.TYPE.TAG}
                            templateMode={TEMPLATE.MODE.ALL}
                            setLoadingState={this.setLoadingStateTrue}
                            filter={<FilterPopOver page="TagList"
                                                   templateType={TEMPLATE.TYPE.TAG}
                                                   templateMode={TEMPLATE.MODE.ALL}
                                                   filterName={TEMPLATE.FILTER.all.tag}
                                                   submitValue={this.props.getTemplateList}
                                                   showQualifier={false}
                                                   setLoadingState={this.setLoadingStateTrue}
                            />}
                        >

                            <TableGrid
                                id="TagList-table-grid"
                                displayType={"tags"}
                                dataSource={tagList}
                                nameDrawer={this.openPreview}
                                link={QD_URL +"/search-tag-editor/"}
                                customActions={customActions}
                                pageState={this.props.filter}
                                onPageChange={this.onPageChange}
                                sortState={this.props.filter}
                                onSortChange={this.onSortChange}
                                type={metadataTemplateTypes.QuestionTemplate}
                                templateType={TEMPLATE.TYPE.TAG}
                                templateMode={TEMPLATE.MODE.ALL}
                                showQualifier={false}
                                showStatus={false}
                                showVersionCount={false}
                                showGridActionVersions={false}
                                uniqueIdentifier={"id"}
                            />
                        </TableView>

                        <ArgoTemplateListCreateFabHook
                            fileName="TagList"
                            fieldName="fab"
                            hoverText="Create Tag"
                            onClick={this.openCreate}
                        />

                    </CardContent>

                </Card>

            </div>
        );
    }
}

TagList.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getTemplateList, updatePageAndGetList, updateSortAndGetList, openDrawer}, dispatch);
}

function mapStateToProps(state) {
    return {
        tagList: state.tagList,
        filter: state.filter[TEMPLATE.FILTER.all.tag] || {}  //Get correct filter from constant
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TagList);
