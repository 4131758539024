import React from "react";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as mode from "../utilities/displayOneQuestionPerPage";
import * as style  from "../utilities/getStyle";
import * as font from "../utilities/fontUtilities";
import * as icon from "../constants/iconConstants";
import * as tag from "../constants/customMetadataConstants";
import * as ddo from "../constants/customObjConstants";
import {styled} from "@mui/material/styles";


export function saveMultiSelect(question, answerOption) {

    const {
        surveyEditMode,
        displayMode
    } = this.props;

    if (surveyEditMode === mode.EDITABLE_SURVEY) {
        // See if template or instance
        if (displayMode === mode.INSTANT_MODE) {
            this.props.answerQuestionOptions(question.id, answerOption.key, {selected: !answerOption.selected});
        } else {
            let tempAnswer = {...answerOption};
            tempAnswer.selected = !answerOption.selected;
            this.props.answerQuestionTemplateOptions(question.id, answerOption.key, tempAnswer);
        }
    }
}


export function multiSelectQuestion(itemObj) {
    let self = this;

    const {question} = this.props;

    let readonlyMode = (this.props.surveyEditMode === mode.READONLY_SURVEY);

    let answers = ddo.getAnswerOptions(question);

    let textObj = font.getDefaultOrCustomTextObj(question, ddo.OBJ_TYPE.BUTTON, ddo.OBJ_TEXT_TYPE.NAME);

    let CustomTopographyStyle = style.getCustomTextStyle(textObj.fontObj);

    let outlinedCheckboxesMode = tag.metadataExists(question, tag.DISPLAY_CHECKBOXES_AS_OUTLINED);

    let unselectedColorControl = tag.metadataValue(question, tag.UNSELECTED_COLOR_CONTROL, "" , icon.DEFAULT_ICON_COLOR_UNSELECTED);
    let unselectedColorText = tag.metadataValue(question, tag.UNSELECTED_COLOR_TEXT, "" , textObj.fontObj.color);

    let selectedColorControl = tag.metadataValue(question, tag.SELECTED_COLOR_CONTROL, "" , outlinedCheckboxesMode ? "white" : icon.DEFAULT_ICON_COLOR_SELECTED);
    let selectedColorText = tag.metadataValue(question, tag.SELECTED_COLOR_TEXT, "" , outlinedCheckboxesMode ? "white" : textObj.fontObj.color);

    function getTextColor(isChecked) {
        if(isChecked){
            return selectedColorText
        } else {
            return unselectedColorText
        }
    }

    const StyledCheckbox = styled(Checkbox)({
        color: unselectedColorControl,
        '&.Mui-checked': {
            color: selectedColorControl,
        },
        checked: {},
    });

    let sideBySide = tag.metadataExists(question, tag.DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE_WRAP) || tag.metadataExists(question, tag.DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE);

    if (outlinedCheckboxesMode) {

        let outlinedOverrides = style.getOutlinedOverrides(question, textObj);

        let StyledFormControlLabel = null;

        return (
            <FormGroup style={{display: 'flex', flexDirection: sideBySide ? 'row' : 'column', paddingLeft: "10px"}}>
                {answers.map(answer => {

                    StyledFormControlLabel = style.getStyledFormControlLabel(outlinedOverrides, answer.selected, readonlyMode);

                    return (
                        <StyledFormControlLabel
                            key={answer.id}
                            control={
                                <StyledCheckbox
                                    onChange={saveMultiSelect.bind(self, question, answer)}
                                    disableRipple={readonlyMode}
                                    disableFocusRipple={readonlyMode}
                                    checked={answer.selected}
                                    value={answer.templateKey}
                                    // classes={{
                                    //     root: classes.root,
                                    //     checked: classes.checked,
                                    // }}
                                />
                            }
                            label={<div style={{display: "flex", alignItems: "center"}}>{<CustomTopographyStyle style={{color: getTextColor(answer.selected)}}>{answer.optionText}</CustomTopographyStyle>}</div>}
                        />
                    );
                })
                }
            </FormGroup>
        );
    } else {

        return (
            <FormGroup style={{display: 'flex', flexDirection: sideBySide ? 'row' : 'column'}}>
                {answers.map(answer => {
                    return (
                        <FormControlLabel
                            key={answer.id}
                            control={
                                <StyledCheckbox
                                    onChange={saveMultiSelect.bind(self, question, answer)}
                                    disableRipple={readonlyMode}
                                    disableFocusRipple={readonlyMode}
                                    checked={answer.selected}
                                    value={answer.templateKey}
                                />
                            }
                            label={<div style={{display: "flex", alignItems: "center"}}>{<CustomTopographyStyle style={{color: getTextColor(answer.selected)}}>{answer.optionText}</CustomTopographyStyle>}</div>}
                        />
                    );
                })
                }
            </FormGroup>
        );
    }
}
