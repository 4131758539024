import React, {Component} from "react";
import PropTypes from "prop-types";
import ArgoColorInput from "./ArgoColorInput";
import ArgoTextFieldHook from "./ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import * as portal from "../../utilities/portal";


const textAlignList = [
    {value: "left", text: "Left"},
    {value: "center", text: "Center"},
    {value: "right", text: "Right"}
]

const fontsList = [
    {value: "arial", text: "Arial"},
    {value: "arialblack", text: "Arial Black"},
    {value: "comic", text: "Comic Sans"},
    {value: "courier", text: "Courier New"},
    {value: "georgia", text: "Georgia"},
    {value: "impact", text: "Impact"},
    {value: "lucida", text: "Lucida"},
    {value: "lucidaSans", text: "Lucida Sans"},
    {value: "palatino", text: "Palatino"},
    {value: "roboto", text: "Roboto"},
    {value: "tahoma", text: "Tahoma"},
    {value: "trebuchet", text: "Trebuchet"},
    {value: "verdana", text: "Verdana"}
];

const sizingTypeList = [
    {value: "variant", text: "Variant"},
    {value: "points", text: "Points"},
    {value: "pixels", text: "Pixels"}
];

const variantTypesList = [
    {value: "h1", text: "h1"},
    {value: "h2", text: "h2"},
    {value: "h3", text: "h3"},
    {value: "h4", text: "h4"},
    {value: "h5", text: "h5"},
    {value: "h6", text: "h6"},
    {value: "body1", text: "body1"},
    {value: "body2", text: "body2"},
    {value: "caption", text: "caption"},
    {value: "subtitle1", text: "subtitle1"},
    {value: "subtitle2", text: "subtitle2"}
];

const fontStyleList = [
    {value: "normal", text: "Normal"},
    {value: "italic", text: "Italic"},
    {value: "oblique", text: "Oblique"}
];

const fontWeightList = [
    {value: "normal", text: "Normal"},
    {value: "bold", text: "Bold"},
    {value: "bolder", text: "Bolder"}
];


class ArgoTextDisplayCustom extends Component {

    constructor(props) {
        super(props);

        let value = props.initToObj;

        if (props.initToObj === null) {
            this.state = {};
        }
        else {
            this.state = {
                title: value.title,
                hide: value.hide,
                font: value.font,
                fontFamily: value.fontFamily,
                fontWeight: value.fontWeight,
                colorType: value.colorType,
                coloIndex: value.colorIndex,
                colorEntry: value.colorEntry,
                color: value.color,
                backgroundColor: "",
                backgroundImage: "",
                fontSizing: value.fontSizing,
                fontSizeEntry: value.fontSizeEntry,
                fontSize: value.fontSize,
                variant: value.variant,
                fontStyle: value.fontStyle,
                textAlign: value.textAlign,  // left center right
                opacityEntry: value.opacityEntry,
                opacity: value.opacity,
                icon: ""
            };
        }
    }


    componentWillReceiveProps(nextProps) {
        // Does NOT fire for some reason!!!
    }


    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        let stateObj = {[fieldName]: value};

        switch(fieldName) {
            case "fontSizeEntry":
                if (this.state.fontSizing === "pixels") {
                    stateObj = {[fieldName]: value, "fontSize": value+"px"};
                }
                else {
                    stateObj = {[fieldName]: value, "fontSize": value+"pt"};
                }
                break;
            default:
                console.log("ArgoTextDisplayCustom.js  Switch default E01"); // eslint-disable-line
        }

        this.setState(stateObj, () => {this.setMetadataValue()});
    };


    onSelectChange = (event, fieldName, menuItemText) => {
        let selected = event.target.value;

        let stateObj = {[fieldName]: selected};

        switch(fieldName) {
            case "fontSizing":
            case "variant":

                let fontSizing = fieldName === "fontSizing" ? selected : this.state.fontSizing;
                let variant = fieldName === "variant" ? selected : this.state.variant;
                let fontSize = "";
                switch(variant) {
                    case "h1":
                        fontSize = "36";
                        break;
                    case "h2":
                        fontSize = "31";
                        break;
                    case "h3":
                        fontSize = "26";
                        break;
                    case "h4":
                        fontSize = "21";
                        break;
                    case "h5":
                        fontSize = "16";
                        break;
                    case "h6":
                        fontSize = "16";
                        break;
                    case "caption":
                        fontSize = "12";
                        break;
                    case "subtitle1":
                        fontSize = "12";
                        break;
                    case "subtitle2":
                        fontSize = "12";
                        break;
                    case "body1":
                        fontSize = "14";
                        break;
                    case "body2":
                        fontSize = "14";
                        break;
                    case "overline":
                        fontSize = "10";
                        break;
                    default:
                        console.log("ArgoTextDisplayCustom.js  Switch default E02"); // eslint-disable-line
                }

                stateObj = {fontSizing: fontSizing, variant: variant, "fontSize": fontSize + "pt", "fontSizeEntry": fontSize};
                break;
            default:
                console.log("ArgoTextDisplayCustom.js  Switch default E03"); // eslint-disable-line

        }

        // After setting the state, call setMetadataValue to update the template
        this.setState(stateObj, () => {this.setMetadataValue()});
    };


    setMetadataValue = () => {

        let valueObj = {
            title: this.state.title,
            hide: this.state.hide,
            font: this.state.font,
            fontFamily: portal.getFontFamily(this.state.font),
            fontWeight: this.state.fontWeight,
            colorType: this.state.colorType,
            colorIndex: this.state.colorIndex,
            colorEntry: this.state.colorEntry,
            color: this.state.color,
            backgroundColor: "",
            backgroundImage: "",
            fontSizing: this.state.fontSizing,
            fontSizeEntry: this.state.fontSizeEntry,
            fontSize: this.state.fontSize,  // 2em, 18pt
            variant: this.state.variant,
            fontStyle: this.state.fontStyle,
            textAlign: this.state.textAlign,  // left center right
            opacityEntry: this.state.opacityEntry,
            opacity: this.state.opacity,
            icon: ""
            //backgroundColor: 'red',
        };

        let mpo = this.props.metadataPropsObj;

        let setCustomMetadataFunction = this.props.useMetadataPropsObjAltFunction ? mpo.setCustomMetadataFunctionAlt : mpo.setCustomMetadataFunction;

        // Check to see if a set matadata callback was passed as a prop, if not use the callback passed in the mpo (metadata props object).
        if (this.props.setCustomMetadata) {
            this.props.setCustomMetadata(this.props.fieldName, 'default', portal.CUSTOM_DDO_QNR_FORMAT_APP_HEADER, valueObj);
        }
        else {
            this.setState({metadataValue: JSON.stringify(valueObj)}, () => {mpo.setCustomMetadataFunction(mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, "custom", mpo.metadataTag, valueObj)});
        }
    };


    setColor = (colorObj) => {
        this.setState(colorObj, () => {this.setMetadataValue()});
    };

    render() {

        return (
            <div id="ArgoTextDisplayCustom-container-div" style={{display: "flex", flexDirection: "row", width: '99%', backgroundColor: this.props.backgroundColorOverride, paddingTop: this.props.paddingTop}}>

                <div style={{width: "20px"}}></div>

                {this.props.showDisplay ?
                    <div style={{display: 'flex'}}>
                        <ArgoTextFieldSelectHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="textAlign"
                            label={this.props.displayLabel}
                            value={this.state.textAlign}
                            onChange={this.onSelectChange}
                            width="160px"
                            menuItems={textAlignList}
                        />
                        <div style={{width: "20px"}}></div>
                    </div>
                    : ""
                }

                {this.props.showTitleEntry ?
                    <div style={{display: 'flex'}}>
                        <ArgoTextFieldHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="title"
                            label="Title"
                            value={this.state.title}
                            onChange={this.onTextChange}
                            width="140px"
                        />
                        <div style={{width: "20px"}}></div>
                    </div>
                    : ""
                }


                <ArgoTextFieldSelectHook
                    fileName="ArgoTextDisplayCustom"
                    fieldName="font"
                    label="Font"
                    value={this.state.font}
                    onChange={this.onSelectChange}
                    width="140px"
                    menuItems={fontsList}
                />

                <div style={{width: "20px"}}></div>

                <ArgoTextFieldSelectHook
                    fileName="ArgoTextDisplayCustom"
                    fieldName="fontSizing"
                    label="Sizing"
                    value={this.state.fontSizing}
                    onChange={this.onSelectChange}
                    width="110px"
                    menuItems={sizingTypeList}
                />

                <div style={{width: "20px"}}></div>

                <div>
                {
                    (this.state.fontSizing === 'variant') ?
                        <ArgoTextFieldSelectHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="variant"
                            label="Variant"
                            value={this.state.variant}
                            onChange={this.onSelectChange}
                            width="110px"
                            menuItems={variantTypesList}
                        />
                        :
                        <ArgoTextFieldHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="fontSizeEntry"
                            label={this.state.fontSizing === 'points' ? "Points" : "Pixels"}
                            value={this.state.fontSizeEntry}
                            type="number"
                            onChange={this.onTextChange}
                            width="70px"
                        />
                }
                </div>

                <div style={{width: "20px"}}></div>

                <ArgoTextFieldSelectHook
                    fileName="ArgoTextDisplayCustom"
                    fieldName="fontStyle"
                    label="Style"
                    value={this.state.fontStyle}
                    onChange={this.onSelectChange}
                    width="110px"
                    menuItems={fontStyleList}
                />

                <div style={{width: "20px"}}></div>

                <ArgoTextFieldSelectHook
                    fileName="ArgoTextDisplayCustom"
                    fieldName="fontWeight"
                    label="Weight"
                    value={this.state.fontWeight}
                    onChange={this.onSelectChange}
                    width="110px"
                    menuItems={fontWeightList}
                />

                <div style={{width: "20px"}}></div>

                <ArgoColorInput opacityFlag={true}
                                setColor={this.setColor}
                                initToObj={this.props.initToObj}
                                largeHexEntry={this.props.largeHexEntry}
                                index={this.props.index}/>

            </div>
        );
    }
}

ArgoTextDisplayCustom.defaultProps = {
    showDisplay: true,
    showTitleEntry: false,
    displayLabel: "Display",
    backgroundColorOverride: "#F8F8F8",
    largeHexEntry: true,
    paddingTop: "10px",
    useMetadataPropsObjAltFunction: false,
    index: 0,
    setCustomMetadata: undefined
};

ArgoTextDisplayCustom.propTypes = {
    showDisplay: PropTypes.bool,
    showTitleEntry: PropTypes.bool,
    fieldName: PropTypes.string,
    initToObj: PropTypes.object,
    displayLabel: PropTypes.string,
    backgroundColorOverride: PropTypes.string,
    largeHexEntry: PropTypes.bool,
    paddingTop: PropTypes.string,
    metadataPropsObj: PropTypes.object,
    useMetadataPropsObjAltFunction: PropTypes.bool,
    index: PropTypes.number,
    setCustomMetadata: PropTypes.func
};

export default ArgoTextDisplayCustom;

