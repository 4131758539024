import * as React from "react";

function SvgQuestionMarkCircle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
      <path d="M23.424 10.827c0 3.956-4.533 5.478-5.507 6.907-.729 1.063-.485 2.557-2.495 2.557-1.309 0-1.946-1.064-1.946-2.039 0-3.623 5.323-4.442 5.323-7.425 0-1.643-1.096-2.616-2.921-2.616-3.895 0-2.373 4.016-5.323 4.016-1.066 0-1.979-.639-1.979-1.855 0-2.983 3.407-5.628 7.119-5.628 3.895-.002 7.729 1.792 7.729 6.083zm-7.879 11.441a2.507 2.507 0 00-2.496 2.496 2.507 2.507 0 002.496 2.494 2.506 2.506 0 002.494-2.494 2.507 2.507 0 00-2.494-2.496zM32 16c0 8.822-7.178 16-16 16S0 24.822 0 16 7.178 0 16 0s16 7.177 16 16zm-3 0c0-7.168-5.832-13-13-13S3 8.832 3 16s5.832 13 13 13 13-5.832 13-13z" />
    </svg>
  );
}

export default SvgQuestionMarkCircle;
