import * as types from "../constants/actionConstants";
import {getAuthorizationToken} from "../api/login";
import * as system from "../constants/systemConstants";

const getToken = (userName, password, rememberMe = false) => {
    return (dispatch) => {
        dispatch({type: types.TOKEN_GET, rememberMe, username: userName});
        if(rememberMe) {
            localStorage.setItem(system.STORAGE_USERNAME, userName);
        }
        else {
            localStorage.removeItem(system.STORAGE_USERNAME);
        }

        getAuthorizationToken(userName, password, rememberMe).then((token) => {
            if(token[system.STORAGE_TOKEN]) {
                dispatch(setToken(token[system.STORAGE_TOKEN]));
            }
        });
    };
};

const initializeAuthorization = () => {
    let login = {
        loggedIn: false,
        username: "",
    };

    if(localStorage.getItem(system.STORAGE_TOKEN)) {
        login.loggedIn = true;
    } else if (sessionStorage.getItem(system.STORAGE_TOKEN))
    {
        login.loggedIn = true;
    }

    if(localStorage.getItem(system.STORAGE_USERNAME))
    {
        login.username = localStorage.getItem(system.STORAGE_USERNAME);
    } else if(sessionStorage.getItem(system.STORAGE_USERNAME))
    {
        login.username = sessionStorage.getItem(system.STORAGE_USERNAME);
    }

    return {
        type: types.TOKEN_INITIALIZE,
        payload: login
    };
};

const setToken = (token) => {
    localStorage.setItem(system.STORAGE_TOKEN, token);

    return {
        type: types.TOKEN_SET
    };
};

const clearToken = (message) => {
    localStorage.removeItem(system.STORAGE_TOKEN);

    return {
        type: types.TOKEN_CLEAR,
        message
    };
};

export {
    getToken,
    setToken,
    clearToken,
    initializeAuthorization,
};
