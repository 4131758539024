import {axiosWrap} from "./TemplateApi";
import * as system from "../constants/systemConstants";

const GET = "get";
const PUT = "put";
const POST = "post";

// converts UI column name to database equivalent
const columnNameMapper = {
    "Name": "name",
    "Last updated": "lastModifiedDate",
    "Updated by": "lastModifiedBy",
    "Type": "questionType",
};

class TagApi {

    static getTagById(id) {
        return axiosWrap(GET,
            system.QD_API_URL +
            "search-tags/" +
            id)
            .then((response) => {
                return response.data;
            });
    }

    static createTag(tag) {
        return axiosWrap(POST, system.QD_API_URL +
            "search-tags", tag)
            .then((response) => {
                    return response.data;
                }
            );
    }

    static updateTag(tag) {
        return axiosWrap(PUT,
            system.QD_API_URL +
            "search-tags", tag)
            .then((response) => {
                return response.data;
            });
    }

    static getTagList(pageable, filter) {
        if (!pageable) {
            pageable = {
                pageNumber: 0,
                sizeSize: 10
            };
        }

        return axiosWrap(GET ,`${system.QD_API_URL}search-tags?&page=${pageable.pageNumber-1||""}&size=${pageable.pageSize||""}&sort=${columnNameMapper[pageable.name]||""},${pageable.direction||""}&name=${filter.title}&updatedBy=${filter.updatedBy || ""}`)
            .then((response) => {
                return response.data;
            });
    }

    static checkTagExists(name) {
        return axiosWrap(GET ,`${system.QD_API_URL}search-tags?name=${name}`)
            .then((response) => {
                return response.data;
            });
    }

}

export default TagApi;
