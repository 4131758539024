import * as types from "../constants/actionConstants";
import {
    startQuestionnaireApi,
    completeQuestionnaireApi,
    getQuestionnaireInstanceIdFromRefKeyApi,
    getQuestionnaireReadonlyInstanceIdFromRefKeyApi,
    getQuestionnaireInstanceApi,
    getQuestionnaireFullApi,
    getNewTokenIdAfterExpiration
} from "../api/portalApi";
import {answerQuestionAnswerOptionsApi, answerQuestionTextApi} from "../api/portalApi";
import * as system from "../constants/systemConstants";

export const startQuestionnaire = (id) => {
    return (dispatch) => {
        startQuestionnaireApi(id).then(response => {
            dispatch({
                type: types.QUESTIONNAIRE_START
            })
        })
    }
};

export const completeQuestionnaire = (id) => {
    return (dispatch) => {
        completeQuestionnaireApi(id).then(response => {
            dispatch({
                type: types.QUESTIONNAIRE_COMPLETE
            })
        })
    }
};

export const getQuestionnaire = (id, after = ()=>{}) => {
    return (dispatch) => {
        getQuestionnaireFullApi(id).then(questionnaire => {
            dispatch({
                type: types.QUESTIONNAIRE_GET_FULL_SUCCESS,
                id,
                questionnaire
            });
            after();
        });
    };
};

export const setQuestionnaireTemplate = (questionnaireTemplate) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_QUESTIONNAIRE_TEMPLATE_SUCCESS,
            data: {id: questionnaireTemplate.id, questionnaireTemplate: questionnaireTemplate}
        });
    };
};

export const setTemplateObj = (templateObj) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_TEMPLATE_OBJ,
            data: {templateObj: templateObj}
        });
    };
};

export const clearPortalPreviewMode = () => {
    return (dispatch) => {
        dispatch({
            type: types.SET_QUESTIONNAIRE_TEMPLATE_NULL
        });
    };
};

export const getQuestionnaireInstance = (refKey, firstAnswerIndex) => {

    return (dispatch) => {
        getQuestionnaireInstanceIdFromRefKeyApi(refKey, firstAnswerIndex).then((data) => {
            localStorage.setItem(system.STORAGE_TOKEN, data[system.STORAGE_TOKEN]);
            dispatch({
                type: types.TOKEN_SET
            });
            dispatch({
                type: types.GET_QUESTIONNAIRE_INSTANCE_ID_FROM_REF_KEY_SUCCESS,
                data: {refKey: refKey, firstAnswerIndex: firstAnswerIndex, questionnaireId: data.questionnaireId, metadata: data.metadata}
            });
            getQuestionnaireInstanceApi(data.questionnaireId).then((data) => {
                dispatch({
                    type: types.GET_QUESTIONNAIRE_INSTANCE_SUCCESS,
                    data: data
                });
            });
        });
    };
};

export const getNewTokenId = (refKey) => {
    return(dispatch) => {
        getNewTokenIdAfterExpiration(refKey).then((newTokenId) => {
            localStorage.setItem(system.STORAGE_TOKEN, newTokenId);
            dispatch({
                type: types.TOKEN_SET
            });
        })
    }
};

export const getQuestionnaireReadonlyInstance = (refKey) => {

    return (dispatch) => {
        getQuestionnaireReadonlyInstanceIdFromRefKeyApi(refKey).then((data) => {
            localStorage.setItem(system.STORAGE_TOKEN, data[system.STORAGE_TOKEN]);
            dispatch({
                type: types.TOKEN_SET
            });
            dispatch({
                type: types.GET_QUESTIONNAIRE_INSTANCE_ID_FROM_REF_KEY_SUCCESS,
                data: {refKey: refKey, questionnaireId: data.questionnaireId, metadata: data.metadata}
            });
            getQuestionnaireInstanceApi(data.questionnaireId).then((data) => {
                dispatch({
                    type: types.GET_QUESTIONNAIRE_INSTANCE_SUCCESS,
                    data: data
                });
            });
        });
    };
};

export const setQuestionnaireInstanceNull = () => {
    return (dispatch) => {
        dispatch({
            type: types.SET_QUESTIONNAIRE_INSTANCE_NULL
        });
    };
};

export const currentPage = (currPage) => {
    return (dispatch) => {
        dispatch({
            type: types.PORTAL_CURRENT_PAGE,
            data: currPage
        });
    };
};

export const nextPage = (currPage) => {
    return (dispatch) => {
        dispatch({
            type: types.PORTAL_NEXT_PAGE,
            data: currPage
        });
    };
};

export const prevPage = (currPage) => {
    return (dispatch) => {
        dispatch({
            type: types.PORTAL_PREV_PAGE,
            data: currPage
        });
    };
};

export const answerQuestionOptions = (id, answerOptionKey, answerOptionUpdateDTO, after = ()=>{}) => {

    return (dispatch, getState) => {
        answerQuestionAnswerOptionsApi(id, answerOptionKey, answerOptionUpdateDTO).then(responseObj => {
            dispatch({type: types.QUESTION_ANSWER_SUCCESS, data: {id: id, key: answerOptionKey, responseObj: answerOptionUpdateDTO}});
            //dispatch(getQuestionnaire(getState().responseProcess.id, after));
            //dispatch({type: types.QUESTION_ANSWER_SUCCESS, data: {id: id, key: answerOptionKey, responseObj: responseObj}});
        });
    };
};

export const answerQuestionTemplateOptions = (id, answerOptionKey, answerOptionUpdateDTO, after = ()=>{}) => {
    return (dispatch, getState) => {
        dispatch({type: types.QUESTION_TEMPLATE_ANSWER_SUCCESS, data: {id: id, key: answerOptionKey, responseObj: answerOptionUpdateDTO}});
    };
};

export const answerQuestionText = (question, questionUpdateDTO, after = ()=>{}) => {
    return (dispatch, getState) => {

        answerQuestionTextApi(question.id, questionUpdateDTO).then(responseObj => {
            dispatch({type: types.QUESTION_ANSWER_SUCCESS, data: {id: question.id, key: null, responseObj: questionUpdateDTO}});
            //dispatch(getQuestionnaire(getState().responseProcess.id, after))
            //dispatch({type: types.QUESTION_ANSWER_SUCCESS, data: {id: question.id, key: null, responseObj: responseObj}});
        });
    };
};

export const answerQuestionTemplateText = (question, questionUpdateDTO, after = ()=>{}) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.QUESTION_TEMPLATE_ANSWER_SUCCESS,
            data: {id: question.id, key: null, responseObj: questionUpdateDTO}
        });
    };
};

export const incrementPortalCount = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.INCREMENT_PORTAL_COUNT
        });
    };
};




