const pageNames = {
        qd2admin:"Questionnaires",
        categories:"Categories",
        groups:"Question_Groups",
        questionnaires:"Questionnaires",
        questions:"Questions",
        actions:"Action_List",
        upload:"Questionnaire_Upload",
        "search-tags": "Search_Tags",
        unpublishedQuestionnaires: "Unpublished_Questionnaires",
    };
export default pageNames;