//import {responseProcess} from "../actions";

let systemConstants = {
    initialized: false,
};

export const initStore = (storeDispatch, storeContext) => {
    systemConstants.storeDispatch = storeDispatch;
    systemConstants.storeContext = storeContext;
};

export const initSystemConstants = (QD_URI_PREFIX, id, onComplete) => {
    systemConstants.QD_URI_PREFIX = QD_URI_PREFIX.toString();
    systemConstants.QD_API_AUTH_URL = QD_URI_PREFIX + "/api/authenticate";
    systemConstants.QD_API_URL = QD_URI_PREFIX + "/api/v1/";
    systemConstants.QD_APP_URL = QD_URI_PREFIX + "/appapi/v1/";
    systemConstants.QD_BATCH_URL = QD_URI_PREFIX + "/batch-upload/load-files/";
    systemConstants.QD_HELP_URL = QD_URI_PREFIX + "/help/webhelp/QDAdminHelp.htm";
    systemConstants.QD_HELP_URL_QUESTION = QD_URI_PREFIX + "/help/csh/QD-CSH.htm#cshid=";

    systemConstants.initialized = true;
    if(onComplete) {
        systemConstants.onComplete = onComplete;
    }
    Object.freeze(systemConstants);

    //systemConstants.storeDispatch(responseProcess.setQuestionnaire(id));

};

export {systemConstants};

export const QD_URL = "admin";
export const QD_PORTAL = "portal";
export const STORAGE_TOKEN = "id_token";
export const STORAGE_USERNAME = "username";


export const ALERT_MESSAGE_QUESTIONNAIRE_INVALID_KEY = "This key is invalid.";
export const ALERT_MESSAGE_QUESTIONNAIRE_COMPLETED = "Completed and is no longer available.";
export const ALERT_MESSAGE_QUESTIONNAIRE_DEACTIVATED = "Deactivated and is no longer available.";
export const ALERT_MESSAGE_QUESTIONNAIRE_EXPIRED = "Expired and is no longer available.";
export const ALERT_MESSAGE_QUESTIONNAIRE_CANCELED = "Canceled. The link remains active until the expiration date.";
export const ALERT_MESSAGE_QUESTIONNAIRE_UNAVAILABLE = "This survey will be available for read-only viewing once the customer has opened the survey link.";
