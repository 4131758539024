import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {TableGrid, TableView} from '../../containers';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import AddIcon from "@mui/icons-material/Add";
import {getQuestionnaireTemplate, questionnaireTemplateInitClear, questionnaireTemplateCloneClear} from "../../actions/questionnaireTemplateActions";
import {openDrawer} from "../../actions/drawerActions";
import {QD_URL} from "../../constants/systemConstants";
import FilterPopOver from "../dialogs/filters/FilterPopOver";
import {QUESTIONNAIRE_COMPLEX} from "../../constants/questionnaireType";
import {setQuestionnaireType} from "../../actions/questionnaireEditorActions";
import QuestionnaireReviewDrawer, {questionnaireReviewDrawerProps} from "../drawers/QuestionnaireReviewDrawer";
import {getTemplateList, updatePageAndGetList, updateSortAndGetList} from "../../actions/templateListActions";
import formatCopyText from "../../utilities/formatCopyText";
import {useNavigate} from 'react-router-dom'
import ArgoListTitleAndLoadingAnimationHook from "../common/ArgoListTitleAndLoadingAnimationHook";
import * as TEMPLATE from "../../constants/templateConstants";
import * as SYSTEM from "../../constants/systemConstants";


class QuestionnaireList extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: false, filter: false, savePortalReferenceJson: false};

        //const theme = React.ThemeContext();
    }

    componentDidMount(){
        // let value = this.context;
        // debugger;
        this.setState({loading: true}, () => this.props.getTemplateList(this.props.filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
    }

    componentWillReceiveProps(nextProps) {

        if (this.state.filter) {
            this.setState({filter: false});
        }
        else if (nextProps.questionnaireInit.init) {
            this.setState({loading: true, filter: true},() =>  nextProps.questionnaireTemplateInitClear());
        }
        else if ((!this.state.loading) && (nextProps.questionnaireInit.clone)) {
            this.setState({loading: true, filter: true}, () =>  nextProps.questionnaireTemplateCloneClear());
        }
        else if ((this.state.loading) && (nextProps.questionnaireInit.clone)) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemCOnstants.js
                let delay = setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false});
            }
        }
        else if ((this.state.loading) && (!nextProps.questionnaireInit.init)) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemCOnstants.js
                let delay = setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false});
            }
        }
        else if ((this.state.loading) && (!nextProps.questionnaireInit.clone)) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemCOnstants.js
                let delay = setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false});
            }
        }
    }

    // Used to test loading animation rendering
    delayedSetLoadingFalse = () => {
        this.setState({loading: false});
    }


    navigateToEdit = (templateKey, templateId, templateMode, type) => {
        let path = QD_URL + "/questionnaire-editor/" + templateKey + "/" + templateId + "/0"; // CloneMode 0=No, 1=Yes
        this.props.navHook(path, {replace: true});

        //this.props.setQuestionnaireType(type);
    };

    navigateToClone = (templateKey, templateId, templateMode, type, row) => {
        let path = QD_URL + "/questionnaire-editor/" + templateKey + "/" + templateId + "/1"; // CloneMode 0=No, 1=Yes
        this.props.navHook(path, {replace: true});
        this.props.setQuestionnaireType(type);
    };

    navigateToCreate = (type) => {
        let path = QD_URL + "/questionnaire-editor/";
        this.props.navHook(QD_URL + "/questionnaire-editor/", {replace: true})
        this.props.setQuestionnaireType(type);
    };

    openQuestionnaireReviewDrawer = (templateKey, id, name, row, event) => {
        formatCopyText(row, event, "Questionnaire:"); // If user uses special keys when clicking title format the clipboard CTRL, ALT or CTRL + ALT
        this.props.openDrawer(questionnaireReviewDrawerProps, <QuestionnaireReviewDrawer templateKey={templateKey} templateId={parseInt(id)}/>);
    };

    onPageChange = (page, size, event) => {
        this.setState({loading: true, filter: true}, () => this.props.updatePageAndGetList(page, size, this.props.filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT, event));
    };

    onSortChange = (sort, direction) => {
        this.setState({loading: true, filter: true}, () => this.props.updateSortAndGetList(sort, direction, this.props.filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
    };

    setLoadingStateTrue = (callback = function () {}, parm1 = null, parm2 = null, calledFrom = "") => {
        //NOTE: This had to be different then the other list screens
        if ((parm1 !== null)&&(parm2 !== null)) {
            this.setState({loading: true, filter: true}, () => callback(parm1, parm2)); // This bust be from the change date drawer, so set filter flag also so render animation will work
        }
        else if (parm1 !== null) {
            this.setState({loading: true, filter: false}, () => callback(parm1));
        } else {
            this.setState({loading: true, filter: false}, () => callback());
        }
    }

    setSavePortalReferenceJsonTrue = (callback) => {
        //this.setState({savePortalReferenceJson: true}, () => callback());
        //if (this.state.savePortalReferenceJson) {
        this.setState({loading: true}, () => this.props.getTemplateList(this.props.filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
        //}
    }

    setFloatingActionButtonCreateOptions = () => {
        // NOTE: Plan is to drop support for simple and dynamic questionnaires
        return (
            <div>
                <Fab id="QuestionnaireList-fab"
                     onClick={this.navigateToCreate.bind(this, QUESTIONNAIRE_COMPLEX)}
                     color="primary">
                    <AddIcon color="white"/>
                </Fab>
            </div>
        );
        // return this.props.questionnaireTypeDisplayed === 'full' ?
        //     <Fab id="QuestionnaireList-menu"
        //          onClick={this.navigateToCreate.bind(this, QUESTIONNAIRE_COMPLEX))}
        //          iconButtonElement={
        //              <IconButton id="QuestionnaireList-menu-icon-button"
        //                          onClick={(event) => {event.stopPropagation();}}
        //                          style={{height: "56px", width: "56px"}}
        //                          iconStyle={{color: style.palette.alternateTextColor}}>
        //                   <AddIcon/>
        //               </IconButton>}
        //               useLayerForClickAway
        //               anchorOrigin={{horizontal: 'left', vertical: 'top'}}
        //               targetOrigin={{horizontal: 'left', vertical: 'top'}}
        //               style={{borderRadius: '50%', backgroundColor: style.palette.accent1Color, height: "56px", width: "56px", border: "10px", textAlign: "center"}}>
        //         <div>
        //             <MenuItem primaryText="Create simple questionnaire" onClick={this.navigateToCreate.bind(this, QUESTIONNAIRE_SIMPLE)}></MenuItem>
        //             {/*<MenuItem primaryText="Create dynamic questionnaire" onClick={this.navigateToCreate.bind(this, QUESTIONNAIRE_DYNAMIC)}></MenuItem>*/}
        //             <MenuItem primaryText="Create complex questionnaire" onClick={this.navigateToCreate.bind(this, QUESTIONNAIRE_COMPLEX)}></MenuItem>
        //         </div>
        //     </Fab>
        //     :
        //     <div>
        //         <Fab id="QuestionnaireList-fab"
        //              onClick={this.navigateToCreate.bind(this, this.props.questionnaireTypeDisplayed === 'simple-only' ? QUESTIONNAIRE_SIMPLE : QUESTIONNAIRE_COMPLEX)}>
        //             <AddIcon />
        //         </Fab>
        //     </div>
    };

    render() {

        //let value = this.context;
        //debugger;

        let customActions = [
            {
                func: this.openQuestionnaireReviewDrawer,
                label: 'Review'
            },
            {
                func: this.navigateToEdit,
                label: "Edit"
            },
            {
                func: this.navigateToClone,
                label: "Clone"
            }
        ];

        // Used by the versions list and stored in the version filters
        let defaultActions = {
            edit: this.navigateToEdit,
            create: this.navigateToCreate,
            review: this.openQuestionnaireReviewDrawer
        };

        return (
            <div id="QuestionnaireList-container-div" style={{height: "100%", padding: "15px", marginTop: '64px'}}>

                <ArgoListTitleAndLoadingAnimationHook fileName="QuestionnaireList" listTitle="Questionnaires" loading={this.state.loading} filter={this.state.filter}/>

                <div style={{clear: "both"}}></div>

                <Card id="QuestionnaireList-card"
                      variant="outlined"
                      sx={{border: "1px solid lightgray", height: "100%", boxShadow: "2px 4px whitesmoke"
                      }}>
                    <CardContent id="QuestionnaireList-card-content">

                        <TableView
                            id="QuestionnaireList-table-view"
                            templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                            templateMode={TEMPLATE.MODE.CURRENT}
                            setLoadingState={this.setLoadingStateTrue}
                            filter={<FilterPopOver page="questionnaires"
                                                   templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                                                   templateMode={TEMPLATE.MODE.CURRENT}
                                                   filterName={TEMPLATE.FILTER.current.questionnaire}
                                                   submitValue={this.props.getTemplateList}
                                                   statuses={["All", "Draft", "Published", "Retired"]}
                                                   setLoadingState={this.setLoadingStateTrue}
                                                   showOutstandingQuestionnaires={true}
                                                   outstanding
                            />}
                        >

                            <TableGrid
                                gridListType={TEMPLATE.GRID_LIST_TYPE.QUESTIONNAIRE_LIST}
                                id="QuestionnaireList-table-grid"
                                displayType={"questionnaire"}
                                dataSource={this.props.questionnaireTemplateList}
                                nameDrawer={this.openQuestionnaireReviewDrawer}
                                link={QD_URL +"/questionnaire-editor/"}
                                customActions={customActions}
                                pageState = {this.props.filter}
                                onPageChange = {this.onPageChange}
                                sortState = {this.props.filter}
                                onSortChange = {this.onSortChange}
                                templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                                templateMode={TEMPLATE.MODE.CURRENT}
                                showOutstanding={true}
                                showStartDate={true}
                                showEndDate={true}
                                showInUseCount={false}
                                showInUseAndVersionsSameColumn={true}
                                defaultActions={defaultActions}
                                setLoadingState={this.setLoadingStateTrue}
                                savePortalReferenceJson={this.setSavePortalReferenceJsonTrue}
                            />
                        </TableView>

                        <div id="QuestionnaireList-fab-div" title={"Create questionnaire"} style={{bottom: "2.5rem", right: "2.5rem", position: "fixed"}}>
                            <Fab id="QuestionnaireList-fab"
                                 onClick={this.navigateToCreate.bind(this, QUESTIONNAIRE_COMPLEX)}
                                 color="primary"
                                 sx={{float: "right"}}>
                                <AddIcon color="white"/>
                            </Fab>
                        </div>

                    </CardContent>

                </Card>

                {/*<div id="QuestionnaireList-fab" title={"Create questionnaire"} style={{bottom: "2.5rem", right: "2.5rem", position: "fixed"}}>*/}
                {/*    <Fab id="QuestionnaireList-fab"*/}
                {/*         onClick={this.navigateToCreate.bind(this, QUESTIONNAIRE_COMPLEX)}*/}
                {/*         color="primary"*/}
                {/*    sx={{float: "right"}}>*/}
                {/*        <AddIcon color="white"/>*/}
                {/*    </Fab>*/}
                {/*</div>*/}

            </div>
        );
    }
}


QuestionnaireList.propTypes = {
    getTemplateList: PropTypes.func,
    setQuestionnaireType: PropTypes.func,
    getQuestionnaireTemplate: PropTypes.func,
    openDrawer: PropTypes.func,
    updatePageAndGetList: PropTypes.func,
    updateSortAndGetList: PropTypes.func,
    questionnaireTemplateList: PropTypes.array,
    questionnaireTemplate: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    filter: PropTypes.object,
    match: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getTemplateList, setQuestionnaireType, getQuestionnaireTemplate, openDrawer, updatePageAndGetList, updateSortAndGetList, questionnaireTemplateInitClear, questionnaireTemplateCloneClear}, dispatch);
}

function mapStateToProps(state) {
    return {
        questionnaireTypeDisplayed: state.settings.questionnaireTypeDisplayed,
        questionnaireTemplate: state.questionnaireTemplate,
        questionnaireTemplateList: state.questionnaireTemplateList,
        questionnaireInit: state.questionnaireInit,
        filter: state.filter[TEMPLATE.FILTER.current.questionnaire]
    };
}

function myParams(Component,props) {
    return props => <Component {...props} navHook={useNavigate()} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(myParams(QuestionnaireList));

//export default withRouter(QuestionnaireList);
