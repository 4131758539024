import React, {Component, useContext} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import {questionnaireTemplateInit,
    getQuestionnaireTemplate,
    getQuestionnaireReference,
    getMetadataRequiredTokens,
    saveQuestionnaireTemplate,
    clearQuestionnaireReference}
from "../../actions/questionnaireTemplateActions";
//import {updateQuestionnaireTemplate} from "../../actions/questionnaireEditorActions";
import {formatDateDisplay, toLocale} from "../../utilities/dateFormats";
import ArgoAppBar from "../common/ArgoAppBar";
import ArgoTextFieldHook from "../common/ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "../common/ArgoTextFieldSelectHook";
import ArgoButtonHook from "../common/ArgoButtonHook";
import {QD_PORTAL_PATH} from '../../constants/systemConstants';
//import * as metadataTemplateTypes from "../../constants/metadataTemplateTypes";
// import {getMetadataDefinitions} from "../../actions/metadataDefinitionActions";
import * as portal from "../../utilities/portal";
import blueTheme from "../../blueTheme";
import {ALLOWED_TOKEN} from "../../constants/dataConstants";
import ClickAwayListener from '@mui/material/ClickAwayListener';


const leftCurlyBracket = "{";
const rightCurlyBracket = "}";
const standardIndent = "50px";
const metadataIndent = "90px";

// const testObj = {
//     "questionnaireTemplateId": 8,
//     "userKey": "blake",
//     "referenceUrl": "http://www.google.com",
//     "metadata": {
//         "DateOfEvent": "6/30/2020",
//         "FinancialInstitution": "ARGO Bank (Richardson) Branch",
//         "ChannelName": "Contact Center",
//         "NameOfProduct": "Personal Loan  ",
//         "TypeOfProduct": "Product Type",
//         "OperatorName": "Sally P."
//     }
// };


class QuestionnaireCreatePortalRefKeyDrawer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPublished: false,
            sendJsonNotSet: true,
            sendJson: {metadata: {}},
            templateSendJson: "",
            fullMetadataTokenArray: ALLOWED_TOKEN,
            unusedMetadataTokenArray: ALLOWED_TOKEN,
            metadataAddMode: true,
            metadataCurrentIndex: 0,
            metadataArray: [],
            newMetadataToken: "",
            newMetadataValue: "",
            metadataSaved: false
        };
    }



    componentDidMount() {
        this.props.getQuestionnaireTemplate(this.props.template.templateKey, this.props.template.id);
        this.displayRequiredTokens()
    }


    componentWillReceiveProps(nextProps) {

        if (this.state.sendJsonNotSet) {

            let tempQuestionnaireTemplate = {...nextProps.questionnaireTemplate[nextProps.template.templateKey]};

            if (tempQuestionnaireTemplate) {

                let sendJson = {metadata: {}};
                let templateSendJson = {metadata: {}};

                if (tempQuestionnaireTemplate.metadata.hasOwnProperty("sendJSON")) {
                    sendJson = JSON.parse(tempQuestionnaireTemplate.metadata["sendJSON"]);
                    templateSendJson = tempQuestionnaireTemplate.metadata["sendJSON"];
                }

                let unusedMetadataTokenArray = this.state.fullMetadataTokenArray;
                for (let key in sendJson.metadata) {
                    unusedMetadataTokenArray = unusedMetadataTokenArray.filter(e => e !== key); // remove used token
                }

                let metadataArray = [];
                for (let key in sendJson.metadata) {
                    if (sendJson.metadata.hasOwnProperty(key)) {
                        metadataArray.push({token: [key], value: sendJson.metadata[key]});
                    }
                }

                let isPublished = false;

                let publishedDate = nextProps.template.publishedDate;
                if (publishedDate !== null) {

                    let todayDate = new Date();

                    let currentDayOfMonth = todayDate.getDate();
                    let currentMonth = todayDate.getMonth() +1; // Be careful! January is 0, not 1
                    let currentYear = todayDate.getFullYear();

                    let todayYearMonthDay = currentYear.toString() + ((currentMonth < 10) ? ("0" + currentMonth) :  currentMonth) + ((currentDayOfMonth < 10) ? ("0" + (currentDayOfMonth)) : currentDayOfMonth);

                    let startDateYearMonthDay = formatDateDisplay(nextProps.template.startDate,"YearMonthDay");
                    let endDateYearMonthDay = formatDateDisplay(nextProps.template.endDate,"YearMonthDay");

                    //let startDateYearMonthDay = startDate.substr(6,4) + startDate.substr(0,2) + startDate.substr(3,2);
                    //let endDateYearMonthDay = endDate.substr(6,4) + endDate.substr(0,2) + endDate.substr(3,2);


                    if ((endDateYearMonthDay === "" ) && (startDateYearMonthDay <= todayYearMonthDay)) {
                        isPublished = true;
                    } else if ((startDateYearMonthDay <= todayYearMonthDay) && (endDateYearMonthDay > todayYearMonthDay)) {
                        isPublished = true;
                    }

                }

                this.setState({
                    isPublished: isPublished,
                    sendJsonNotSet: false,
                    sendJson: sendJson,
                    templateSendJson: templateSendJson,
                    metadataArray: metadataArray,
                    unusedMetadataTokenArray: unusedMetadataTokenArray
                });


                nextProps.clearQuestionnaireReference();
            }
        }

    }


    onSelectChange = (event, fieldName, menuItemText) => {
        let value = event.target.value;
        this.setState({[fieldName]: value});
        value = this.state.unusedMetadataTokenArray[value];
    };


    onTextChange = (event, fieldName) => {
        let value = event.target.value;

        this.setState({[fieldName]: value});
    };


    addTokenAndValueLine = (addToSendObj, key, value, indentPx = "60px", index = 0, isURL = false, valueBlack = false) => {

        let displayValue = (value === null) ? "" : value;

        if (addToSendObj && (key !== "") && (value !== "{") && (value !== "}")) {

            let tempSendJson = {...this.state.sendJson};
            let jsonChanged = false;

            if (!tempSendJson.hasOwnProperty("metadata")) {
                tempSendJson["metadata"] = {};
            }

            // If not metadata and key does not exist then add key
            if ((indentPx !== metadataIndent) && !tempSendJson.hasOwnProperty(key)) {
                tempSendJson[key] = value;
                jsonChanged = true;
            }
            else if ((indentPx === metadataIndent) && (!tempSendJson.metadata.hasOwnProperty(key))) {
                tempSendJson.metadata[key] = value;
                jsonChanged = true;
            }

            if (jsonChanged) {
                this.setState({sendJson: tempSendJson});
            }
        }

        return <div key="div01" style={{display: "flex", alignItems: "center", width: "100%"}}>
            <div key={key + "1" + index} style={{width: indentPx, paddingTop: "10px"}}>&nbsp;</div>
            <div key={key + "2" + index}>
                {key === "" ?
                    <div key="div02" style={{float: "left", fontSize: "14pt", color: "black"}}>{key}</div>
                    :
                    <div key="div03" style={{float: "left", fontSize: "14pt", color: "red"}}>{'"' + key + '"'} &nbsp;</div>
                }
            </div>
            <div key={key + "3" + index}>
                {key === "" ?
                    <div key="div04" style={{float: "left", fontSize: "14pt"}}>{key}</div>
                    :
                    <div key="div05" style={{float: "left", fontSize: "14pt"}}>:</div>
                }
            </div>
            <div key={key + "4" + index} style={{float: "left", fontSize: "14pt", }}>&nbsp;</div>
            <div key={key + "5" + index}>
                {isURL ?
                    <div key="div05" style={{float: "left", fontSize: "14pt", color: "blue", textDecoration: "underline"}}>{'"' + displayValue + '"'}</div>
                    :
                    <div key="div06" style={{float: "left", fontSize: "14pt", color: valueBlack ? "black" : "blue"}}>{valueBlack ? displayValue : ('"' + displayValue + '"')}</div>
                }
            </div>
            <div key={key + "6" + index} style={{paddingLeft: "15px"}}>
                &nbsp;&nbsp;&nbsp;
                {((indentPx === metadataIndent) && (index !== -1)) ?

                    <ArgoButtonHook
                        fileName="QuestionnaireCreatePortalRefKeyDrawer"
                        fieldName="remove"
                        label="REMOVE"
                        width={100}
                        onClick={this.removeMetadata}
                    />
                    :
                    ""
                }
            </div>
            <div key={key + "7" + index} style={{float: "clear"}}></div>
        </div>

    };


    metadataAddLine = () => {

        return <div key="metadataAddLine01" style={{display: "flex", width: "100%"}}>
            <div key="metadataAddLine02" style={{width: "76px", paddingTop: "10px"}}>&nbsp;</div>



            <div key="metadataAddLine03-ADD-button" style={{alignItems: "left", paddingTop: '14px', paddingLeft: "20px"}}>
                <ArgoButtonHook
                    fileName="QuestionnaireCreatePortalRefKeyDrawer"
                    fieldName="add"
                    label="ADD"
                    width={100}
                    style={{textAlign:'center',padding:"10px"}}
                    onClick={this.changeMetadataAddModeToFalse}
                />
            </div>

            <div key="metadataAddLine03" style={{float: "clear"}}></div>
        </div>
    };


    changeMetadataAddModeToFalse = () => {

        this.setState({metadataAddMode: false});
    };


    displayMetadataLine = (index) => {

        return <div key="displayMetadataLine01" style={{display: "flex", width: "100%"}}>

            <div id="QuestionnaireCreatePortalRefKeyDrawer-spacer-div-1" style={{width: "100px"}}>&nbsp;</div>

            <div id="QuestionnaireCreatePortalRefKeyDrawer-metadata-container-div" style={{float: "left", paddingTop: "10px"}}>

                <ArgoTextFieldSelectHook
                    fileName="QuestionnaireCreatePortalRefKeyDrawer"
                    fieldName="newMetadataToken"
                    label="Substitution Token"
                    value={this.state.newMetadataToken}
                    onChange={this.onSelectChange}
                    width="200px"
                    menuItems={this.state.unusedMetadataTokenArray}
                />
            </div>

            <div id="QuestionnaireCreatePortalRefKeyDrawer-spacer-div-2" style={{float: "left", fontSize: "14pt", paddingTop: "30px"}}>&nbsp; : &nbsp;</div>

            <div id="QuestionnaireCreatePortalRefKeyDrawer-spacer-div-3" style={{float: "left", fontSize: "14pt", width: "10px"}}>&nbsp;</div>

            <div  key="displayMetadataLine06" style={{float: "left", fontSize: "14pt", paddingTop: "10px" }} >
                <ArgoTextFieldHook
                    fileName="QuestionnaireCreatePortalRefKeyDrawer"
                    fieldName="newMetadataValue"
                    label="Substitution Value"
                    value={this.state.newMetadataValue}
                    onChange={this.onTextChange}
                    width="300px"
                />
            </div>

            <div id="QuestionnaireCreatePortalRefKeyDrawer-spacer-div-4" style={{float: "left", fontSize: "14pt"}}>&nbsp;</div>

            <ArgoButtonHook
                fileName="QuestionnaireCreatePortalRefKeyDrawer"
                fieldName="saveMetadata"
                label="SAVE"
                width={100}
                paddingTop={"23px"}
                onClick={this.saveMetadata}
            />

            <div id="QuestionnaireCreatePortalRefKeyDrawer-spacer-div-5" style={{float: "left", fontSize: "14pt", }}>&nbsp;</div>

            <ArgoButtonHook
                fileName="QuestionnaireCreatePortalRefKeyDrawer"
                fieldName="cancelMetadata"
                label="CANCEL"
                width={100}
                paddingTop={"23px"}
                onClick={this.cancelMetadata}
            />

            <div id="QuestionnaireCreatePortalRefKeyDrawer-clear-div" style={{float: "clear"}}></div>
        </div>

    };


    saveMetadata = () => {

        //let tokenExists = false;

        if (this.state.newMetadataToken) {
            let newMetadataToken = this.state.unusedMetadataTokenArray[this.state.newMetadataToken]
            let metadataArray = this.state.metadataArray; // get current used metadata tokens
            let unusedMetadataTokenArray = this.state.unusedMetadataTokenArray.filter(e => e !== newMetadataToken); // remove used token

            metadataArray.push({token: newMetadataToken, value: this.state.newMetadataValue});

            this.setState({metadataAddMode: true, newMetadataToken: "", newMetadataValue: "", metadataCurrentIndex: (this.state.metadataCurrentIndex + 1), unusedMetadataTokenArray: unusedMetadataTokenArray, metadataArray: metadataArray});

        }
        else {
            this.setState({metadataAddMode: true});
        }
    };


    cancelMetadata = () => {
        this.setState({metadataAddMode: true, newMetadataToken: "", newMetadataValue: ""});
    };


    removeMetadata = (index) => {

        let metadataArray = this.state.metadataArray;
        //let removedMetadataObj = metadataArray[index];  // For debugging

        metadataArray.splice(index, 1); // remove 1 obj from index location

        let unusedMetadataTokenArray = this.state.fullMetadataTokenArray;  // reset unused tokens to all tokens

        for (let i = 0; i < metadataArray.length; i++) {
            unusedMetadataTokenArray = unusedMetadataTokenArray.filter(e => e !== metadataArray[i].token);  // remove used tokens
        }

        this.setState({metadataCurrentIndex: (this.state.metadataCurrentIndex -1), metadataArray: metadataArray, unusedMetadataTokenArray: unusedMetadataTokenArray});
    };

    displayRequiredTokens = () => {
        this.props.getMetadataRequiredTokens(this.props.templateId)
    }


    sendJSON = () => {
        // NOTE: because the sendJson object is saved in the questionnaire metadata
        // when moved between servers the questionnaire.id will change and is required
        // when you GET a questionnaire reference key.  This was causing portal to
        // malfunction getting the wrong metadata for the template.
        let tempSendJson = {...this.state.sendJson};

        //tempSendJson.referenceUrl = QD_PORTAL_PATH; // NO more sending reference URL per Nirav
        tempSendJson.questionnaireTemplateId = this.props.template.id;
        this.props.getQuestionnaireReference(tempSendJson);
    };


    saveJSON = () => {

        let updatedTemplate = JSON.parse(JSON.stringify(this.props.questionnaireTemplate[this.props.template.templateKey]));

        let tempSendJson = {metadata: this.state.sendJson.metadata};
        updatedTemplate.metadata["sendJSON"] = JSON.stringify(tempSendJson);

        updatedTemplate.startDate = null;
        updatedTemplate.endDate = null;
        updatedTemplate.notes = null;

        this.setState({isPublished: false, templateSendJson: updatedTemplate.metadata["sendJSON"], metadataSaved: true}, this.props.saveQuestionnaireTemplate(updatedTemplate));
    };


    closeDrawer = () => {
        if (this.state.metadataSaved) {
            this.props.savePortalReferenceJson(this.closeCreatePortalRefKeyDrawer);  // Tell questionnaire list screen that it needs to relaod because portal reference key was saved
        }

        this.props.closeDrawer();
    };


    render() {

        const test = blueTheme;

        let currJsonString = JSON.stringify(this.state.sendJson);
        let savedJsonString = JSON.stringify(this.state.templateSendJson);

        let refKey = this.props.questionnaireReference.hasOwnProperty("key") ? this.props.questionnaireReference.key : "";
        //let refReadOnlyKey = this.props.questionnaireReference.hasOwnProperty("readOnlyKey") ? this.props.questionnaireReference.readOnlyKey : "";


        let referenceUrl = "refUrl"// portal.getPortalRefURL();

        let rtnJsonObj = this.props.questionnaireReference;

        return (
            <ClickAwayListener onClickAway={this.closeDrawer}>
                <div key="baseDiv" style={{height: "100%"}}>
                    <ArgoAppBar
                        title={"Create Portal Reference Key:"}
                        titleFor={this.props.template.name}
                        showMenuArrowButton="LEFT"
                        onLeftIconButtonTouchTap={this.closeDrawer}
                        isDrawer={true}
                        width={questionnaireCreatePortalRefKeyDrawerProps.width}
                    />

                    <div style={{height: '64px'}}></div>

                    <div key="MainParentDivSendJson" style={{height: "100%"}}>
                        <div key="SendJson01" style={{height: "40px"}}>&nbsp;</div>
                        <div key="SendJson02" style={{height: "40px", paddingLeft: "40px"}}><h2><b>Create Portal Reference Keys</b></h2></div>
                        <div key="SendJson03" style={{height: "40px"}}>&nbsp;</div>
                        <div key="SendJson04" style={{height: "40px", paddingLeft: "40px"}}><h3><b>Send Details</b></h3></div>
                        <div key="SendJson05" style={{height: "10px"}}>&nbsp;</div>
                        <div key="SendJson06" style={{paddingLeft: "40px", fontSize: "14pt"}}><b>{leftCurlyBracket}</b></div>
                        <div key="SendJson07">{this.addTokenAndValueLine(true, "questionnaireTemplateId", this.props.templateId, standardIndent)}</div>
                        <div key="requiredTokens">{this.addTokenAndValueLine(false, "requiredTokens", Object.values(this.props.questionnaireRequiredTokens).join(", "), standardIndent)}</div>
                        {/*<div key="SendJson08">{this.addTokenAndValueLine(true, "userKey", this.props.login.username, standardIndent)}</div>*/}
                        {/*<div key="SendJson09">{this.addTokenAndValueLine(true, "referenceUrl", referenceUrl, standardIndent, 0,true)}</div>*/}
                        <div key="SendJson10">{this.addTokenAndValueLine(true, "metadata", leftCurlyBracket, standardIndent, 0,false, true)}</div>
                        <div key="SendJsonMetadataParent">
                            {this.state.metadataArray.map((metadataObj, index) =>
                                <div key={"metadataObj-" + index} style={{paddingTop: "10px"}}>{this.addTokenAndValueLine(true, metadataObj.token, metadataObj.value, metadataIndent, index)}</div>
                            )}
                        </div>
                        <div key="SendJsonMetadataAddMode">
                            {this.state.metadataAddMode ?
                                <div key="SendJsonMetadataAddMode1">{this.metadataAddLine()}</div>
                                :
                                <div key="SendJsonMetadataAddMode2">{this.displayMetadataLine(this.state.metadataCurrentIndex)}</div>
                            }
                        </div>

                        <div key="MetadataRightCurlyBracket">{this.addTokenAndValueLine(false, "", rightCurlyBracket, standardIndent, 0,false, true)}</div>

                        <div key="leftCurlyBracketMain" style={{paddingLeft: "40px", fontSize: "14pt"}}><b>{rightCurlyBracket}</b></div>

                        <div key="OuterButtonContainer" style={{display: "flex", width: "100%"}}>
                            <div key="SendButtonContainer" style={{float: "left", paddingTop: '14px', paddingLeft: "40px"}}>
                                <ArgoButtonHook
                                    fileName="QuestionnaireCreatePortalRefKeyDrawer"
                                    fieldName="send"
                                    onClick={this.sendJSON.bind(this)}
                                    label={"SEND"}
                                    width={100}
                                    disabled={!this.state.metadataAddMode || !this.state.isPublished}
                                    canHaveErrorMessage={true}
                                    errorMessage={this.state.isPublished ? (!this.state.metadataAddMode ? "Send is disabled when in metadata add mode." : "") : "Send is disabled unless the questionnaire is published."}
                                />
                            </div>
                            <div key="ButtonContainerSpacer" style={{float: "left", width: "20px"}}>&nbsp;</div>
                            <div key="SaveButtonContainer" style={{
                                float: "left",
                                paddingTop: '14px',
                                paddingLeft: "40px",
                                display: currJsonString === savedJsonString ? "none" : "inline"
                            }}>
                                <ArgoButtonHook
                                    fileName="QuestionnaireCreatePortalRefKeyDrawer"
                                    fieldName="saveJSON"
                                    label={"SAVE"}
                                    width={100}
                                    disabled={!this.state.metadataAddMode}
                                    onClick={this.saveJSON.bind(this)}
                                />
                            </div>
                        </div>

                        <div key="NoteContainer" style={{paddingLeft: "40px", paddingTop: "20px"}}>
                            Note: Only published questionnaires allow the "Send Details" JSON to be sent, and saving the
                            "Send Details will change a published questionnaire to draft.
                        </div>

                        <div key="RtnJsonContainer">
                            {Object.keys(rtnJsonObj).length === 0 ? "" :
                                <div>
                                    <div key="rtnJson01" style={{height: "40px"}}>&nbsp;</div>
                                    <div key="rtnJson02" style={{height: "20px", paddingLeft: "40px"}}><h3><b>Return
                                        Details</b></h3></div>
                                    <div key="rtnJson03" style={{height: "10px"}}>&nbsp;</div>
                                    <div key="rtnJson04" style={{paddingLeft: "40px", fontSize: "14pt"}}>
                                        <b>{leftCurlyBracket}</b></div>
                                    <div
                                        key="rtnJson05">{this.addTokenAndValueLine(false, "key", rtnJsonObj["key"], standardIndent)}</div>
                                    <div
                                        key="rtnJson06">{this.addTokenAndValueLine(false, "readOnlyKey", rtnJsonObj["readOnlyKey"], standardIndent)}</div>
                                    <div
                                        key="rtnJson07">{this.addTokenAndValueLine(false, "questionnaireTemplateId", rtnJsonObj["questionnaireTemplateId"], standardIndent)}</div>
                                    <div
                                        key="rtnJson08">{this.addTokenAndValueLine(false, "questionnaireTemplateKey", rtnJsonObj["questionnaireTemplateKey"], standardIndent)}</div>
                                    <div
                                        key="rtnJson09">{this.addTokenAndValueLine(false, "id", rtnJsonObj["key"], standardIndent)}</div>
                                    <div
                                        key="rtnJson10">{this.addTokenAndValueLine(false, "questionnaireId", rtnJsonObj["id"], standardIndent)}</div>
                                    <div
                                        key="rtnJson11">{this.addTokenAndValueLine(false, "userKey", rtnJsonObj["userKey"], standardIndent)}</div>
                                    <div
                                        key="rtnJson12">{this.addTokenAndValueLine(false, "referenceUrl", rtnJsonObj["referenceUrl"], standardIndent, 0, true)}</div>
                                    <div
                                        key="rtnJson13">{this.addTokenAndValueLine(false, "readOnlyUrl", rtnJsonObj["readOnlyUrl"], standardIndent, 0, true)}</div>
                                    <div
                                        key="rtnJson14">{this.addTokenAndValueLine(false, "source", rtnJsonObj["source"], standardIndent)}</div>
                                    <div
                                        key="rtnJson15">{this.addTokenAndValueLine(false, "sourceMethod", rtnJsonObj["sourceMethod"], standardIndent)}</div>
                                    <div
                                        key="rtnJson16">{this.addTokenAndValueLine(false, "expirationDate", rtnJsonObj["expirationDate"], standardIndent)}</div>
                                    <div
                                        key="rtnJson17">{this.addTokenAndValueLine(false, "deactivatedDate", rtnJsonObj["deactivatedDate"], standardIndent)}</div>
                                    <div
                                        key="rtnJson18">{this.addTokenAndValueLine(false, "firstQuestionHtml", rtnJsonObj["firstQuestionHtml"], standardIndent)}</div>
                                    <div
                                        key="rtnJson19">{this.addTokenAndValueLine(false, "metadata", leftCurlyBracket, standardIndent, 0, false, true)}</div>
                                    <div key="rtnJson20">
                                        {this.state.fullMetadataTokenArray.map((key, index) =>
                                            <div
                                                key={"rtnMetadataJSON-" + index}> {rtnJsonObj.metadata.hasOwnProperty(key) ?
                                                <div key={"rtnMetadataJSON-Line-" + index}
                                                     style={{
                                                         paddingTop: "5px",
                                                         width: "400px"
                                                     }}>{this.addTokenAndValueLine(false, key, rtnJsonObj.metadata[key], metadataIndent, -1)}</div>
                                                : ""
                                            }
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        key="rtnMetadataJSON-rightCurlyBracket">{this.addTokenAndValueLine(false, "", rightCurlyBracket, standardIndent, 0, false, true)}</div>

                                    <div key="rightCurlyBracketMain" style={{paddingLeft: "40px", fontSize: "14pt"}}>
                                        <b>{rightCurlyBracket}</b></div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}


QuestionnaireCreatePortalRefKeyDrawer.defaultProps = {

};

QuestionnaireCreatePortalRefKeyDrawer.propTypes = {
    templateId: PropTypes.number,
    template: PropTypes.object,
    questionnaireTemplate: PropTypes.object,
    questionnaireReference: PropTypes.object,
    questionnaireRequiredTokens: PropTypes.array,
    questionnaireTemplateGraph: PropTypes.object,
    login: PropTypes.object,
    savePortalReferenceJson: PropTypes.func
};

function mapStateToProps(state, props) {
    return {
        template: state.openCloseSecondaryDrawer.template,
        questionnaireTemplate: state.questionnaireTemplate,
        questionnaireReference: state.questionnaireReference,
        questionnaireRequiredTokens: state.questionnaireRequiredTokens,
        questionnaireTemplateGraph: state.questionnaireTemplateGraph,
        login: state.login
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeDrawer,
        questionnaireTemplateInit,
        getQuestionnaireTemplate,
        saveQuestionnaireTemplate,
        getQuestionnaireReference,
        getMetadataRequiredTokens,
        clearQuestionnaireReference,
        //updateQuestionnaireTemplate
    }, dispatch);
}


const questionnaireCreatePortalRefKeyDrawerProps = {
    opensecondary: true,
    width: "70%",
    docked: false,
    anchor: "right"
};


export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireCreatePortalRefKeyDrawer);
export {questionnaireCreatePortalRefKeyDrawerProps};
