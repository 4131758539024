import React, {useContext} from 'react';
import UserContext from "../../UserContext"
import ArgoButtonHook from "./ArgoButtonHook";


export default function ArgoToolbarHook(props) {

    const theme = useContext(UserContext)

    const fileName = props.fileName === undefined ? "NoFileName" : props.fileName;
    const fieldName = props.fieldName === undefined ? "NoFieldName" : props.fieldName;

    const uniqueIdentifier = fileName + "-ArgoToolbarHook-" + fieldName;
    const uniqueIdentifierErrorText = uniqueIdentifier + "error-message";

    const buttonTitles = props.buttonTitles === undefined ? ["SAVE","CANCEL"] : props.buttonTitles;

    const toolbarErrorText = (props.toolbarErrorText === undefined || props.toolbarErrorText.trim() === "") ? "" : "Errors on page: " + props.toolbarErrorText;

    const leftButtonDisabled = props.leftButtonDisabled === undefined ? (toolbarErrorText !== "") : props.leftButtonDisabled;

    const onClick = props.onClick === undefined ? function () {} : props.onClick;

    const width = props.width === undefined ? "100%" : props.width;


    const handleLeftButtonOnClick = (event) => {
        onClick(event, fieldName, buttonTitles[0]);
    };

    const handleRightButtonOnClick = (event) => {
        onClick(event, fieldName, buttonTitles[1]);
    };


    return (
        <div id={uniqueIdentifier}
             style={{backgroundColor: theme.toolbar.backgroundColor,
                 width: width,
                 height: "50px",
                 fontFamily: theme.fontFamily,
                 position: 'fixed',
                 zIndex: 1000}}
        >
            <div style={{float: "left", paddingLeft: "20px" , paddingTop: "5px"}}>
                <ArgoButtonHook
                    fileName="ArgoToolBarHook"
                    fieldName="button-left"
                    label={buttonTitles[0]}
                    disabled={leftButtonDisabled}
                    toolbar={true}
                    onClick={handleLeftButtonOnClick}
                    width="100px"
                />
            </div>
            <div style={{float: "left", width: "20px"}}>&nbsp;</div>
            <div id={uniqueIdentifierErrorText} name={uniqueIdentifierErrorText} style={{float: "left", color: theme.palette.errorColor, paddingTop: "15px", fontSize: "12px"}}>{leftButtonDisabled ? toolbarErrorText : ""}</div>
            {(buttonTitles.length > 1) ?
                <div style={{float: "right", paddingRight: "20px", paddingTop: "5px"}}>
                    <ArgoButtonHook
                        fileName="ArgoToolBarHook"
                        fieldName="button-right"
                        variant="text"
                        label={buttonTitles[1]}
                        toolbar={true}
                        onClick={handleRightButtonOnClick}
                        width="100px"
                    />
                </div> : ""
            }
        </div>
    );
}
