import React from "react";
import {NumericFormat} from 'react-number-format';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import {styled} from "@mui/material/styles";
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import {getLabelWidth} from "../utilities/getLabelWidth"; // Used for state selection DDLB in Address Component
import * as mode from "../utilities/displayOneQuestionPerPage";
import * as font from "../utilities/fontUtilities";
import * as questionTypes from "../constants/questionTypeConstants";
import * as ddo from "../constants/customObjConstants";
import * as tag from "../constants/customMetadataConstants";
import {saveAnswerTypeSingleSelect} from "./SingleSelect";
import {
    DEFAULT_TEXT_FIELD_PLACEHOLDER_COLOR, DEFAULT_TEXT_FIELD_PLACEHOLDER_OPACITY,
    DEFAULT_TEXT_FIELD_TEXT_BORDER_COLOR_HOVERED, DEFAULT_WHITE,
    DISPLAY_DEF_PLACEHOLDER_COLOR,
    DISPLAY_DEF_TEXT_COLOR_FOCUSED, DISPLAY_DEF_TEXT_PLACEHOLDER_COLOR
} from "../constants/customMetadataConstants";


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
//<editor-fold desc="[CODE SECTION] Text Question Save Answer Routines ----------------------------------------------------------------------">


function saveAnswerTypeText(callerRefStr, savedObjs, event) {

    const {
        question,
        surveyEditMode,
        displayMode
    } = savedObjs.props;

    let enteredValue = event.target.value;

    let answer = savedObjs.question.answerText;

    // -----------------------------------------------------------------------------------------------------------------
    // Special handeling for From/To date stored as a comma delimited From/To
    if ((callerRefStr === "dateRangeEntryFrom-date-range")||(callerRefStr === "dateRangeEntryTo-date-range")) {
        answer = (answer) ? answer.split(",") : ["", ""];
        enteredValue = (callerRefStr === "dateRangeEntryFrom-date-range") ? enteredValue + "," + answer[1] : answer[0]  + "," + enteredValue;
    }
    // -----------------------------------------------------------------------------------------------------------------
    // Special Handeling for Address (Compplex QUestion Type)
    let addressAnswer = savedObjs.question.addressAnswerDTO !== null ? {...savedObjs.question.addressAnswerDTO} : {address: "", extended: "", city: "", zipPostalCode: ""};
    let addressAnswerSave = {...addressAnswer};
    if (callerRefStr !== "") {
        if (callerRefStr.substring(0, 8) === "address-") {

            addressAnswer[callerRefStr.substring(8)] = enteredValue;
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    if (surveyEditMode === mode.EDITABLE_SURVEY) {

        if (callerRefStr.substring(0, 8) === "address-") {
            if ((addressAnswer.address !== addressAnswerSave.address) ||
                (addressAnswer.extended !== addressAnswerSave.extended) ||
                (addressAnswer.city !== addressAnswerSave.city) ||
                (addressAnswer.zipPostalCode !== addressAnswerSave.zipPostalCode))
            {
                if (displayMode === mode.INSTANT_MODE) {
                    savedObjs.props.answerQuestionText(question, {addressAnswer: addressAnswer});
                }
                else {
                    savedObjs.props.answerQuestionTemplateText(question, {addressAnswer: addressAnswer});
                }
            }
        }
        else {

            if (question.answerText === enteredValue) {
                return; //If the answer didn't change, don't update it
            }

            // See if template or instance
            if (displayMode === mode.INSTANT_MODE) {
                savedObjs.props.answerQuestionText(question, {answerText: enteredValue});
            } else {
                savedObjs.props.answerQuestionTemplateText(question, {answerText: enteredValue});
            }
        }

    }
}


//</editor-fold>
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
//<editor-fold desc="[CODE SECTION] Text Entry Fields (QUESTION) -------------------------------------------------------------------------">


export function textEntryQuestion(textEntryType, inputType, itemObj) {

    //const self = this;
    const {questionnaire, question, questionNumber, surveyEditMode, displayMode, answerQuestionOptions, answerQuestionTemplateOptions} = this.props;

    let textObj = ddo.getQuestionNameText(question, questionNumber);

    // Used for state DDLB in address  ---------------------------------------------------------------------------------
    let answers = [];
    let selectedAnswer = null;

    if (inputType === "address") {
        answers = ddo.getAnswerOptions(question);
        selectedAnswer = answers.find((answerOption) => {
            return answerOption.selected === true;
        });

        if (!selectedAnswer) {
            selectedAnswer = {
                id: ""
            };
        }
    }
    // -----------------------------------------------------------------------------------------------------------------

    let savedObjs = {
        itemObj: itemObj,
        inputType: inputType,
        inputModifier: "",
        questionnaire: questionnaire,
        question: question,
        questionNameHidden: (tag.metadataValue(question, tag.DISPLAY_DEF_NAME) === "hide"),
        questionNumber: questionNumber === "0" ? "" : questionNumber,
        questionTextObj: textObj,
        answerText: "",
        answers: answers, // Used for states DDLB in address
        answerQuestionOptions: answerQuestionOptions,
        answerQuestionTemplateOptions: answerQuestionTemplateOptions,
        selectedAnswer: selectedAnswer, // Used for states DDLB in address
        displayMode: displayMode,
        surveyEditMode: surveyEditMode,
        readonlyMode: (surveyEditMode === mode.READONLY_SURVEY),
        //indent: tag.metadataValue(activeTemplate, tag.INDENT_NAME, "", "0px"),
        props: this.props
    }

    let htmlObj = "";

    switch(textEntryType) {
        case questionTypes.FREE_TEXT:
            htmlObj = freeTextEntry(savedObjs);
            break;
        case questionTypes.EMAIL:
            htmlObj = freeTextEntry(savedObjs);
            //htmlObj = emailTextEntry(savedObjs);
            break;
        case questionTypes.ZIPPOSTAL_ONLY:
        case questionTypes.PHONE:
            savedObjs.inputType = "text"; // trick it for until we can fix
            htmlObj = freeTextEntry(savedObjs); // Quick fix
            break;
        case questionTypes.NUMBER:
        case questionTypes.CURRENCY:
            htmlObj = numericTextEntry(savedObjs, textEntryType);
            break;
        case questionTypes.DATE:
            htmlObj = freeTextEntry(savedObjs);
            //htmlObj = singleDateEntry(savedObjs, "singleDateEntry-");
            break;
        case questionTypes.DATE_RANGE:
            savedObjs.inputType = "date"; // should be date-range, but trick it for until we can fix
            htmlObj = dateRangeEntry(savedObjs);
            break;
        case questionTypes.ADDRESS_FULL:
            htmlObj = <div>Question Type "Address" is not currently available for Portal Preview</div>;
            //htmlObj = addressAnswerEntry(savedObjs);
            break;
        // no default
    }

    return htmlObj;
}


function getTextField(self, savedObjs, entryType, textPropsObj, textStyleObj, styledObj, callerRefStrOverride = "", formatFunction = null) {

    let styleObj = {
        fontSize: textStyleObj.fontObj.fontSize,
        fontStyle: textStyleObj.fontObj.fontStyle,
        fontWeight: textStyleObj.fontObj.fontWeight,
        fontFamily: textStyleObj.fontObj.fontFamily,
    }

    let error = textPropsObj.errorFunction(savedObjs.question.answerText);

    let callerRefStr = (callerRefStrOverride !== "") ? callerRefStrOverride : ("textEntry-" + textPropsObj.type)

    let answerText = savedObjs.question.answerText !== null ? savedObjs.question.answerText : "";

    // If an address field then just pull the required field out
    if ((savedObjs.question.addressAnswerDTO !== undefined)&&(savedObjs.question.addressAnswerDTO !== null)) {
        answerText = savedObjs.question.addressAnswerDTO[callerRefStrOverride.substring(8)];
    }

    let autoComplete = "off";
    if (callerRefStrOverride !== "") {
        if (callerRefStrOverride.substring(0,8) === "address-") {
            autoComplete = "new-pasword";
        }
    }

    let placeholderColor = DEFAULT_TEXT_FIELD_PLACEHOLDER_COLOR;
    let placeholderOpacity = DEFAULT_TEXT_FIELD_PLACEHOLDER_OPACITY;
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_TEXT_PLACEHOLDER_COLOR)) {
        placeholderColor = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_TEXT_PLACEHOLDER_COLOR, "", placeholderColor));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_TEXT_PLACEHOLDER_OPACITY)) {
        placeholderOpacity = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_TEXT_PLACEHOLDER_OPACITY, "", placeholderOpacity));  // Some of the theme props wont take a color string
    }

    return {
        type: savedObjs.inputType,
        defaultValue: answerText,
        id: entryType + "-" + savedObjs.question.id,
        label: ((textPropsObj.floatingLabel === undefined) ? "" : (textPropsObj.floatingLabel.length > 0) ? (textPropsObj.floatingLabel + tag.NON_BREAKING_SPACE) : ""),
        placeholder: textPropsObj.placeholder,
        multiline: textPropsObj.multiline,
        maxRows: textPropsObj.maxrows,
        variant: textPropsObj.variant,
        size: textPropsObj.size,
        onBlur: saveAnswerTypeText.bind(self, callerRefStr, savedObjs),
        error: error.flag,
        helperText: error.message,
        disabled: savedObjs.readonlyMode,
        inputProps: {maxLength: textPropsObj.maxLength, autoComplete: autoComplete, style: {...styleObj}},
        InputLabelProps: {
            style: {
                backgroundColor: textPropsObj.variant === "outlined" ? "white" : "",
                autoComplete: "off", ...styleObj
            }
        },
        sx: {
            input: {
                "&::placeholder": {
                    color: placeholderColor,
                        opacity: placeholderOpacity
                }
            }
        }
    }
}

//</editor-fold>
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


// ---------------------------------------------------------------------------------------------------------------------
// Free Text Entry
// ---------------------------------------------------------------------------------------------------------------------


function freeTextEntry(savedObjs, callerRefStrOverride, tagsArray, labelTagAbove = tag.TEXT_LABEL_ABOVE, labelTagBelow = tag.TEXT_LABEL_BELOW) {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs, tagsArray);

    textPropsObj.multiline = tag.metadataExists(savedObjs.question, tag.TEXT_MULTILINE);
    textPropsObj.maxrows = tag.metadataValue(savedObjs.question, tag.TEXT_MAXROW, "", "1");

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, callerRefStrOverride);

    let additionalProps = getTextField(self, savedObjs, "freetext", textPropsObj, textStyleObj, styledObj, callerRefStrOverride);

    return (
        //<form>
        <FormControl key={savedObjs.question.id} style={{width: textPropsObj.width}}>
            {addLabel(savedObjs, labelTagAbove)}
            <styledObj.styledTextField
                label={styledObj.floatingLabel}
                name={textPropsObj.placeholder}
                variant={textPropsObj.variant}
                size={textPropsObj.size}
                {...additionalProps}
            />
            {addLabel(savedObjs, labelTagBelow)}
        </FormControl>
        //</form>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// eMail Text Entry
// ---------------------------------------------------------------------------------------------------------------------


function validateEmail(answerText) {
    // regex checks for one and only 1 '@'
    // no white space in the email
    // at least one character before the '@', between the '@' and the '.'
    // and one or more characters after the '.'
    let tempEmail = (answerText === null || answerText === undefined) ? "" : answerText;

    let errorFlag = !!(tempEmail.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/) || tempEmail.length === 0);

    let errorMessage = !errorFlag ? "Invalid email entry" : "";

    return {flag: !errorFlag, message: errorMessage};
}


function emailTextEntry(savedObjs)  {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs);

    textPropsObj.multiline = tag.metadataExists(savedObjs.question, tag.TEXT_MULTILINE);
    textPropsObj.maxrows = tag.metadataValue(savedObjs.question, tag.TEXT_MAXROW, "", "1");

    textPropsObj.errorFunction = validateEmail;

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, "");

    return (
        <FormControl key={savedObjs.question.id} style={{width: textPropsObj.width}}>
            {addLabel(savedObjs, tag.TEXT_LABEL_ABOVE)}
            <styledObj.styledTextField label={styledObj.floatingLabel} name={textPropsObj.placeholder}
                                       variant={textPropsObj.variant} size={textPropsObj.size}>
                {getTextField(self, savedObjs, "email", textPropsObj, textStyleObj, styledObj, styledObj, "")}
            </styledObj.styledTextField>
            {addLabel(savedObjs, tag.TEXT_LABEL_BELOW)}
        </FormControl>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Number Entry
// ---------------------------------------------------------------------------------------------------------------------


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    let parsedName = JSON.parse(other.name);

    if (parsedName.format === "") {
        return (<NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            type={parsedName.type}
            decimalScale={parsedName.decimalScale}
            thousandSeparator={parsedName.thousandSeparator}
            allowNegative={parsedName.allowNegative}
            prefix={parsedName.prefix}
            suffix={parsedName.suffix}
        />);
    }
    else
    {
        return (<NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            type={parsedName.type}
            format={parsedName.format}
            decimalScale={parsedName.decimalScale}
            thousandSeparator={parsedName.thousandSeparator}
            allowNegative={parsedName.allowNegative}
            prefix={parsedName.prefix}
            suffix={parsedName.suffix}
        />);
    }
}


function numericTextEntry(savedObjs, numericType, tagsArray = [], callerRefStrOverride = "", labelTagAbove = tag.TEXT_LABEL_ABOVE, labelTagBelow = tag.TEXT_LABEL_BELOW) {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs, tagsArray);

    textPropsObj.allowNegative = tag.metadataExists(savedObjs.question, tag.ALLOW_NEGATIVES);
    textPropsObj.isPercentage = tag.metadataExists(savedObjs.question, tag.IS_PERCENTAGE);
    textPropsObj.noDecimal = tag.metadataExists(savedObjs.question, tag.NO_DECIMAL);
    textPropsObj.useDecimal = tag.metadataExists(savedObjs.question, tag.ALLOW_DECIMAL);
    if (textPropsObj.useDecimal) {
        textPropsObj.decimalScale = tag.metadataValue(savedObjs.question, tag.ALLOW_DECIMAL, "", 2);
    }

    // NOTE: since name property is passed to the formatter i use it to pass properties in an object
    let name = {thousandSeparator: true, decimalScale: 0, allowNegative: textPropsObj.allowNegative, prefix: "", suffix: "", type: "text", format: "", mask: ""};

    switch(numericType) {
        case questionTypes.NUMBER:
            name.suffix = textPropsObj.isPercentage ? "%" : "";
            name.decimalScale = textPropsObj.useDecimal ? textPropsObj.decimalScale : 0;
            name.thousandSeparator = tag.metadataExists(savedObjs.question, tag.NO_THOUSAND_SEPARATOR) ? false : true;
            name.format = tag.metadataValue(savedObjs.question, tag.CUSTOM_MASK, "", "")
            break;
        case questionTypes.CURRENCY:
            name.decimalScale = textPropsObj.noDecimal ? 0 : 2;
            name.prefix = "$";
            break;
        case questionTypes.ZIPPOSTAL_ONLY:
            name.format = tag.metadataExists(savedObjs.question, tag.FIVE_DIGIT_ZIP) ? "#####" : "#####-####";
            break;
        case questionTypes.PHONE:
            name.type = "tel";
            name.format = "(###) ###-#### ####";
            break;
        // no default
    }

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styleObj = {
        fontSize: textStyleObj.fontObj.fontSize,
        fontStyle: textStyleObj.fontObj.fontStyle,
        fontWeight: textStyleObj.fontObj.fontWeight,
        fontFamily: textStyleObj.fontObj.fontFamily,
    }

    let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, callerRefStrOverride);

    let callerRefStr = callerRefStrOverride !== "" ? callerRefStrOverride : ("numericEntry-" + textPropsObj.type);

    let answerText = savedObjs.question.answerText !== null ? savedObjs.question.answerText : "";

    // If an address field then ust pull the required field out
    if ((savedObjs.question.addressAnswerDTO !== undefined)&&(savedObjs.question.addressAnswerDTO !== null))  {
        answerText = savedObjs.question.addressAnswerDTO[callerRefStrOverride.substring(8)];
    }

    return (
        <FormControl key={savedObjs.question.id} style={{width: textPropsObj.width}}>
            {addLabel(savedObjs, tag.TEXT_LABEL_ABOVE)}
            <styledObj.styledTextField


                    id={numericType + "-" + savedObjs.question.id}
                    name={JSON.stringify(name)}
                    defaultValue={answerText}
                    label={textPropsObj.floatingLabel}
                    placeholder={textPropsObj.placeholder}
                    variant={textPropsObj.variant}
                    size={textPropsObj.size}
                    onBlur={saveAnswerTypeText.bind(self, callerRefStr, savedObjs)}
                    min={"0"}
                    max={"100"}
                    disabled={savedObjs.readonlyMode}
                    //inputProps={{autoComplete: "off"}}

                    InputProps={{
                        inputComponent: NumberFormatCustom,
                        autoComplete: callerRefStrOverride === "address-zipPostalCode" ? "new-password" : "off",

                    }}
                    InputLabelProps={{style: {backgroundColor: textPropsObj.variant === "filled" ? "#E8E8E8" : "white", ...styleObj}}}

            />
            {addLabel(savedObjs, tag.TEXT_LABEL_BELOW)}
        </FormControl>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Date Entry
// ---------------------------------------------------------------------------------------------------------------------


function singleDateEntry(savedObjs, refStr, tagsArray = []) {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs, tagsArray);

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styleObj = {
        fontSize: textStyleObj.fontObj.fontSize,
        fontStyle: textStyleObj.fontObj.fontStyle,
        fontWeight: textStyleObj.fontObj.fontWeight,
        fontFamily: textStyleObj.fontObj.fontFamily,
    }

    let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, "DATE");

    // Note: Date range value are storedas a comma delimited string
    let answer = savedObjs.question.answerText;
    let dateRangeToFlag = (refStr === "dateRangeEntryTo-");
    if (refStr !== "singleDateEntry-") {
        answer = (answer) ? answer.split(",") : ["", ""];
        answer = (dateRangeToFlag) ? answer[1] : answer[0];
    }

    return (
        <FormControl key={refStr + savedObjs.question.id} style={{width: textPropsObj.width}}>
            {addLabel(savedObjs, dateRangeToFlag ? tag.TEXT_LABEL_ABOVE2 : tag.TEXT_LABEL_ABOVE)}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/*<styledObj.styledDateField label={styledObj.floatingLabel} name={textPropsObj.placeholder} variant={textPropsObj.variant} size={textPropsObj.size}>*/}

                <DatePicker id={refStr + savedObjs.question.id}
                            label={textPropsObj.floatingLabel}
                            variant={textPropsObj.variant}
                            size={textPropsObj.size}
                            defaultValue={(answer) ? answer : ""}
                            value={(answer) ? answer : ""}
                            onBlur={saveAnswerTypeText.bind(self, refStr + textPropsObj.type, savedObjs)}
                            onChange={saveAnswerTypeText.bind(self, refStr + textPropsObj.type, savedObjs)}
                            disabled={savedObjs.readonlyMode}
                    // InputLabelProps={{shrink: true, ...styleObj}}
                    // inputProps={{autoComplete: "off"}}  // eslint-disable-line
                    // InputProps={{style: {...styleObj}}} // eslint-disable-line
                    //inputprops={{max: "2999-12-31"}}
                />

                {/*</styledObj.styledDateField>*/}
            </LocalizationProvider>
            {addLabel(savedObjs, dateRangeToFlag ? tag.TEXT_LABEL_BELOW2 : tag.TEXT_LABEL_BELOW)}
        </FormControl>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Date Range Entry
// ---------------------------------------------------------------------------------------------------------------------


function dateRangeEntry(savedObjs) {

    let arrangeSideBySide = tag.metadataExists(savedObjs.question, tag.DISPLAY_DATE_RANGE_AS_SIDE_BY_SIDE);

    let tempStyle = arrangeSideBySide ? {display: 'flex', width: '100%'} : {};

    let width1 = tag.metadataValue(savedObjs.question, tag.TEXT_WIDTH, "", "100%")
    let width2 = tag.metadataValue(savedObjs.question, tag.TEXT_WIDTH2, "", "100%")

    return (
        <div style={tempStyle}>
            <div style={{float: "left", width: width1}}>
                {freeTextEntry(savedObjs, "dateRangeEntryFrom-")}
            </div>
            <div style={{float: "left", width: "20px"}}>{tag.NON_BREAKING_SPACE}</div>
            <div style={{float: "left", width: width2}}>
                {freeTextEntry(savedObjs, "dateRangeEntryTo-",  [[tag.TEXT_FLOATING_LABEL2, ""], [tag.TEXT_PLACEHOLDER2, ""], [tag.TEXT_VARIANT2, "outlined"], [tag.TEXT_SIZE2, "small"], [tag.TEXT_WIDTH2, "100%"], [tag.TEXT_MAXLENGTH2, "80"]])}
            </div>
        </div>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Address Entry
// ---------------------------------------------------------------------------------------------------------------------


export function addressAnswerEntry(savedObjs){

    let portalPreviewMode = (savedObjs.displayMode === "AdminModeUseTemplateQnr");

    let defaultFloatingLabel1 = "Street 1";
    let defaultFloatingLabel2 = "Street 2";
    let defaultFloatingLabel3 = "City";
    let defaultFloatingLabel4 = "State";
    let defaultFloatingLabel5 = "Zip Code";

    if (tag.metadataExists(savedObjs.question, tag.CUSTOM_DDO_QUE_FORMAT)) {
        let customObj = JSON.parse(tag.metadataValue(savedObjs.question, tag.CUSTOM_DDO_QUE_FORMAT));

        defaultFloatingLabel1 = customObj.floatingLabel;
        defaultFloatingLabel2 = customObj.floatingLabel2;
        defaultFloatingLabel3 = customObj.floatingLabel3;
        defaultFloatingLabel4 = customObj.floatingLabel4;
        defaultFloatingLabel5 = customObj.floatingLabel5;
    }

    let address1TagsArray = [[tag.TEXT_FLOATING_LABEL, defaultFloatingLabel1], [tag.TEXT_PLACEHOLDER, ""], [tag.TEXT_VARIANT, "outlined"], [tag.TEXT_SIZE, "small"], [tag.TEXT_WIDTH, "100%"], [tag.TEXT_MAXLENGTH, "80"]];
    let address2TagsArray = [[tag.TEXT_FLOATING_LABEL2, defaultFloatingLabel2], [tag.TEXT_PLACEHOLDER2, ""], [tag.TEXT_VARIANT2, "outlined"], [tag.TEXT_SIZE2, "small"], [tag.TEXT_WIDTH2, "100%"], [tag.TEXT_MAXLENGTH2, "80"]];
    let cityTagsArray = [[tag.TEXT_FLOATING_LABEL3, defaultFloatingLabel3], [tag.TEXT_PLACEHOLDER3, ""], [tag.TEXT_VARIANT3, "outlined"], [tag.TEXT_SIZE3, "small"], [tag.TEXT_WIDTH3, "480px"], [tag.TEXT_MAXLENGTH3, "80"]];
    let statesTagsArray = [[tag.TEXT_FLOATING_LABEL4, defaultFloatingLabel4], [tag.TEXT_PLACEHOLDER4, ""], [tag.TEXT_VARIANT4, "outlined"], [tag.TEXT_SIZE4, "small"], [tag.TEXT_WIDTH4, "200px"], [tag.TEXT_MAXLENGTH4, "80"]];
    let zipTagsArray = [[tag.TEXT_FLOATING_LABEL5, defaultFloatingLabel5], [tag.TEXT_PLACEHOLDER5, ""], [tag.TEXT_VARIANT5, "outlined"], [tag.TEXT_SIZE5, "small"], [tag.TEXT_WIDTH5, "128px"], [tag.TEXT_MAXLENGTH5, "80"]];

    return (
        <div>
            <div>
                {freeTextEntry(savedObjs, "address-address", address1TagsArray, tag.TEXT_LABEL_ABOVE, tag.TEXT_LABEL_BELOW)}
            </div>

            <div>{tag.NON_BREAKING_SPACE}</div>

            <div>
                {freeTextEntry(savedObjs, "address-extended", address2TagsArray, tag.TEXT_LABEL_ABOVE2, tag.TEXT_LABEL_BELOW2)}
            </div>

            <div>{tag.NON_BREAKING_SPACE}</div>

            <div style={{width: "100%", justifyContent: "space-between"}}>
                <div style={{float: "left"}}>
                    {freeTextEntry(savedObjs, "address-city", cityTagsArray, tag.TEXT_LABEL_ABOVE3, tag.TEXT_LABEL_BELOW3)}
                </div>

                <div style={{float: "left", width: portalPreviewMode ? "11px" : "20px"}}>{tag.NON_BREAKING_SPACE}</div>

                <div style={{float: 'left'}}>
                    {addLabel(savedObjs, tag.TEXT_LABEL_ABOVE4)}
                    {addressDropDownStates(savedObjs, statesTagsArray)}
                    <div style={{marginTop: "-20px"}}>{addLabel(savedObjs, tag.TEXT_LABEL_BELOW4)}</div>
                </div>

                <div style={{float: "left", width: portalPreviewMode ? "11px" : "20px"}}>{tag.NON_BREAKING_SPACE}</div>

                <div style={{float: "left"}}>
                    {numericTextEntry(savedObjs, questionTypes.ZIPPOSTAL_ONLY, zipTagsArray, "address-zipPostalCode", tag.TEXT_LABEL_ABOVE5, tag.TEXT_LABEL_BELOW5)}
                </div>
            </div>

        </div>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// singleSelectDropDownStates !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// ---------------------------------------------------------------------------------------------------------------------

export function addressDropDownStates(savedObjs, tagsArray) {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs, tagsArray);

    textPropsObj.errorFunction = validateEmail;

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, "");

    let question = savedObjs.question;

    let selectedFlag = (savedObjs.selectedAnswer === null) ? false : (savedObjs.selectedAnswer.id !== "");
    let inputLabelAdjPx = "0px";
    let leftAdjPx = "0px";
    let formAdjPx = "0px";
    let backgroundColor = "white";
    let backgroundColorLabel = "white";
    let smallSize = "40px";
    let largeSize = "56px";
    let paddingLeft = "0px";
    let paddingTop = "0px";
    let paddingTopSelect = "0px";
    let fontColor = styledObj.borderColor;

    if (textPropsObj.variant === "outlined") {
        formAdjPx = "0px";

        if (textPropsObj.size === "small") {
            inputLabelAdjPx = (selectedFlag) ? "0px" : "-8px";
            paddingTop = (selectedFlag) ? "0px" : "-8px";
            paddingTopSelect = (selectedFlag) ? "0px" : "-8px";
        } else {
            inputLabelAdjPx = "0px";
        }

    } else if (textPropsObj.variant === "standard") {

        if (textPropsObj.size === "small") {
            smallSize = (selectedFlag) ? "35px" : "40px";
            inputLabelAdjPx = (selectedFlag) ? "16px" : "12px";  // padding above floating text
            formAdjPx = (selectedFlag) ? "-6px" : "-11px";
            leftAdjPx = "-14px";
            paddingTopSelect = "4px";  // padding above answer
        } else {
            inputLabelAdjPx = (selectedFlag) ? "16px" : "12px";  // padding above floating text
            formAdjPx = (selectedFlag) ? "-7px" : "-8px";  // Adjust bottom line position
            leftAdjPx = "-14px";
            largeSize = "40px";
            paddingTopSelect = "6px";  // padding above answer
        }

    } else {
        // Filled entry
        backgroundColor = "#E8E8E8";
        if (selectedFlag) {
            backgroundColorLabel = "transparent";
            paddingTopSelect = "16px";
            paddingTop = "20px";
        } else {
            backgroundColorLabel = "transparent";
            paddingTopSelect = "0px";
            paddingTop = "0px";
        }
        paddingLeft = "12px";

        if (textPropsObj.size === "small") {
            inputLabelAdjPx = (selectedFlag) ? "14px" : "12px";  // position of floating label
            formAdjPx = "-16px";
            leftAdjPx = (selectedFlag) ? "-2px" : "0px";
            smallSize = "48px";
        } else {
            inputLabelAdjPx = (selectedFlag) ? "16px" : "16px"; // position of floating label
            formAdjPx = "-16px";
            leftAdjPx = (selectedFlag) ? "-2px" : "0px";
        }
    }

    savedObjs.answers = savedObjs.displayMode === mode.INSTANT_MODE ? savedObjs.question.answerOptions : savedObjs.question.answerOptionTemplates;

    let selectedId = "";
    for (let i = 0; i < savedObjs.answers.length; i++) {
        if (savedObjs.answers[i].selected) {
            selectedId = savedObjs.answers[i].id.toString();
        }
    }


    return (
        <FormControl
            variant="outlined"
            style={{width: "200px", marginTop: formAdjPx}}
            //className={savedObjs.classes.formControl}
        >
            {textPropsObj.floatingLabel === "" ? null : <InputLabel style={{color: fontColor, backgroundColor: backgroundColorLabel, paddingTop: paddingTop, marginLeft: leftAdjPx, marginTop: inputLabelAdjPx}}>{textPropsObj.floatingLabel + tag.NON_BREAKING_SPACE2}</InputLabel>}
            <styledObj.styledSelect label={styledObj.floatingLabel} name={textPropsObj.placeholder} variant={textPropsObj.variant} size={textPropsObj.size}>

                    labelId={question.id.toString()}
                    id={question.id.toString()}
                    value={selectedId}
                    onChange={saveAnswerTypeSingleSelect.bind(self, "addressStateDDLB", savedObjs)}
                    disabled={savedObjs.readonlyMode}
                    label={textPropsObj.floatingLabel}
                    style={{
                        height: textPropsObj.size === "small" ? smallSize : largeSize,
                        backgroundColor: backgroundColor,
                        paddingLeft: paddingLeft,
                        paddingTop: paddingTopSelect
                    }}
                    input={textPropsObj.variant === "outlined" ?
                        <OutlinedInput
                            labelWidth={getLabelWidth(textPropsObj.floatingLabel)}
                            id={"addressStates-" + question.id.toString()}
                        />
                        :
                        null
                    }
                >
                    {savedObjs.answers.map((answerOption) => {
                        return (
                            <MenuItem
                                value={answerOption.id.toString()}
                                key={answerOption.id.toString()}>
                                {answerOption.optionText}
                            </MenuItem>);
                    })}

            </styledObj.styledSelect>
        </FormControl>
    );

}


// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// Text Entry Utilities
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------


function addLabel(savedObjs, position) {

    let question = savedObjs.question;

    if (savedObjs.questionNameHidden) {
        return <div></div>;
    }

    if (tag.metadataExists(question, position)) {

        let value = tag.metadataValue(question, position);

        if (tag.metadataExists(question, tag.CUSTOM_DDO_LABEL)) {

            let customObj = JSON.parse(tag.metadataValue(question, tag.CUSTOM_DDO_LABEL));

            let CustomTypography = styled(Typography)({
                fontFamily: customObj.fontFamily,
                fontWeight: customObj.fontWeight,
                color: customObj.color,
                fontSize: customObj.fontSize,  // 2em, 18pt
                fontStyle: customObj.fontStyle,
                textAlign: customObj.textAlign,  // left center right
                opacity: customObj.opacity
            });

            return <CustomTypography>{value}</CustomTypography>;
        }
        else {

            let valueArray = value.split("|");  //  text | style | wieght | color | size

            let label = valueArray.length > 0 ? valueArray[0] : "";
            let fontStyle = valueArray.length > 1 ? valueArray[1] : "normal"; // normal or italic, default: normal
            let fontWeight = valueArray.length > 2 ? valueArray[2] : "normal";  // normal or bold,  default: normal
            let color = valueArray.length > 3 ? valueArray[3] : "black"; // any color, default: black
            let fontSize = valueArray.length > 4 ? valueArray[4] : "10px";  // any ##px, default: 10px
            let lineHeight = '20px';

            return <div style={{fontSize: fontSize, fontWeight: fontWeight, lineHeight: lineHeight, color: color, fontStyle: fontStyle}}>{label}</div>;
        }

    }

    return null;
}


function getTextProperties(savedObjs, tagsArray = [], defaultWidth = "100%", defaultMaxlength = "80") {

    let textDefObj = {};

    let tempTagsArray = (tagsArray.length > 0) ? tagsArray : [[tag.TEXT_FLOATING_LABEL, ""], [tag.TEXT_PLACEHOLDER, ""], [tag.TEXT_VARIANT, "outlined"], [tag.TEXT_SIZE, "small"], [tag.TEXT_WIDTH, defaultWidth], [tag.TEXT_MAXLENGTH, defaultMaxlength]];

    let question = savedObjs.question;

    textDefObj.type = savedObjs.inputType;
    textDefObj.floatingLabel = tag.metadataValue(question, tempTagsArray[0][0], "", tempTagsArray[0][1]);
    textDefObj.placeholder = tag.metadataValue(question, tempTagsArray[1][0], "", tempTagsArray[1][1]);
    textDefObj.variant = tag.metadataValue(question, tempTagsArray[2][0], "", tempTagsArray[2][1]);
    textDefObj.size = tag.metadataValue(question, tempTagsArray[3][0], "", tempTagsArray[3][1]);
    textDefObj.width = tag.metadataValue(question, tempTagsArray[4][0], "", tempTagsArray[4][1]);
    textDefObj.maxLength = tag.metadataValue(question, tempTagsArray[5][0], "", tempTagsArray[5][1]);
    textDefObj.multiline= false;
    textDefObj.rowsMax = 1;
    textDefObj.isPercentage = false;
    textDefObj.allowNegative = false;
    textDefObj.useDecimal = 2;
    textDefObj.thousandSeparator = true;
    textDefObj.zipFormat = "#####-####";
    textDefObj.customFormat = "";

    textDefObj.errorFunction = function (answerText) {return {flag: false, message: ""};};

    return textDefObj;
}



function colourNameToHex(colour)
{
    var colours = {"aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
        "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
        "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
        "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
        "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
        "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
        "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
        "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
        "honeydew":"#f0fff0","hotpink":"#ff69b4",
        "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
        "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
        "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
        "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
        "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
        "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
        "navajowhite":"#ffdead","navy":"#000080",
        "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
        "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
        "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
        "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
        "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
        "violet":"#ee82ee",
        "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
        "yellow":"#ffff00","yellowgreen":"#9acd32"};

    if (typeof colours[colour.toLowerCase()] != 'undefined')
        return colours[colour.toLowerCase()];

    return colour;
}


export function getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, callerRefStrOverride) {

    let defaultGray = "#808080";

    // Set styledObj defaults
    let styledObj = {
        styledTextField: null,
        styledDateField: null,
        styledSelect: null,

        floatingLabel: ((textPropsObj.floatingLabel === undefined) ? "" : (textPropsObj.floatingLabel.length > 0) ? (textPropsObj.floatingLabel  + tag.NON_BREAKING_SPACE) : ""),
        floatingLabelColor: tag.DEFAULT_TEXT_FIELD_LABEL_COLOR,
        floatingLabelColorHovered: tag.DEFAULT_TEXT_FIELD_LABEL_COLOR_HOVERED,
        floatingLabelColorFocused: tag.DEFAULT_TEXT_FIELD_LABEL_COLOR_FOCUSED,

        placeholderColor: tag.DEFAULT_TEXT_FIELD_PLACEHOLDER_COLOR, // NOT sure this can be used, seems it's always text color at 50% opacity

        textColor: textStyleObj.type === "default" ? tag.DEFAULT_TEXT_FIELD_TEXT_COLOR : textStyleObj.fontObj.color,
        textColorHovered: textStyleObj.type === "default" ? tag.DEFAULT_TEXT_FIELD_TEXT_COLOR_HOVERED : textStyleObj.fontObj.color,
        textColorFocused: textStyleObj.type === "default" ? tag.DEFAULT_TEXT_FIELD_TEXT_COLOR_FOCUSED : textStyleObj.fontObj.color,

        colorTextAnsweredBorderColor: textStyleObj.type === "default" ? defaultGray : textStyleObj.fontObj.color,

        backgroundColor: tag.DEFAULT_TEXT_FIELD_BACKGROUND_COLOR,
        backgroundColorHovered: tag.DEFAULT_TEXT_FIELD_BACKGROUND_COLOR_HOVERED,
        backgroundColorFocused: tag.DEFAULT_TEXT_FIELD_BACKGROUND_COLOR_FOCUSED,

        backgroundFilledColor: tag.DEFAULT_TEXT_FIELD_FILLED_BACKGROUND_COLOR,
        backgroundFilledColorHovered: tag.DEFAULT_TEXT_FIELD_FILLED_BACKGROUND_COLOR_HOVERED,
        backgroundFilledColorFocused: tag.DEFAULT_TEXT_FIELD_FILLED_BACKGROUND_COLOR_FOCUSED,

        borderColor: tag.DEFAULT_TEXT_FIELD_BORDER_COLOR,
        borderColorHovered: tag.DEFAULT_TEXT_FIELD_TEXT_BORDER_COLOR_HOVERED,
        borderColorFocused: tag.DEFAULT_TEXT_FIELD_TEXT_BORDER_COLOR_FOCUSED,
    }

    // Get any styledObj metadata overrides
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_FLOATING_LABEL_COLOR)) {
        styledObj.floatingLabelColor = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_FLOATING_LABEL_COLOR, "", styledObj.floatingLabelColor));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_FLOATING_LABEL_COLOR_HOVERED)) {
        styledObj.floatingLabelColorHovered = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_FLOATING_LABEL_COLOR_HOVERED, "", styledObj.floatingLabelColorHovered));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_FLOATING_LABEL_COLOR_FOCUSED)) {
        styledObj.floatingLabelColorFocused = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_FLOATING_LABEL_COLOR_FOCUSED, "", styledObj.floatingLabelColorFocused));  // Some of the theme props wont take a color string
    }

    // Placeholder always seems to be the input base color at 50% opacity
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_PLACEHOLDER_COLOR)) {
        styledObj.placeholderColor = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_PLACEHOLDER_COLOR, "", styledObj.placeholderColor));  // Some of the theme props wont take a color string
    }

    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_TEXT_COLOR)) {
        styledObj.textColor = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_TEXT_COLOR, "", styledObj.textColor));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_TEXT_COLOR_HOVERED)) {
        styledObj.textColorHovered = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_TEXT_COLOR_HOVERED, "", styledObj.textColorHovered));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_TEXT_COLOR_FOCUSED)) {
        styledObj.textColorFocused = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_TEXT_COLOR_FOCUSED, "", styledObj.textColorFocused));  // Some of the theme props wont take a color string
    }


    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_BACKGROUND_COLOR)) {
        styledObj.backgroundColor = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_BACKGROUND_COLOR, "",  styledObj.backgroundColor));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_BACKGROUND_COLOR_HOVERED)) {
        styledObj.backgroundColorHovered = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_BACKGROUND_COLOR_HOVERED, "",  styledObj.backgroundColorHovered));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_BACKGROUND_COLOR_FOCUSED)) {
        styledObj.backgroundColorFocused = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_BACKGROUND_COLOR_FOCUSED,  "", styledObj.backgroundColorFocused));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_FILLED_BACKGROUND_COLOR)) {
        styledObj.backgroundFilledColor = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_FILLED_BACKGROUND_COLOR, "",  styledObj.backgroundFilledColor));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_HOVERED)) {
        styledObj.backgroundFilledColorHovered = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_HOVERED, "",  styledObj.backgroundFilledColorHovered));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_FOCUSED)) {
        styledObj.backgroundFilledColorFocused = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_FOCUSED,  "", styledObj.backgroundFilledColorFocused));  // Some of the theme props wont take a color string
    }

    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_BORDER_COLOR)) {
        styledObj.borderColor = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_BORDER_COLOR, "",  styledObj.borderColor));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_BORDER_COLOR_HOVERED)) {
        styledObj.borderColorHovered = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_BORDER_COLOR_HOVERED, "",  styledObj.borderColorHovered));  // Some of the theme props wont take a color string
    }
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_BORDER_COLOR_FOCUSED)) {
        styledObj.borderColorFocused = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_BORDER_COLOR_FOCUSED,  "", styledObj.borderColorFocused));  // Some of the theme props wont take a color string
    }


    // NOT sure this is a thing?????
    if (tag.metadataExists(savedObjs.question, tag.DISPLAY_DEF_COLOR_ANSWERED_BORDER)) {
        styledObj.colorTextAnsweredBorderColor = colourNameToHex(tag.metadataValue(savedObjs.question, tag.DISPLAY_DEF_COLOR_ANSWERED_BORDER, "", "gray"));  // Some of the theme props wont take a color string
    }


    // let callerRefStr = (callerRefStrOverride !== "") ? callerRefStrOverride : ("textEntry-" + textPropsObj.type)

    // let answerText = savedObjs.question.answerText !== null ? savedObjs.question.answerText : "";
    //
    // // If an address field then just pull the required field out
    // if ((savedObjs.question.addressAnswerDTO !== undefined)&&(savedObjs.question.addressAnswerDTO !== null)) {
    //     answerText = savedObjs.question.addressAnswerDTO[callerRefStrOverride.substring(8)];
    // }

    let tempStyledObj = {
        // '& .MuiInputBase-root': {
        //     color: "red" // this works for filled MUI 5
        // },
        // '& .MuiFormLabel-root.Mui-disabled': {
        //     color: styledObj.floatingLabelColor // MUI 5 floating label color all variants NOT focused or hovered
        // },
        '&& .MuiInputLabel-root::before': {
            color: "red"
        },
        '& .MuiInputLabel-root': {
            color: styledObj.floatingLabelColor // MUI 5 floating label color all variants NOT focused or hovered
        },
        '&:hover label': {
            color: styledObj.floatingLabelColorHovered // MUI 5 floating label color all variants hovered
        },
        '& label.Mui-focused': {
            color: styledObj.floatingLabelColorFocused // MUI 5 floating label color all variants focused
        },


        // '& .MuiInputLabel-root': {
        //     color: "green" //styledObj.placeholderColor // MUI 5 placeholder color all variants
        // },
        // '&& .MuiInput-root': {
        //     color: "purple" //styledObj.textColor // MUI 5 standard underline NOT hovered or focused color
        // },
        // '&& .MuiFilledInput-root': {
        //     color: "yellow" //styledObj.textColor // MUI 5 filled underline NOT hovered or focused color
        // },
        // !!!!!!!!!!!!!!!!!!!! TEXT FIELD BORDER COLORS !!!!!!!!!!!!!!!!!!!!!!!!!
        // '&& .MuiInput-root::before': {
        //     borderColor: styledObj.borderColor, // MUI 5 standard underline NOT hovered or focused color
        // },
        // '&& .MuiInput-root:hover::before': {
        //     borderColor: styledObj.borderColorHovered // MUI 5 standard underline hover color
        // },
        // '&& .MuiInput-root::after': {
        //     borderColor: styledObj.borderColorFocused // MUI 5 standard underline focused color
        // },

        // "& .MuiInputBase-root.MuiOutlinedInput-root::placeholder": {
        //     color: "blue"
        // },
        // "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
        //     color: "blue",
        //     opacity: 1
        // },

        "& .MuiInput-root": {
            color: styledObj.textColor,
            backgroundColor: styledObj.backgroundColor,
            "&:hover": {
                color: styledObj.textColorHovered,
                backgroundColor: styledObj.backgroundColorHovered,
            },
            "&.Mui-focused": {
                color: styledObj.textColorFocused,
                backgroundColor: styledObj.backgroundColorFocused,
            },

        },

        "& .MuiFilledInput-root": {
            color: styledObj.textColor,
            backgroundColor: styledObj.backgroundFilledColor,
            borderRadius: 4,
            //border: "1px solid",
            //borderColor: "orange",
            "&:hover": {
                color: styledObj.textColorHovered,
                backgroundColor:styledObj.backgroundFilledColorHovered,
            },
            "&.Mui-focused": {
                color: styledObj.textColorFocused,
                backgroundColor: styledObj.backgroundFilledColorFocused,
            }
        },

        "& .MuiOutlinedInput-root": {
            color: styledObj.textColor,
            backgroundColor: styledObj.backgroundColor,
            borderRadius: 4,
            //border: "1px solid",
            //borderColor: "orange",
            "&:hover": {
                color: styledObj.textColorHovered,
                backgroundColor: styledObj.backgroundColorHovered,
            },
            "&.Mui-focused": {
                color: styledObj.textColorFocused,
                backgroundColor: styledObj.backgroundColorFocused,
            }
        },

        '&& .MuiFilledInput-root::before': {
            borderColor: styledObj.borderColor // MUI 5 filled underline NOT hovered or focused color
        },
        '&& .MuiFilledInput-root:hover::before': {
            borderColor: styledObj.borderColorHovered // MUI 5 filled underline hover color
        },
        '&& .MuiFilledInput-root::after': {
            borderColor: styledObj.borderColorFocused // MUI 5 filled underline focused color
        },

        // '& .MuiOutlinedInput-root': {
        //     '& fieldset': {
        //         borderColor: styledObj.borderColor // MUI 5 outlined border color NOT focused or hovered
        //     },
        //     '&:hover fieldset': {
        //         borderColor: styledObj.borderColorHovered // MUI 5 outlined border color hovered
        //     },
        //     '&.Mui-focused fieldset': {
        //         borderColor: styledObj.borderColorFocused // MUI 5 outlined border color focused
        //     },
        // },
    };

    styledObj.styledTextField = styled(TextField)(tempStyledObj);

    //styledObj.styledDateField = styled(DateField)(tempStyledObj);

    styledObj.styledSelect = styled(Select)(tempStyledObj);

    return styledObj;
}


