import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import InfoIcon from "@mui/icons-material/Info";
import CommentIcon from '@mui/icons-material/Comment';
import ArgoReviewTemplate from "../../common/ArgoReviewTemplate";
import {getCategoryTemplate} from "../../../actions/categoryTemplateActions";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import {expanderClear} from "../../../actions/expanderActions";
import Typography from "@mui/material/Typography";
import GroupViewerExpandable from "../groups/GroupViewerExpandable";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import * as portal from "../../../utilities/portal";
import statusFormatter from "../../../utilities/StatusFormatter";
import * as metadataUtils from "../../../utilities/metadata";
import * as TEMPLATE from "../../../constants/templateConstants";


class CategoryReview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expandAll: true,
            openGroupReview: false,
            selectedGroupInfoKey: "",
            openQuestionReview: false,
            selectedQuestionInfoKey: "",
            groupsLoaded: false,
            showCategoryBackButton: true,
            showGroupBackButton: true,
            initialScroll: false
        };
    }

    componentDidMount() {

        if (this.props.templateKey || this.props.templateId) {
            this.props.getCategoryTemplate(this.props.templateKey, this.props.templateId);
        }
    }

    componentWillUnmount() {
        this.props.expanderClear("CategoryReview");
    }

    componentWillReceiveProps(nextProps) {

        if (!this.state.initialScroll) {
            let page = document.getElementById(this.props.scrollTo);
            if (page) {
                page.scrollIntoView(false);
                this.setState({initialScroll: true});
                return;
            }
        }

        // let tempExpanderObj = {};
        // for (let i=0; i<nextProps.groups.length; i++) {
        //     tempExpanderObj[nextProps.groups[i]] = {open: false, viewerExpandableObj: {}};
        // }

        // IS THIS STILL NEEDED ZZZZZ
        // if ((nextProps.templateKey && nextProps.templateKey !== this.props.templateKey)
        //     || (nextProps.templateId && nextProps.templateId !== this.props.templateId)) {
        //     nextProps.getCategoryTemplate(nextProps.templateKey, nextProps.templateId);
        // }

    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        if(buttonTitle === 'BACK') this.setState({openGroupReview: false, openQuestionReview: false, showCategoryBackButton: true})
    }

    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }

    render() {
        this.props.setStateCallBack({showCategoryBackButton: this.state.showCategoryBackButton})
        const template = this.props.template;
        const metadata = template?.metadata;

        if (this.state.openGroupReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.GROUP}
                templateKey={this.state.selectedGroupInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        if (this.state.openQuestionReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.QUESTION}
                templateKey={this.state.selectedQuestionInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        let categoryScript = "";
        if (metadata) {
            categoryScript = metadata.hasOwnProperty(portal.CAT_SCRIPT) ? metadata[portal.CAT_SCRIPT] : "";
        }

        return (
            <div id="CategoryReview-container-div" >
                {template ?
                    <div id="CategoryReview-inner-container-div" style={{flexGrow: "2", padding: "32px"}}>
                        <div style={{minHeight: "min-content"}}>

                            <Typography variant="h6" component="div">
                                Category Information:
                            </Typography>

                            <div>&nbsp;</div>

                            <div id="CategoryReview-type-container-div" style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                overflowWrap: "break-word"
                            }}>
                                <div style={{color: "darkgray"}}>Status:</div>
                                <div>&nbsp;</div>
                                <div>{statusFormatter(this.props.template, 0, 0, true)}</div>
                            </div>

                            <div>&nbsp;</div>

                            <div id="CategoryReview-portal-page-break-container-div" style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                overflowWrap: "break-word"
                            }}>
                                <div style={{color: "darkgray"}}>Portal Page Break:</div>
                                <div>&nbsp;</div>
                                <div>{metadata[portal.CUSTOM_DDO_CAT_FORMAT] ? (JSON.parse(metadata[portal.CUSTOM_DDO_CAT_FORMAT]).portalPageBreak ? 'Yes' : 'No') : 'No'}</div>
                            </div>

                            <div>&nbsp;</div>

                            <div id="CategoryReview-title-bar-container-div" style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                overflowWrap: "break-word"
                            }}>
                                <div style={{color: "darkgray"}}>Portal Title Bar:</div>
                                <div>&nbsp;</div>
                                <div>{metadata[portal.CUSTOM_DDO_CAT_FORMAT] ? (JSON.parse(metadata[portal.CUSTOM_DDO_CAT_FORMAT]).titlebar ? 'Yes' : 'No') : 'No'}</div>
                            </div>

                            <div>&nbsp;</div>

                            <div id="CategoryReview-name-container-div" style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                overflowWrap: "break-word"
                            }}>
                                <div style={{color: "darkgray"}}>Category:</div>
                                <div>&nbsp;</div>
                                <div>{template.name}</div>
                            </div>

                            <div>&nbsp;</div>

                            <div id="CategoryReview-qualifier-container-div" style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                overflowWrap: "break-word"
                            }}>
                                <div style={{color: "darkgray"}}>Qualifier:</div>
                                <div>&nbsp;</div>
                                <div>{template.nameQualifier}</div>
                            </div>

                            <div>&nbsp;</div>

                            <div id="CategoryReview-script-container-div" style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                overflowWrap: "break-word"
                            }}>
                                <div style={{color: "darkgray"}}>Script:</div>
                                <div>&nbsp;</div>
                                <div>
                                    {categoryScript && <div style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        overflowWrap: "break-word"
                                    }}>
                                        <CommentIcon style={{minWidth: "36px"}}/>
                                        <div>&nbsp;</div>
                                        <div>{metadata.hasOwnProperty(portal.CAT_SCRIPT) ? metadata[portal.CAT_SCRIPT] : ""}</div>
                                    </div>}
                                </div>
                            </div>

                            <div>&nbsp;</div>

                            <div id="CategoryReview-description-container-div" style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                overflowWrap: "break-word"
                            }}>
                                <div style={{color: "darkgray"}}>Description:</div>
                                <div>&nbsp;</div>
                                <div>
                                    {template.description && <div style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        overflowWrap: "break-word"
                                    }}>
                                        <InfoIcon style={{minWidth: "36px"}}/>
                                        <div>&nbsp;</div>
                                        <div>{template.description}</div>
                                    </div>}
                                </div>
                            </div>

                            <div>&nbsp;</div>

                            <div>
                                {metadataUtils.listMetadata(template, "Category")}
                            </div>
                        </div>

                        {/*// ===========================================================================================================*/}
                        {/*// ASSIGNED GROUPS LIST */}
                        {/*// ===========================================================================================================*/}

                        <GroupViewerExpandable
                            parentName="CategoryReview"
                            categoryTemplate={this.props.template}
                            groupKeys={this.props.template.groupKeys}
                            showIconButtonReorder={false}
                            showIconButtonEdit={false}
                            showIconButtonRemove={false}
                            setStateCallback={this.setStateCallback}
                            hidePortalPreview={true}
                        />

                        {/*// ===========================================================================================================*/}
                        {/*// CATEGORY PORTAL PREVIEW */}
                        {/*// ===========================================================================================================*/}

                        <div id="CategoryReview-space-above-portal-preview" style={{height: "20px"}}>&nbsp;</div>

                        <ArgoPortalPreview
                            calledBy="CategoryReview"
                            templateType={TEMPLATE.TYPE.CATEGORY}
                            template={this.props.template}
                        />

                    </div>
                    : ""
                }
            </div>
        );
    }
}

CategoryReview.defaultProps = {
    setStateCallBack: function () {},
    scrollTo: "",
    width: "100%"
};

CategoryReview.propTypes = {
    templateKey: PropTypes.string,
    groupTemplate: PropTypes.object,
    getCategoryTemplate: PropTypes.func,
    templateId: PropTypes.string,
    setStateCallBack: PropTypes.func,
    scrollTo: PropTypes.string,
    width: PropTypes.string
};

function mapStateToProps(state, props) {
    return {
        template: state.categoryTemplate[props.templateKey],
        groupTemplates: state.groupTemplate,
        groupKeys: state.categoryTemplate[props.templateKey]?.groupKeys || [],
        questionTemplates: state.questionTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getCategoryTemplate, getQuestionTemplate, expanderClear}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryReview);

