import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import * as Colors from "@mui/material/colors";
import ChatBubble from "@mui/icons-material/ChatBubble";
import {closeDrawer} from "../../actions/drawerActions";
import {getQuestionnaireNotes} from "../../actions/questionnaireTemplateActions";
import {toLocale} from "../../utilities/dateFormats";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class QuestionnaireNoteHistoryDrawer extends Component {
    componentWillMount() {
        this.props.getQuestionnaireNotes(this.props.templateId);
    }

    render() {
        return (
            <ClickAwayListener onClickAway={this.props.closeDrawer}>
                <div style={{height: "100%"}}>
                    <ArgoAppBar
                        title={"Note History:"}
                        titleFor={this.props.template.name}
                        showMenuArrowButton="LEFT"
                        onLeftIconButtonTouchTap={this.props.closeDrawer}
                        isDrawer={true}
                        width={questionnaireNoteHistoryDrawerProps.width}
                    />
                    <div style={{height: '64px'}}></div>
                    <div style={{height: "100%"}}>
                        {this.props.notes.map((note, index) => {
                            return (
                                <Card key={index} style={{margin: "16px"}}>
                                    <CardContent>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            height: "24px",
                                            paddingBottom: "16px"
                                        }}>
                                            <ChatBubble color={Colors.grey[500]}/><span>{note.actionLabel}</span>
                                        </div>
                                        <div style={{paddingBottom: "16px"}}>
                                            {"Updated by " + note.user + " on " + toLocale(note.date)}
                                        </div>
                                        {note.text}
                                    </CardContent>
                                </Card>);
                        })}
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

QuestionnaireNoteHistoryDrawer.propTypes = {
    closeDrawer: PropTypes.func,
    getQuestionnaireNotes: PropTypes.func,
    templateId: PropTypes.number,
    notes: PropTypes.array,
    template: PropTypes.object
};

QuestionnaireNoteHistoryDrawer.defaultProps = {
    close: () => {}
};

function mapStateToProps(state) {
    return {
        notes: state.notes,
        template: state.openCloseSecondaryDrawer.template
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer, getQuestionnaireNotes}, dispatch);
}

const questionnaireNoteHistoryDrawerProps = {
    opensecondary: true,
    width: "40%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireNoteHistoryDrawer);
export {questionnaireNoteHistoryDrawerProps};
