import React, { useState, useEffect } from 'react';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";


export default function ArgoIconButtonToggleViewFieldTextHook(props) {
    const [toggle, setToggle] = useState(false);

    const fileName = props.fileName === undefined ? "file-name" : props.fileName;
    const fieldName = props.fieldName === undefined ? "field-name" : props.fieldName;

    const uniqueIdentifier = fileName + "-text-field-" + fieldName;

    const marginTop = props.marginTop === undefined ? "0px" : props.marginTop;

    const onClick = props.onClick === undefined ? function () {} : props.onClick;


    const handleOnClick = (event) => {
        setToggle(!toggle);
        onClick(props.fieldName, !toggle)
    };

    return (
        <IconButton id={uniqueIdentifier}
                    name={uniqueIdentifier}
                    onClick={handleOnClick}
                    style={{marginTop: marginTop}}>
            {toggle ? <VisibilityIcon/> : <VisibilityOffIcon/>}
        </IconButton>
    );
}
