import * as types from "../constants/actionConstants";
import TemplateApi from "../api/TemplateApi";

const initializeEditorCreate = () => {
    return {
        type: types.ACTION_EDITOR_INITIALIZE,
        payload: {
            template: {
                templateKey: "",
                name: "",
                nameQualifier: null,
                description: "",
                type: "",
                metadata: {}
            },
            options: {
                hasDescription: false,
                hasScript: false,
                createMode: true,
                validate: [],
                isValidating: false
            }
        }
    };
};

const initializeEditorUpdate = (template) => {
    return {
        type: types.ACTION_EDITOR_INITIALIZE,
        payload: {
            template: template,
            options: {
                hasScript: !!template.script,
                hasDescription: !!template.description,
                createMode: false,
                validate: [],
                isValidating: false
            }
        }
    };
};

const updateTemplate = (template, options) => {
    return {
        type: types.ACTION_EDITOR_UPDATE,
        payload: {
            template,
            options
        }
    };
};

const clearEditor = () => {
    return {
        type: types.ACTION_EDITOR_CLEAR
    };
};

const checkValue = (filter) => {
    return (dispatch) => {
        dispatch({type: types.ACTION_EDITOR_VALUE_VALIDATING});
        dispatch(checkQuestionnaireActionsExists(filter))
    };
};

const checkQuestionnaireActionsExists = (filter) => {
    let thunk = (dispatch) => {
        TemplateApi.checkQuestionnaireActionsExists(filter).then((data) => {
            dispatch({type: types.ACTION_EDITOR_VALUE_EXISTS, list: data.collection});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__actionExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};

export {
    initializeEditorCreate,
    initializeEditorUpdate,
    updateTemplate,
    clearEditor,
    checkValue
};
