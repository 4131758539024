import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";


class ArgoNavigate extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div>
            <Navigate to={this.props.to} replace={true}/>
        </div>);
    }
}


ArgoNavigate.propTypes = {
    to: PropTypes.string,
};

export default (ArgoNavigate);
