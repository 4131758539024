import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Typography from "@mui/material/Typography";
import {getCategoryTemplate} from "../../../actions/categoryTemplateActions";
import {
    initializeCategoryEditorCreate,
    initializeCategoryEditorUpdate,
    updateCategoryTemplate,
    clearCategoryEditor,
    validateCategoryNameAndQualifierUnique,
    addCategoryGroup,
    removeCategoryGroup,
} from "../../../actions/categoryEditorActions";
import {
    getGroupTemplate,
    createGroupTemplate,
    saveGroupTemplate
} from "../../../actions/groupTemplateActions";
import {saveQuestionTemplate} from "../../../actions/questionTemplateActions";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import {expanderClear} from "../../../actions/expanderActions";
import GroupEditor from "../groups/GroupEditor";
import GroupViewerExpandable from "../groups/GroupViewerExpandable";
import ArgoMetadataEditor from "../../common/ArgoMetadataEditor";
import ArgoColorInput from "../../common/ArgoColorInput";
import ArgoSelectExisting from "../../common/ArgoSelectExisting";
import ArgoTextDisplayDefination from "../../common/ArgoTextDisplayDefination";
import ArgoTextDisplayCustom from "../../common/ArgoTextDisplayCustom";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoCheckboxHook from "../../common/ArgoCheckboxHook";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoRadioGroupHook from "../../common/ArgoRadioGroupHook";
import ArgoTextEntryWithCustomOverride from "../../common/ArgoTextEntryWithCustomOverride";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import ArgoReviewTemplate from "../../common/ArgoReviewTemplate";
import QuestionEditor from "../questions/QuestionEditor";
import * as validate from "../../../utilities/validate";
import * as metadataUtils from "../../../utilities/metadata";
import * as portal from "../../../utilities/portal";
import * as metadata from "../../../utilities/metadata";
import * as metadataTemplateTypes from "../../../constants/metadataTemplateTypes";
import * as TEMPLATE from "../../../constants/templateConstants";


class CategoryEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showGroupBackButton: true,
            groupTemplateSearch: "",
            openGroupReview: false,
            selectedGroupInfoKey: "",
            openQuestionReview: false,
            selectedQuestionInfoKey: "",
            isThisAnExistingGroup: "0",
            nameErrorText: " ",
            metadataErrorText: "",
            isLoading: false,
            groupList: [],
            editGroupKey: null,
            nameDisplayDefination: "default",
            nameDisplayMetadataValue: "",
            nameDisplayMetadataValueObj: null,
            descDisplayDefination: "default",
            descDisplayMetadataValue: "",
            descDisplayMetadataValueObj: null,
            scriptDisplayDefination: "default",
            scriptDisplayMetadataValue: "",
            scriptDisplayMetadataValueObj: null,
            formatObj: {
                portalPageBreak: false,
                titlebar: false,
                colorType: "default",
                colorIndex: null,
                colorEntry: "black",
                color: "#01579b",
                opacityEntry: "100",
                opacity: "100%",
            },
            leftDisplayDefination: "default",
            leftDisplayMetadataValue: "",
            leftDisplayMetadataValueObj: null,
            centerDisplayDefination: "default",
            centerDisplayMetadataValue: "",
            centerDisplayMetadataValueObj: null,
            rightDisplayDefination: "default",
            rightDisplayMetadataValue: "",
            rightDisplayMetadataValueObj: null,
            toolbarErrorText: "",
            allAccordionsExpanded: false,
            groupsLoaded: false,
            openGroupEditor: false,
            groupTemplate: {},
            openQuestionEditor: false,
            questionTemplate: {},
            metadataSet: false,
            initialScroll: false
        };
    }

    componentDidMount() {

        if (this.props.templateKey || this.props.templateId) {
            if (this.props.categoryTemplate[this.props.templateKey] && (!this.props.templateId ||
                (this.props.categoryTemplate[this.props.templateKey].id === this.props.templateId))) {

                this.props.initializeCategoryEditorUpdate(this.props.categoryTemplate[this.props.templateKey]);
            }
            else if (this.props.templateKey) {
                this.setState({isLoading: true});
                this.props.getCategoryTemplate(this.props.templateKey, this.props.templateId);
            }

        }
        else {
            let template = {
                ...this.props.createInitialTemplate
            };

            this.props.initializeCategoryEditorCreate(template);
        }

        if (this.props.templateOriginal) {
            this.pageInit(this.props);
        }
    }

    componentWillUnmount() {
        this.props.expanderClear("CategoryEditor");
    }

    componentWillReceiveProps(nextProps) {

        if (!this.state.initialScroll) {
            let scrollTo = (this.props.scrollTo === "") ? "CategoryEditor-height-div" : this.props.scrollTo;
            let page = document.getElementById(scrollTo);
            if (page) {
                page.scrollIntoView(false);
                this.setState({initialScroll: true});
                return;
            }
        }

        // if templateOriginal is loaded and the templateKey matches the current templateKey and is different from the loaded template (check id here?)
        let isTemplateLoaded = (nextProps.templateOriginal && nextProps.templateOriginal.templateKey === nextProps.templateKey
            && nextProps.template.templateKey !== nextProps.templateKey);

        // The loaded template is stale and the correct template is loaded
        let editTemplateReady = nextProps.template.id !== nextProps.templateId &&
            (nextProps.categoryTemplate[nextProps.templateKey] && nextProps.categoryTemplate[nextProps.templateKey].id === nextProps.templateId);

        if(!this.state.metadataSet) {
            if (isTemplateLoaded && editTemplateReady) {
                this.setState({isLoading: false, groupList: nextProps.groupTemplateList});
                this.props.initializeCategoryEditorUpdate(nextProps.templateOriginal);

                this.pageInit(nextProps);
            }
            else {
                this.setState({groupList: nextProps.groupTemplateList});
            }
        }

        nextProps.groupKeys && nextProps.groupKeys.map((templateKey) => {
            if (!nextProps.groupTemplate[templateKey]) {
                nextProps.getGroupTemplate(templateKey);
            }
            return false;
        });

        // portal.sendCategoryToPortal(nextProps.template, "", nextProps.groupKeys, nextProps.groupTemplate, nextProps.questionTemplate, nextProps.getQuestionTemplate);

        this.checkForErrorsOnScreen(nextProps);
    }

    componentWillUnmount() {
        this.props.clearCategoryEditor();
    }

    // Allow child components to set errors on this page.
    setStateCallback = (stateObj, saveAfter = false, templateType) => {

        if ((templateType === TEMPLATE.TYPE.GROUP) && saveAfter) {
            this.setState(stateObj, () => this.groupEditorSave());
        }
        else if ((templateType === TEMPLATE.TYPE.QUESTION) && saveAfter) {
            this.setState(stateObj, () => this.questionEditorSave());
        } else {
            this.setState(stateObj, () => this.checkForErrorsOnScreen(this.props));
        }
    }

    pageInit = (currProps) => {

        let tempCustomFormatObj = null;
        let tempFormatObj = {
            portalPageBreak: false,
            titlebar: false,
            colorType: "default",
            colorIndex: null,
            colorEntry: "black",
            color: "#01579b",
            opacityEntry: "100",
            opacity: "100%"
        };

        let tempLeftDisplayDefination = "default";
        let tempLeftDisplayMetadataValue = "";
        let tempLeftDisplayMetadataValueObj = null;
        let tempCenterDisplayDefination = "default";
        let tempCenterDisplayMetadataValue = "";
        let tempCenterDisplayMetadataValueObj = null;
        let tempRightDisplayDefination = "default";
        let tempRightDisplayMetadataValue = "";
        let tempRightDisplayMetadataValueObj = null;

        let metadataObj = currProps.templateOriginal.metadata;

        if (metadataObj.hasOwnProperty(portal.CUSTOM_DDO_CAT_FORMAT )) {

            tempCustomFormatObj = JSON.parse(currProps.templateOriginal.metadata[portal.CUSTOM_DDO_CAT_FORMAT ]);
            tempFormatObj.portalPageBreak = tempCustomFormatObj.portalPageBreak;
            tempFormatObj.titlebar = tempCustomFormatObj.titlebar;
            tempFormatObj.colorType = tempCustomFormatObj.colorType;
            tempFormatObj.colorIndex = tempCustomFormatObj.colorIndex;
            tempFormatObj.colorEntry = tempCustomFormatObj.colorEntry;
            tempFormatObj.color = tempCustomFormatObj.color;
            tempFormatObj.opacityEntry = tempCustomFormatObj.opacityEntry;
            tempFormatObj.opacity = tempCustomFormatObj.opacity;
        }

        if (metadataObj.hasOwnProperty(portal.CUSTOM_DDO_CAT_TITLE_BAR_LEFT)) {
            tempLeftDisplayMetadataValue = metadataObj[portal.CUSTOM_DDO_CAT_TITLE_BAR_LEFT];
            tempLeftDisplayMetadataValueObj = JSON.parse(tempLeftDisplayMetadataValue);
            tempLeftDisplayDefination = "custom";
        }
        if (metadataObj.hasOwnProperty(portal.CUSTOM_DDO_CAT_TITLE_BAR_CENTER)) {
            tempCenterDisplayMetadataValue = metadataObj[portal.CUSTOM_DDO_CAT_TITLE_BAR_CENTER];
            tempCenterDisplayMetadataValueObj = JSON.parse(tempCenterDisplayMetadataValue);
            tempCenterDisplayDefination = "custom";
        }
        if (metadataObj.hasOwnProperty(portal.CUSTOM_DDO_CAT_TITLE_BAR_RIGHT)) {
            tempRightDisplayMetadataValue = metadataObj[portal.CUSTOM_DDO_CAT_TITLE_BAR_RIGHT];
            tempRightDisplayMetadataValueObj = JSON.parse(tempRightDisplayMetadataValue);
            tempRightDisplayDefination = "custom";
        }

        let tempNameObj = metadata.setMetadataDefinationAndValueAndValueObj(metadataObj, portal.CUSTOM_DDO_NAME, metadata.definationAndValueAndValueObj, tempFormatObj.titlebar);

        let tempScriptObj = metadata.setMetadataDefinationAndValueAndValueObj(metadataObj, portal.CUSTOM_DDO_CAT_SCRIPT, metadata.definationAndValueAndValueObj, tempFormatObj.titlebar);

        let tempDescObj = metadata.setMetadataDefinationAndValueAndValueObj(metadataObj, portal.CUSTOM_DDO_DESC, metadata.definationAndValueAndValueObj, tempFormatObj.titlebar);

        this.setState({
            formatObj: tempFormatObj,
            leftDisplayDefination : tempLeftDisplayDefination,
            leftDisplayMetadataValue: tempLeftDisplayMetadataValue,
            leftDisplayMetadataValueObj: tempLeftDisplayMetadataValueObj,
            centerDisplayDefination : tempCenterDisplayDefination,
            centerDisplayMetadataValue: tempCenterDisplayMetadataValue,
            centerDisplayMetadataValueObj: tempCenterDisplayMetadataValueObj,
            rightDisplayDefination : tempRightDisplayDefination,
            rightDisplayMetadataValue: tempRightDisplayMetadataValue,
            rightDisplayMetadataValueObj: tempRightDisplayMetadataValueObj,
            nameDisplayDefination: tempNameObj.displayDefination,
            nameDisplayMetadataValue: tempNameObj.displayMetadataValue,
            nameDisplayMetadataValueObj: tempNameObj.displayMetadataValueObj,
            scriptDisplayDefination : tempScriptObj.displayDefination,
            scriptDisplayMetadataValue: tempScriptObj.displayMetadataValue,
            scriptDisplayMetadataValueObj: tempScriptObj.displayMetadataValueObj,
            descDisplayDefination : tempDescObj.displayDefination,
            descDisplayMetadataValue: tempDescObj.displayMetadataValue,
            descDisplayMetadataValueObj: tempDescObj.displayMetadataValueObj
        });
    };

    checkForErrorsOnScreen = (props) => {
        let nameErrorText = validate.templateNameAndQualiferUnique(props, "Category Name");
        let metadataErrorText = this.state.metadataErrorText;
        let toolbarErrorText = "";

        if (nameErrorText !== " ") {
            toolbarErrorText = nameErrorText;
        }

        if (metadataErrorText !== "") {
            toolbarErrorText = toolbarErrorText === "" ? this.state.metadataErrorText : toolbarErrorText + ",  " + this.state.metadataErrorText;
        }

        this.setState({nameErrorText: nameErrorText, metadataErrorText: metadataErrorText, toolbarErrorText: toolbarErrorText});
    };

    setMetadataErrorText = (metadataErrorText) => {
        this.setState({metadataErrorText: metadataErrorText, metadataSet: false}, () => this.checkForErrorsOnScreen(this.props));
    }

    metadataChanged = (updatedTemplate, depth) => {
        // Note: Timing issues was causing componentWillReceiveProps to trigger and a setState was canceling another setState
        this.setState({metadataSet: true}, () => this.props.updateCategoryTemplate(updatedTemplate, depth));
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "CANCEL":
                this.props.cancel();
                break;
            case "SAVE":
                this.saveCategory();
                break;
            case "BACK":
                this.setState({openGroupReview: false, openQuestionReview: false});
                break;
            // no default
        }
    }

    pickGroup = (groupTemplateKey) => {
        if (!this.props.groupKeys.includes(groupTemplateKey.templateKey)) {
            this.props.addCategoryGroup(groupTemplateKey.templateKey);
        }
        this.setState({groupTemplateSearch: ""});
    };

    onUpdateGroupInput = (searchText) => {

        this.setState({groupTemplateSearch: searchText});
    };

    onCheckboxChange = (event, fieldName) => {

        let tempObj = {...this.state.formatObj};

        tempObj[fieldName] = event.target.checked;

        if ((fieldName === "titlebar") && (event.target.checked)) {
            tempObj.nameDisplayDefination = "hide";
            tempObj.descDisplayDefination = "hide";
            tempObj.scriptDisplayDefination = "hide";

            this.setState({formatObj: tempObj,
                nameDisplayDefination: "hide",
                nameDisplayMetadataValue: "",
                nameDisplayMetadataValueObj: "hide",
                descDisplayDefination: "hide",
                descDisplayMetadataValue: "",
                descDisplayMetadataValueObj: "hide",
                scriptDisplayDefination: "hide",
                scriptDisplayMetadataValue: "",
                scriptDisplayMetadataValueObj: "hide"}, () => {this.setMetadataValue()});
        }
        else if ((fieldName === "titlebar") && (!event.target.checked)) {
            tempObj.nameDisplayDefination = "default";
            tempObj.descDisplayDefination = "default";
            tempObj.scriptDisplayDefination = "default";

            this.setState({formatObj: tempObj,
                nameDisplayDefination: "default",
                nameDisplayMetadataValue: "",
                nameDisplayMetadataValueObj: null,
                descDisplayDefination: "default",
                descDisplayMetadataValue: "",
                descDisplayMetadataValueObj: null,
                scriptDisplayDefination: "default",
                scriptDisplayMetadataValue: "",
                scriptDisplayMetadataValueObj: null}, () => {this.setMetadataValue()});
        }
        else {
            this.setState({formatObj: tempObj}, () => {this.setMetadataValue()});
        }

    };

    setMetadataValue = () => {
        let updatedTemplate = {...this.props.template};
        let metadata = {
            ...updatedTemplate.metadata
        };

        if ((this.state.formatObj.portalPageBreak)|| (this.state.formatObj.titlebar)) {
            metadata[portal.CUSTOM_DDO_CAT_FORMAT] = JSON.stringify(this.state.formatObj);
        }
        else {
            delete metadata[portal.CUSTOM_DDO_CAT_FORMAT];
        }

        updatedTemplate.metadata = metadata;
        this.props.updateCategoryTemplate(updatedTemplate);
    };

    onTextChange = (event, fieldName) => {
        let value = event.target.value;

        if ((fieldName === "nameQualifier") && (value==="")) {
            value = null;
        }

        let updatedTemplate = {...this.props.template};
        updatedTemplate[fieldName] = value;

        this.props.updateCategoryTemplate(updatedTemplate);

        if (fieldName === "name" || fieldName === "nameQualifier")
        {
            this.props.validateCategoryNameAndQualifierUnique(updatedTemplate.name, updatedTemplate.nameQualifier);
        }
        else if (fieldName === portal.CAT_SCRIPT) {
            this.handleMetaChange(fieldName, event, value);
        }
    };

    handleMetaChange = (fieldName, event, value) => {
        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};
        tempMetadata[fieldName] = value;
        updatedTemplate.metadata = tempMetadata;
        this.props.updateCategoryTemplate(updatedTemplate);
    };

    handleMetadataChange = (metadata) => {
        let updatedTemplate = {...this.props.template};
        updatedTemplate.metadata = metadata;
        this.props.updateCategoryTemplate(updatedTemplate);
    };

    saveCategory = () => {

        let template = {
            ...this.props.template,
            groupKeys: [...this.props.groupKeys]
        };

        // If edit is being called from category expander, after QuestionnaireEditor state is set it calls saveCategoryTemplate
        if (this.props.categoryExpanderEdit) {
            this.props.categoryExpanderSetStateCallback({categoryTemplate: template}, true, TEMPLATE.TYPE.CATEGORY);
            return;
        }

        this.props.save(template);
    };

    onChangeIsThisAnExistingGroup = (event, fieldName, index) => {
        let value = event.target.value;
        let newState = {
            isThisAnExistingGroup: value,
            groupTemplateSearch: (value)? this.state.groupTemplateSearch: ""
        };

        this.setState(newState);
    };

    groupSummary = (templateKey) => {

        let template = this.props.groupTemplate[templateKey];
        if (!template) return;
        let questionCount = template.questionKeys.length;

        let label = template.name;
        let questionCountLabel = "";

        if (questionCount) {
            questionCountLabel = (questionCount > 1) ? "(" + questionCount + " questions)" : "(" + questionCount + " question)";
        }

        let groupList = [];
        let rowRemoveFlag = false;
        for (let i = 0; i < this.state.groupList.length; i++) {
            if (this.state.groupList[i].templateKey !== templateKey) {
                groupList.push(this.state.groupList[i]);  // CLONE this.state.questionList minus selected question
            }
            else
            {
                rowRemoveFlag = true;
            }
        }

        // Only set if there was a change to keep from getting react errors
        if (rowRemoveFlag) {
            this.setState({groupList: groupList});  // If there was a row removed then setState with new array of questions
        }

        return (
            <div id={"CategoryEditor-question-details-container-div" + this.props.index} style={{display: "flex"}}>
                <div>{label}</div>

                {template.nameQualifier !== null ? <div>
                    <div>&nbsp;&nbsp;</div>
                    <div style={{color: "darkgray", fontSize: "12px", paddingTop: "4px"}}>{"(" + this.props.groupTemplate[templateKey].nameQualifier + ")"}</div>
                </div>: ""}

                <div>&nbsp;&nbsp;</div>

                <div style={{color: "darkgray", fontSize: "12px", paddingTop: "4px"}}>{questionCountLabel}</div>
            </div>
        );
    };

    // // Allow child components to set errors on this page.
    // setStateCallback = (stateObj) => {
    //     this.setState(stateObj, this.checkForErrorsOnScreen(this.props));
    // }

    setColor = (colorObj) => {

        let tempObj = {...this.state.formatObj};

        tempObj.colorType = colorObj.colorType;
        tempObj.colorIndex = colorObj.colorIndex;
        tempObj.colorEntry = colorObj.colorEntry;
        tempObj.color = colorObj.color;
        tempObj.opacityEntry = colorObj.opacityEntry;
        tempObj.opacity = colorObj.opacity;

        this.setState({"formatObj": tempObj}, () => {this.setMetadataValue()});
    };

    onClickIconButton = (event, fieldName, index) => {

        switch(fieldName) {
            case "edit":
                let groupKey = this.props.groupKeys[index];
                let newState = {
                    isThisAnExistingGroup: "1",
                    editGroupKey: groupKey
                };

                this.setState(newState);
                break;
            case "remove":
                // Group info icon button opens group preview
                this.props.removeCategoryGroup(index);
                break;
            case "info":
                let groupTemplate = Object.values(this.props.groupTemplate)[index];
                this.setState({selectedGroupInfoKey: groupTemplate.templateKey});
                break;
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // GROUP EDITOR FUNCTIONS ------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    groupEditorCreate = (groupTemplate) => {
        if (this.state.editGroupKey) {
            // Update existing group
            this.props.saveGroupTemplate(groupTemplate, /** persistDrawer*/ true);
        } else {
            // Create new group
            this.props.createGroupTemplate(groupTemplate, true);
        }
        this.setState({isThisAnExistingGroup: "0", groupTemplateSearch: "", editGroupKey: null});
    };

    groupEditorClose = () => {
        this.setState({isThisAnExistingGroup: "0", groupTemplateSearch: "", editGroupKey: null});
    };

    groupEditorSave = () => {
        this.props.saveGroupTemplate(this.state.groupTemplate);
        this.setState({openGroupEditor: false});
    };

    groupEditorCancel = () => {
        this.setState({openGroupEditor: false});
    };

    // -----------------------------------------------------------------------------------------------------------------
    // QUESTION EDITOR FUNCTIONS ------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    questionEditorSave = () => {
        this.props.saveQuestionTemplate(this.state.questionTemplate);
        this.setState({openQuestionEditor: false});
    };

    questionEditorCancel = () => {
        this.setState({openQuestionEditor: false});
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {
        if (this.state.isLoading) {
            //TODO place a loading screen here
            return null;
        }

        if (this.state.openGroupReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.GROUP}
                templateKey={this.state.selectedGroupInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        if (this.state.openQuestionReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.QUESTION}
                templateKey={this.state.selectedQuestionInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        if (this.state.isThisAnExistingGroup === "1") {

            let template = {
                name: this.state.groupTemplateSearch
            };

            return <GroupEditor title="Create Group"
                                createMode={true}
                                save={this.groupEditorCreate}
                                cancel={this.groupEditorClose}
                                showCreateToggle={this.isEditCreateSelector}
                                createInitialTemplate={template}
                                templateKey={this.state.editGroupKey}
                                width={this.props.width}
                    />;
        }

        // Edit from group expander
        if (this.state.openGroupEditor) {

            return <GroupEditor title="Edit Group"
                                save={this.groupEditorSave}
                                cancel={this.groupEditorCancel}
                                templateKey={this.state.groupTemplate.templateKey}
                                templateId={this.state.groupTemplate.id}
                                filter={this.props.filter}
                                templateMode={TEMPLATE.MODE.CURRENT}
                                groupExpanderEdit={true}
                                groupExpanderSetStateCallback={this.setStateCallback}
                                width={this.props.width}
                                scrollTo="CategoryEditor-container-outer-div"
                   />;
        }

        // Edit from question expander
        if (this.state.openQuestionEditor) {

            return <QuestionEditor title="Edit Question"
                                   save={this.questionEditorSave}
                                   cancel={this.questionEditorCancel}
                                   templateKey={this.state.questionTemplate.templateKey}
                                   templateId={this.state.questionTemplate.id}
                                   filter={this.props.filter}
                                   templateMode={TEMPLATE.MODE.CURRENT}
                                   questionDepth={0}
                                   questionExpanderEdit={true}
                                   questionExpanderSetStateCallback={this.setStateCallback}
                                   width={this.props.width}
                                   scrollTo="CategoryEditor-container-outer-div"
            />;
        }

        const metadataObj = (this.props.template && this.props.template.metadata) ? this.props.template.metadata : {};

        let propsObj = {
            fileName: "CategoryEditor",
            onTextChange: this.onTextChange,
            templateType: TEMPLATE.TYPE.CATEGORY,
            template: this.props.template,
            metadata: metadataObj,
            templateUpdateFunction: this.props.updateCategoryTemplate,
            setStateCallbackFunction: this.setStateCallback,
            setCustomMetadataFunction: metadataUtils.setCustomMetadata,
            depth: 0,
        }

        let categoryTemplateClone = JSON.parse(JSON.stringify(this.props.template));

        categoryTemplateClone.groupKeys = this.props.groupKeys; // Required by Portal Preview for group order/remove/add updates

        return (
            this.props.template.hasOwnProperty("templateKey") ?
                <div id="CategoryEditor-container-outer-div" style={{height: "100%"}}>

                    <ArgoToolbarHook
                        fileName="CategoryEditor"
                        fieldName="save-or-cancel"
                        toolbarErrorText={this.state.toolbarErrorText}
                        onClick={this.handleToolbarButtonClick}
                        width={this.props.width}
                    />

                    <div id="CategoryEditor-height-div" style={{height: "50px"}}></div>

                    <div>&nbsp;</div>

                    <div id="CategoryEditor-page-div" style={{height: "100%", width: "100%"}}>

                        <div id="CategoryEditor-container-inner-div"
                             style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                            <div id="CategoryEditor-title-div">
                                {this.props.title === "" ? "" :
                                    <div>
                                        <div>&nbsp;</div>
                                        <Typography style={{fontWeight: "bold"}}>{this.props.title}</Typography>
                                        <div>&nbsp;</div>
                                    </div>
                                }
                            </div>

                            <div id="CategoryEditor-pagebreak-container-div"
                                 style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                                <ArgoCheckboxHook
                                    fileName="CategoryEditor"
                                    fieldName="portalPageBreak"
                                    label="Portal page break"
                                    onChange={this.onCheckboxChange}
                                    checked={this.state.formatObj.portalPageBreak}
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoCheckboxHook
                                    fileName="CategoryEditor"
                                    fieldName="titlebar"
                                    label="Portal title bar"
                                    onChange={this.onCheckboxChange}
                                    checked={this.state.formatObj.titlebar}
                                />

                                <div style={{width: "20px"}}></div>

                                {this.state.formatObj.titlebar ?
                                    <ArgoColorInput mode="title"
                                                    colorLabel={"Title Bar Color"}
                                                    fieldName={"formatObj"}
                                                    initToObj={this.state.formatObj}
                                                    opacityFlag={true}
                                                    largeHexEntry={false}
                                                    setColor={this.setColor.bind(this)}
                                                    height={'17px'}
                                    /> : ""
                                }
                            </div>

                            {this.state.formatObj.titlebar ?
                                <div id="CategoryEditor-titles-div" style={{width: '100%', backgroundColor: '#F8F8F8'}}>

                                    <div id="CategoryEditor-title-spacer-01-div" style={{height: "10px"}}/>

                                    <div style={{display: "flex", alignItems: "left", width: '100%'}}>

                                        <div style={{width: "20px"}}></div>

                                        <ArgoTextDisplayDefination
                                            fieldName="leftDisplayDefination"
                                            initTo={this.state.leftDisplayDefination}
                                            templateType={TEMPLATE.TYPE.CATEGORY}
                                            titleType="header-left"
                                            hideOption={false}
                                            labelText={"Left Title"}
                                            metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_CAT_TITLE_BAR_LEFT}, propsObj)}
                                        />

                                        {(this.state.leftDisplayDefination === 'custom') ?
                                            <ArgoTextDisplayCustom
                                                fieldName="leftDisplayDefination"
                                                initToObj={this.state.leftDisplayMetadataValueObj}
                                                showDisplay={false}
                                                showTitleEntry={true}
                                                paddingTop={"0px"}
                                                metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_CAT_TITLE_BAR_LEFT}, propsObj)}
                                            />
                                            :
                                            <div></div>
                                        }
                                    </div>

                                    <div id="CategoryEditor-title-spacer-02-div" style={{height: "10px"}}/>

                                    <div style={{display: "flex", alignItems: "left", width: '100%'}}>

                                        <div style={{width: "20px"}}></div>

                                        <ArgoTextDisplayDefination
                                            fieldName="centerDisplayDefination"
                                            initTo={this.state.centerDisplayDefination}
                                            templateType={TEMPLATE.TYPE.CATEGORY}
                                            titleType="header-center"
                                            hideOption={false}
                                            labelText={"Center Title"}
                                            metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_CAT_TITLE_BAR_CENTER}, propsObj)}
                                        />

                                        {(this.state.centerDisplayDefination === 'custom') ?
                                            <ArgoTextDisplayCustom
                                                fieldName="centerDisplayDefination"
                                                initToObj={this.state.centerDisplayMetadataValueObj}
                                                showDisplay={false}
                                                showTitleEntry={true}
                                                paddingTop={"0px"}
                                                metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_CAT_TITLE_BAR_CENTER}, propsObj)}
                                            />
                                            :
                                            <div></div>
                                        }

                                    </div>

                                    <div id="CategoryEditor-title-spacer-03-div" style={{height: "10px"}}/>

                                    <div style={{display: "flex", alignItems: "left", width: '100%'}}>

                                        <div style={{width: "20px"}}></div>

                                        <ArgoTextDisplayDefination
                                            fieldName="rightDisplayDefination"
                                            initTo={this.state.rightDisplayDefination}
                                            templateType={TEMPLATE.TYPE.CATEGORY}
                                            titleType="header-right"
                                            hideOption={false}
                                            labelText={"Right Title"}
                                            metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_CAT_TITLE_BAR_RIGHT}, propsObj)}
                                        />

                                        {(this.state.rightDisplayDefination === 'custom') ?
                                            <ArgoTextDisplayCustom
                                                fieldName="rightDisplayDefination"
                                                initToObj={this.state.rightDisplayMetadataValueObj}
                                                showDisplay={false}
                                                showTitleEntry={true}
                                                paddingTop={"0px"}
                                                metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_CAT_TITLE_BAR_RIGHT}, propsObj)}
                                            />
                                            :
                                            <div></div>
                                        }

                                    </div>

                                </div>
                                : ""}

                        </div>

                        <div id="CategoryEditor-break-between-titles-main-page">&nbsp;</div>

                        <div style={{justifyContent: "space-between"}}>

                            <div style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                                {this.props.showCreateToggle && this.props.showCreateToggle()}

                                <ArgoTextEntryWithCustomOverride
                                    fieldName="name"
                                    label="Category Name"
                                    labelCustom="Display (Name)"
                                    titleType="name"
                                    templateType={TEMPLATE.TYPE.CATEGORY}
                                    value={this.props.template.name}
                                    maxlength={255}
                                    errorText={this.state.nameErrorText}
                                    initTo={this.state.nameDisplayDefination}
                                    initToObj={this.state.nameDisplayMetadataValueObj}
                                    hideOnly={this.state.formatObj.titlebar}
                                    propsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_NAME}, propsObj)}
                                />

                                <div id="CategoryEditor-qualifier-container-div"
                                     style={{display: "flex", alignItems: "left", width: '100%'}}>

                                    <ArgoTextFieldHook
                                        fileName="CategoryEditor"
                                        fieldName="nameQualifier"
                                        label="Name Qualifier"
                                        value={this.props.template.nameQualifier}
                                        maxLength={255}
                                        multiline={true}
                                        onChange={this.onTextChange}
                                        width="96%"
                                        divContainerHeight="52px"
                                    />

                                    <div style={{width: "83px"}}>&nbsp;</div>

                                </div>

                                <div>&nbsp;</div>
                                <div>&nbsp;</div>

                                <ArgoTextEntryWithCustomOverride
                                    fieldName={portal.CAT_SCRIPT}
                                    label="Script"
                                    titleType="script"
                                    templateType={TEMPLATE.TYPE.CATEGORY}
                                    labelCustom="Display (Script)"
                                    value={metadataObj[portal.CAT_SCRIPT]}
                                    maxlength={2000}
                                    initTo={this.state.scriptDisplayDefination}
                                    initToObj={this.state.scriptDisplayMetadataValueObj}
                                    propsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_CAT_SCRIPT}, propsObj)}
                                />

                                <ArgoTextEntryWithCustomOverride
                                    fieldName="description"
                                    label="Description/Instructions"
                                    labelCustom="Display (Description)"
                                    titleType="desc"
                                    templateType={TEMPLATE.TYPE.CATEGORY}
                                    value={this.props.template.description}
                                    maxlength={255}
                                    initTo={this.state.descDisplayDefination}
                                    initToObj={this.state.descDisplayMetadataValueObj}
                                    propsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_DESC}, propsObj)}
                                />

                                {/*// ===========================================================================================================*/}
                                {/*// METADATA EDITOR */}
                                {/*// ===========================================================================================================*/}

                                <div>&nbsp;</div>

                                <div style={{width: "100%", paddingBottom: "40px"}}>
                                    <ArgoMetadataEditor
                                        entityType={metadataTemplateTypes.CategoryTemplate}
                                        template={this.props.template}
                                        // onChange={this.props.updateCategoryTemplate}
                                        onChangeCallback={this.metadataChanged}
                                        metadata={this.props.template.metadata}
                                        errorText={this.state.metadataErrorText}
                                        setMetadataErrorText={this.setMetadataErrorText}
                                    />
                                </div>

                                {/*// ===========================================================================================================*/}
                                {/*// ADD GROUP TO CATEGORY */}
                                {/*// ===========================================================================================================*/}

                                <ArgoRadioGroupHook
                                    fileName="CategoryEditor"
                                    fieldName="isThisAnExistingGroup"
                                    label="Is this an existing group?"
                                    value={this.state.isThisAnExistingGroup}
                                    onChange={this.onChangeIsThisAnExistingGroup}
                                    checked={true}
                                />

                                <ArgoSelectExisting
                                    mode={TEMPLATE.TYPE.GROUP}
                                    paddingRight="0px"
                                    onAddTemplate={this.pickGroup.bind(this)}
                                />

                                <div>&nbsp;</div>

                                {/*// ===========================================================================================================*/}
                                {/*// ASSIGNED GROUPS LIST */}
                                {/*// ===========================================================================================================*/}

                                <GroupViewerExpandable
                                    parentName="CategoryEditor"
                                    categoryTemplate={this.props.template}
                                    groupKeys={this.props.groupKeys}
                                    setStateCallback={this.setStateCallback}
                                    hidePortalPreview={true}
                                    questionTemplate={this.props.questionTemplates}
                                />

                                {/*// ===========================================================================================================*/}
                                {/*// PORTAL PREVIEW */}
                                {/*// ===========================================================================================================*/}

                                <div id="CategoryEditor-space-above-portal-preview"
                                     style={{height: "20px"}}>&nbsp;</div>

                                <ArgoPortalPreview
                                    calledBy="CategoryEditor"
                                    createMode={this.props.createMode}
                                    templateType={TEMPLATE.TYPE.CATEGORY}
                                    template={categoryTemplateClone}
                                />

                            </div>
                        </div>
                    </div>
                </div> : <div id="CategoryEditor-container-outer-div"/>
        );
    }

}

CategoryEditor.defaultProps = {
    createMode: false,
    title: "",
    scrollTo: "",
    width: "100%"
};

CategoryEditor.propTypes = {
    scrollTo: PropTypes.string,
    createMode: PropTypes.bool,
    title: PropTypes.string,
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    cancel: PropTypes.func,
    save: PropTypes.func,
    showCreateToggle: PropTypes.func,
    createInitialTemplate: PropTypes.object,
    categoryExpanderEdit: PropTypes.bool,
    categoryExpanderSetStateCallback: PropTypes.func,
    width: PropTypes.string
};

function mapStateToProps(state, props) {

    // if (state.categoryEditor.groupKeys.length > 0) {
    //     if (state.groupTemplate.hasOwnProperty(state.categoryEditor.groupKeys[0])) {
    //         debugger;
    //     }
    // }

    return {
        template: state.categoryEditor.template,
        templateOriginal: state.categoryTemplate[props.templateKey],
        categoryTemplate: state.categoryTemplate,
        groupKeys: state.categoryEditor.groupKeys,
        options: state.categoryEditor.options,
        filter: state.filter["defaultfilter"],
        groupTemplateList: state.groupTemplateList,
        groupTemplate: state.groupTemplate,
        questionTemplate: state.questionTemplate,
        metadataDefinition: state.metadataDefinition[metadataTemplateTypes.CategoryTemplate],
        //templateFromKey: state.categoryTemplate[props.templateKey],
        groupTemplates: state.groupTemplate,
        //groupKeysFromKey: state.categoryTemplate[props.templateKey]?.groupKeys || [],
        questionTemplates: state.questionTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getGroupTemplate,
        getCategoryTemplate,
        initializeCategoryEditorCreate,
        initializeCategoryEditorUpdate,
        updateCategoryTemplate,
        clearCategoryEditor,
        createGroupTemplate,
        saveGroupTemplate,
        validateCategoryNameAndQualifierUnique,
        getQuestionTemplate,
        addCategoryGroup,
        removeCategoryGroup,
        saveQuestionTemplate,
        expanderClear
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEditor);
