import * as types from "../constants/actionConstants";

const initialState = {
    newShowGroupAdded: false,
    answerIndex: null,
};

export default function showGroupReducer(state = initialState, action) {
    switch (action.type) {
        case types.ANSWER_SHOW_GROUP_SUCCESS:
            return {
                ...state,
                ...action
            };

        case types.ANSWER_SHOW_GROUP_CLEAR:
            return {
                ...state,
                ...action
            };

        default:
            return state;
    }
}
