// import * as tag from "../constants/displayDefMetadataConstants";
// import {getCustomTypography} from "./getCustomTypography";
// import * as ddo from "../constants/displayDefObjConstants";
import * as custom from "./getCustomTypography";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as mode from "./displayOneQuestionPerPage";
import React from "react";


export function getCategoryTitleBar(qnr, categoryTitleObj, itemObj, convertedVariant, uploadedImageStyles, objId) {

    let htmlObj = <div></div>;

    //let categoryTemplate = ddo.getCategory(qnr, itemObj.id);

    let bColor = "#01579b";
    let bOpacity = "100%";

    let leftOnly = categoryTitleObj.useCustomLeft && (!categoryTitleObj.useCustomCenter && !categoryTitleObj.useCustomRight);

    if (categoryTitleObj.useCustomLeft || categoryTitleObj.useCustomCenter || categoryTitleObj.useCustomRight) {
        // Custom category header

        if (categoryTitleObj.categoryCustomObj.colorType !== "default") {
            bColor = (categoryTitleObj.categoryCustomObj.colorType === "select") ? categoryTitleObj.categoryCustomObj.color : categoryTitleObj.categoryCustomObj.colorEntry;
            bOpacity = categoryTitleObj.categoryCustomObj.opacity;
        }

        let CustomLeft = custom.getCustomTypography(categoryTitleObj.titleLeftCustomObj, "left");
        let CustomCenter = custom.getCustomTypography(categoryTitleObj.titleCenterCustomObj, "center");
        let CustomRight = custom.getCustomTypography(categoryTitleObj.titleRightCustomObj, "right");

        htmlObj = <Grid key={"CatTitle-" + objId} container item sm={12} style={{background: bColor, opacity: bOpacity, padding: "8px 8px 8px 8px"}}>
            <div style={{width: '100%', display: 'flex'}}>
                <div style={{textAlign: 'left', width: leftOnly ? '100%' : '35%'}}>
                    {categoryTitleObj.useCustomLeft ? <CustomLeft>{categoryTitleObj.titleLeft}</CustomLeft> : ""}
                </div>
                <div style={{textAlign: 'center', width: '30%'}}>
                    {categoryTitleObj.useCustomCenter ? <CustomCenter>{categoryTitleObj.titleCenter}</CustomCenter> : ""}
                </div>
                <div style={{textAlign: 'right', width: '35%'}}>
                    {categoryTitleObj.useCustomRight ? <CustomRight>{categoryTitleObj.titleRight}</CustomRight> : ""}
                </div>
            </div>
        </Grid>;

    }
    else {

        // Used to help spacing on long titles where there is only one being used
        let leftTitleOnly    = (categoryTitleObj.titleLeft !== "") && (categoryTitleObj.titleCenter === "") && (categoryTitleObj.titleRight === "");
        let centerTitleOnly  = (categoryTitleObj.titleLeft === "") && (categoryTitleObj.titleCenter !== "") && (categoryTitleObj.titleRight === "");
        let rightTitleOnly   = (categoryTitleObj.titleLeft === "") && (categoryTitleObj.titleCenter === "") && (categoryTitleObj.titleRight !== "");
        let leftAndRightOnly = (categoryTitleObj.titleLeft !== "") && (categoryTitleObj.titleCenter === "") && (categoryTitleObj.titleRight !== "");

        if (leftTitleOnly) {
            htmlObj =
                <Grid key={"HF1-" + objId} container item sm={12} style={{backgroundColor: '#01579b', padding: "8px 8px 8px 8px"}}>
                    <div style={{width: '100%', display: 'flex'}}>
                        <div style={{color: '#FFFFFF', float: 'left', width: '100%'}}>
                            {categoryTitleObj.titleLeftIsImage ?
                                <img src={`data:image/png;base64,${categoryTitleObj.titleLeft}`} alt="" style={uploadedImageStyles}/>
                                :
                                (categoryTitleObj.titleLeft === "") ?
                                    <Typography color='inherit' variant={categoryTitleObj.titleLeftVariant !== "" ? categoryTitleObj.titleLeftVariant : convertedVariant}>&nbsp;</Typography>
                                    :
                                    <Typography color='inherit' variant={categoryTitleObj.titleLeftVariant !== "" ? categoryTitleObj.titleLeftVariant : convertedVariant}>{categoryTitleObj.titleLeft}</Typography>
                            }
                        </div>
                    </div>
                </Grid>;
        }
        else if (centerTitleOnly) {
            htmlObj =
                <Grid key={"HF2-" + objId} container item sm={12} style={{backgroundColor: '#01579b', padding: "8px 8px 8px 8px"}}>
                    <div style={{width: '100%', display: 'flex'}}>
                        <div style={{color: '#FFFFFF', float: 'right', width: '100%', textAlign: 'center'}}>
                            {categoryTitleObj.titleCenterIsImage ?
                                <img src={`data:image/png;base64,${categoryTitleObj.titleCenter}`} alt="" style={uploadedImageStyles}/>
                                :
                                <Typography color='inherit' variant={categoryTitleObj.titleCenterVariant !== "" ? categoryTitleObj.titleCenterVariant : convertedVariant}>{categoryTitleObj.titleCenter}</Typography>
                            }
                        </div>
                    </div>
                </Grid>;
        }
        else if (rightTitleOnly) {
            htmlObj =
                <Grid key={"HF3-" + objId} container item sm={12} style={{backgroundColor: '#01579b', padding: "8px 8px 8px 8px"}}>
                    <div style={{width: '100%', display: 'flex'}}>
                        <div style={{color: '#FFFFFF', float: 'right', width: '100%', textAlign: 'right'}}>
                            {categoryTitleObj.titleRightIsImage ?
                                <img src={`data:image/png;base64,${categoryTitleObj.titleRight}`} alt="" style={uploadedImageStyles}/>
                                :
                                <Typography color='inherit' align="right"  variant={categoryTitleObj.titleRightVariant !== "" ? categoryTitleObj.titleRightVariant : convertedVariant}>{categoryTitleObj.titleRight}</Typography>
                            }
                        </div>
                    </div>
                </Grid>;
        }
        else if (leftAndRightOnly) {

            let leftVariant = categoryTitleObj.titleLeftVariant !== "" ? categoryTitleObj.titleLeftVariant : convertedVariant;
            let rightVariant = categoryTitleObj.titleRightVariant !== "" ? categoryTitleObj.titleRightVariant : convertedVariant;

            leftVariant = mode.displayOneQuestionPerPage() ? "h5" : leftVariant; // Override if screen to small
            rightVariant = mode.displayOneQuestionPerPage() ? "h5" : rightVariant;

            htmlObj =
                <Grid key={"HF4-" + objId} container item sm={12} style={{backgroundColor: '#01579b', padding: "8px 8px 8px 8px"}}>
                    <div style={{width: '100%', display: 'flex'}}>
                        <div style={{color: '#FFFFFF', float: 'left', width: '50%'}}>
                            {categoryTitleObj.titleLeftIsImage ?
                                <img src={`data:image/png;base64,${categoryTitleObj.titleLeft}`} alt="" style={uploadedImageStyles}/>
                                :
                                (categoryTitleObj.titleLeft === "") ?
                                    <Typography color='inherit' variant={leftVariant !== "" ? leftVariant : convertedVariant}>&nbsp;</Typography>
                                    :
                                    <Typography color='inherit' variant={leftVariant !== "" ? leftVariant : convertedVariant}>{categoryTitleObj.titleLeft}</Typography>
                            }
                        </div>
                        <div style={{color: '#FFFFFF', float: 'right', width: '50%', textAlign: 'right', padding: "8px 8px 8px 8px"}}>
                            {categoryTitleObj.titleRightIsImage ?
                                <img src={`data:image/png;base64,${categoryTitleObj.titleRight}`} alt="" style={uploadedImageStyles}/>
                                :
                                <Typography color='inherit' align="right" variant={rightVariant !== "" ? rightVariant : convertedVariant}>{categoryTitleObj.titleRight}</Typography>
                            }
                        </div>
                    </div>
                </Grid>;
        }
        else {
            htmlObj =
                <Grid key={"HF5-" + objId} container item sm={12} style={{backgroundColor: '#01579b', padding: "8px 8px 8px 8px"}}>
                    <div style={{width: '100%', display: 'flex'}}>
                        <div style={{color: '#FFFFFF', float: 'left', width: '40%'}}>
                            {categoryTitleObj.titleLeftIsImage ?
                                <img src={`data:image/png;base64,${categoryTitleObj.titleLeft}`} alt="" style={uploadedImageStyles}/>
                                :
                                (categoryTitleObj.titleLeft === "") ?
                                    <Typography color='inherit' variant={categoryTitleObj.titleLeftVariant !== "" ? categoryTitleObj.titleLeftVariant : convertedVariant}>&nbsp;</Typography>
                                    :
                                    <Typography color='inherit' variant={categoryTitleObj.titleLeftVariant !== "" ? categoryTitleObj.titleLeftVariant : convertedVariant}>{categoryTitleObj.titleLeft}</Typography>
                            }
                        </div>
                        <div style={{color: '#FFFFFF', float: 'right', width: '20%', textAlign: 'center'}}>
                            {categoryTitleObj.titleCenterIsImage ?
                                <img src={`data:image/png;base64,${categoryTitleObj.titleCenter}`} alt="" style={uploadedImageStyles}/>
                                :
                                <Typography color='inherit' variant={categoryTitleObj.titleCenterVariant !== "" ? categoryTitleObj.titleCenterVariant : convertedVariant}>{categoryTitleObj.titleCenter}</Typography>
                            }
                        </div>
                        <div style={{color: '#FFFFFF', float: 'right', width: '40%', textAlign: 'right'}}>
                            {categoryTitleObj.titleRightIsImage ?
                                <img src={`data:image/png;base64,${categoryTitleObj.titleRight}`} alt="" style={uploadedImageStyles}/>
                                :
                                <Typography color='inherit' align="right" variant={categoryTitleObj.titleRightVariant !== "" ? categoryTitleObj.titleRightVariant : convertedVariant}>{categoryTitleObj.titleRight}</Typography>
                            }
                        </div>
                    </div>
                </Grid>;
        }
    }

    return htmlObj;
}
