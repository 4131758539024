import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Typography from "@mui/material/Typography";
import {getTag} from "../../../actions/tagActions";
import QuestionViewerExpandable from "../questions/QuestionViewerExpandable";
import ArgoReviewTemplate from "../../common/ArgoReviewTemplate";
import * as TEMPLATE from "../../../constants/templateConstants";


class TagReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openQuestionReview: false,
            selectedQuestionInfoKey: ""
        };
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.getTag(this.props.id);
        }
    }

    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {

        switch (buttonTitle) {
            case "BACK":
                // Used on info icon button from question accordions
                this.setState({openQuestionReview: false});
                break;
            // no default
        }
        event.preventDefault(); // Fix for the enter key propagating to the next focusable element
    }

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {

        if (this.state.openQuestionReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.QUESTION}
                templateKey={this.state.selectedQuestionInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        const template = (this.props.template !== null) ? this.props.template : {};

        return (
            template.hasOwnProperty("questionKeys") ?
                <div id="tag-viewer-container-div" style={{padding: "30px"}}>

                    <Typography variant="h6" component="div">
                        Tag Information:
                    </Typography>

                    <div>&nbsp;</div>

                    <div style={{display: "flex", width: "40%"}}>
                        <div id="tag-viewer-label-div" style={{float: "left", paddingBottom: "12px", fontSize: "20px", color: "silver"}}>Name:</div>

                        <div id="tag-viewer-spacer1-div" style={{float: "left", width: "20px"}}></div>

                        <div id="tag-viewer-name-div" style={{float: "left", paddingBottom: "12px", fontSize: "20px", fontWeight: "500"}}>{template.name}</div>

                    </div>

                    {/*// ===========================================================================================================*/}
                    {/*// ASSIGNED QUESTIONS LIST */}
                    {/*// ===========================================================================================================*/}

                    <QuestionViewerExpandable
                        parentType={TEMPLATE.TYPE.TAG}
                        parentTemplate={template}
                        questionKeys={template.questionKeys}
                        showIconButtonReorder={false}
                        showIconButtonEdit={false}
                        showIconButtonRemove={false}
                        setStateCallback={this.setStateCallback}
                    />

                </div>
                : ""
        );
    }
}

TagReview.propTypes = {
    id: PropTypes.number,
    width: PropTypes.string
};

function mapStateToProps(state, props) {
    return {
        template: state.tag.hasOwnProperty(props.id) ? state.tag[props.id] : {},
        questionTemplates: state.questionTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getTag}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TagReview);
