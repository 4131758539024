import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Divider from "@mui/material/Divider";
import {getIntegrationLogById} from "../../../actions/integrationLogsActions";
import {formatDateDisplay} from '../../../utilities/dateFormats';
import {getIntegrationLogStatus} from "../../../constants/integrationConstants";
import style from "../../../blueTheme";


class IntegrationLogDetails extends Component {

    componentWillMount() {
        if (this.props.id) {
            this.props.getIntegrationLogById(this.props.id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.id !== nextProps.id) {
            nextProps.getIntegrationLogById(nextProps.id);
        }
    }

    render() {

        const integrationLog = {...this.props.integrationLog};
        let integrationEvent;
        if (Object.entries(integrationLog).length > 0) {
            integrationEvent = {...integrationLog.integrationEvent}
            integrationEvent.message = JSON.parse(integrationEvent.message)
        }

        const rootStyle = {
            font: style.palette.font,
            padding: "3%",
            justifyContent: "space-between",
            width: "94%",
            maxHeight: "fit-content",
            height: "fit-content",
            minHeight: "fit-content",
            ...this.props.style
        };

        // BRC Commented out was created a mutation error and causing closeDrawer to fail on first click
        // if (integrationLog.integrationEvent !== undefined) {
        //     delete integrationLog.integrationEvent.integration['apiKey1'];
        //     delete integrationLog.integrationEvent.integration['apiKey2'];
        // }

        return (
            (integrationLog && integrationLog.hasOwnProperty('integrationEvent')) ?
                <div style={rootStyle}>
                    <div id="IntegrationLogDetails-EventDate-container-div" style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontWeight: "400"}}>{"Event date:"}</div>
                        <div style={{width: "15px"}}/>
                        <div style={{
                            float: "left",
                            paddingBottom: "12px",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{formatDateDisplay(integrationLog.createdDate, "DATETIME")}</div>
                    </div>
                    <div id="IntegrationLogDetails-Title-container-div" style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontWeight: "400"}}>{"Title:"}</div>
                        <div style={{width: "15px"}}/>
                        <div style={{
                            paddingBottom: "12px",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{integrationLog.integrationEvent.integration.name}</div>
                    </div>
                    <div id="IntegrationLogDetails-Questionnaire-container-div"
                         style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontWeight: "400"}}>{"Questionnaire:"}</div>
                        <div style={{width: "15px"}}/>
                        <div style={{
                            paddingBottom: "12px",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{integrationLog.integrationEvent.questionnaire.questionnaireTemplate.name}</div>
                    </div>
                    <div id="IntegrationLogDetails-TrackingId-container-div" style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontWeight: "400"}}>{"Tracking Id:"}</div>
                        <div style={{width: "15px"}}/>
                        <div style={{
                            paddingBottom: "12px",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{integrationLog.integrationEvent.trackingId}</div>
                    </div>
                    <div id="IntegrationLogDetails-ResponseId-container-div" style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontWeight: "400"}}>{"Response Id:"}</div>
                        <div style={{width: "15px"}}/>
                        <div style={{
                            paddingBottom: "12px",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{integrationLog.responseId}</div>
                    </div>
                    <div id="IntegrationLogDetails-Result-container-div" style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontWeight: "400"}}>{"Result:"}</div>
                        <div style={{width: "15px"}}/>
                        <div style={{
                            paddingBottom: "12px",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{getIntegrationLogStatus[integrationLog.success ? 1 : 0]}</div>
                    </div>
                    <div id="IntegrationLogDetails-ResultMessage-container-div"
                         style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontWeight: "400"}}>{"Result Message:"}</div>
                        <div style={{width: "15px"}}/>
                        <div style={{
                            paddingBottom: "12px",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{integrationLog.resultMessage}</div>
                    </div>
                    <div id="IntegrationLogDetails-ResponseTime-container-div" style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontWeight: "400"}}>{"Response Time (ms):"}</div>
                        <div style={{width: "15px"}}/>
                        <div style={{
                            paddingBottom: "12px",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{integrationLog.responseTime}</div>
                    </div>

                    <div style={{width: "40%"}}>
                        <div style={{paddingBottom: "12px", fontSize: "16px", fontWeight: "500"}}>{" "}</div>
                    </div>

                    <div style={{width: "40%"}}>
                        <div style={{
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{"Event Message:"}</div>
                    </div>

                    <pre style={{width: "100%", fontSize: "16px"}}>
                        {JSON.stringify(integrationEvent.message, null, 4)}
                    </pre>

                    <div style={{width: "40%"}}>
                        <div style={{paddingBottom: "0px", fontSize: "12px", fontWeight: "500"}}>{"\u00A0"}</div>
                    </div>

                    <div style={{width: "40%"}}>
                        <div style={{
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>{"Event Details:"}</div>
                    </div>

                    <pre style={{width: "100%", fontSize: "16px"}}>
                        {JSON.stringify(integrationEvent, null, 4)}
                    </pre>


                    <div>&nbsp;</div>

                    <Divider style={{
                        height: "100%",
                        minWidth: "2px",
                        marginRight: "8px",
                        marginLeft: "8px",
                        backgroundColor: "rgb(97, 97, 97, .8)"
                    }}/>
                </div>
                : ""
        );
    }
}

IntegrationLogDetails.defaultProps = {
    integrationLog: undefined
};

IntegrationLogDetails.propTypes = {
    id: PropTypes.number,
};

function mapStateToProps(state, props) {
    return {
        integrationLog: state.integrationLog
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getIntegrationLogById}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationLogDetails);
