import * as types from "../constants/actionConstants";


const initialState = [];


function questionTemplateListReducer(state = initialState, action) {
    switch (action.type) {
        case types.QUESTION_TEMPLATE_LIST_GET_SUCCESS:
        case types.QUESTION_TEMPLATE_LIST_CURRENT_GET_SUCCESS:
            return action.list;

        default:
            return state;
    }
}

export default questionTemplateListReducer;