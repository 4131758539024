
export const CUSTOMER_JOURNEY_TYPE = "CUSTOMER_JOURNEY";
export const CUSTOMER_JOURNEY_NAME= "Customer Journey";

export const SALES_AND_SERVICE_TYPE = "SALES_AND_SERVICE";
export const SALES_AND_SERVICE_NAME = "Sales & Service";


export const getIntegrationTypeName = {
    [CUSTOMER_JOURNEY_TYPE]: [CUSTOMER_JOURNEY_NAME],
    // [SALES_AND_SERVICE_TYPE]: [SALES_AND_SERVICE_NAME]
};

export const IntegrationTypes = [
    {value: CUSTOMER_JOURNEY_TYPE, text: CUSTOMER_JOURNEY_NAME}
    // {value: SALES_AND_SERVICE_TYPE, text: SALES_AND_SERVICE_NAME}
];

export const IntegrationTypesFilter = [
    {type: "ALL", name: "All"},
    {type: CUSTOMER_JOURNEY_TYPE, name: CUSTOMER_JOURNEY_NAME},
    // {type: SALES_AND_SERVICE_TYPE, name: SALES_AND_SERVICE_NAME}
];

export const getIntegrationLogStatus = ["Failed", "Success"];


