import * as types from "../constants/actionConstants";


const initialState = {

};


function groupTemplateReducer(state = initialState, action) {
    switch (action.type) {
        case types.GROUP_TEMPLATE_GET_SUCCESS:
        case types.GROUP_TEMPLATE_CREATE_SUCCESS:
        case types.GROUP_TEMPLATE_UPDATE_SUCCESS:
            return {
                ...state,
                [action.groupTemplate.templateKey]: {
                    ...action.groupTemplate
                }
            };

        default:
            return state;
    }
}

export default groupTemplateReducer;