import * as types from "../constants/actionConstants";

const initialState = {
    count: 0
};

function outstandingQuestionnaireReducer(state = initialState, action) {
    switch (action.type) {
        case types.QUESTIONNAIRE_OUTSTANDING_GET_SUCCESS:
            return {count: action.count};
        default:
            return state;
    }
}

export default outstandingQuestionnaireReducer;