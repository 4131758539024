import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import * as categoryTemplateActions from "../../../actions/categoryTemplateActions";
import CategoryEditor from "./CategoryEditor";
import GroupContainer from "../groups/GroupContainer";

class CategoryContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            templateKey: ""
        };
    }

    componentDidMount() {
        let templateKey = "";
        if (!this.props.templateKey) {
            templateKey = this.props.match.params.templateKey;
        } else {
            templateKey = this.props.templateKey;
        }
        this.setState({templateKey: templateKey});
        this.props.categoryTemplateActions.getCategoryTemplate(templateKey);
    }

    getQuestionPreview() {
        let html;

        if (this.props.template !== undefined) {
            html = (
                <div id="CategoryContainer-container-div">
                    <Card>
                        <CardHeader
                            title={"Category: " + (this.props.template[this.state.templateKey] ? this.props.template[this.state.templateKey].name : "")}
                            titleTypographyProps={{color: "purple"}}
                            subtitle={"Key: "+this.state.templateKey}
                            actAsExpander
                        >
                            {/*<CategoryReview template={this.props.template[this.state.templateKey]} />*/}
                        </CardHeader>
                        <CardContent expandable>
                            <CategoryEditor template={this.props.template[this.state.templateKey]} width="90%" scrollTo="CategoryContainer-container-div"/>
                        </CardContent>
                        <CardContent>
                            {
                                this.props.template[this.state.templateKey] !== undefined ?
                                    this.props.template[this.state.templateKey].groupKeys.map(function (groupKey) {
                                        return <GroupContainer key={groupKey} templateKey={groupKey} />;
                                    })
                                    : ""
                            }
                        </CardContent>
                    </Card>
                </div>
            );
        } else {
            html = <div />;
        }
        return html;
    }

    render() {

        return this.getQuestionPreview();
    }

}

CategoryContainer.defaultProps = {
    match: {},
    categoryTemplateActions: {},
    templateKey: "",
    template: undefined
};

CategoryContainer.propTypes = {
    templateKey: PropTypes.string,
    template: PropTypes.object,
    match: PropTypes.object,
    categoryTemplateActions: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return {
        categoryTemplateActions: bindActionCreators(categoryTemplateActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        template: state.categoryTemplate
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer);
