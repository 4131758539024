import React from 'react';


export default function NoPage() {

    return (
        <div>
            <h3>{"Invalid Page"}</h3>
        </div>
    );
};
