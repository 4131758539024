import * as types from "../constants/actionConstants";
import * as portalTypes from "../portal/constants/actionConstants";
import * as groupType from "../constants/groupTypeLikertOrDefault";
import TemplateApi from "../api/TemplateApi";
import * as TEMPLATE from "../constants/templateConstants";


const initializeGroupEditorCreate = (template) => {

    if (!template) {
        template = {
            metadata: {}
        };
    }

    return {
        type: types.GROUP_EDITOR_INITIALIZE,
        payload: {
            template: {
                answerOptionTemplates: [],
                templateKey: (template.templateKey)? template.templateKey : "",
                name: (template.name)? template.name : "",
                nameQualifier: (template.nameQualifier) ? template.nameQualifier : null,
                description: (template.description)? template.description : "",
                script: "",
                metadata: {...template.metadata},
                questionGroupType: (template.questionGroupType)? template.questionGroupType : groupType.GROUP_TYPE_DEFAULT,
            },
            questionKeys: [],
            options: {
                hasScript: false,
                hasDescription: false,
                createMode: true,
                validate: [],
                isValidating: false
            }
        }
    };
};

const initializeGroupEditorEdit = (template) => {
    return {
        type: types.GROUP_EDITOR_INITIALIZE,
        payload: {
            template: template,
            questionKeys: template.questionKeys,
            options: {
                hasScript: !!template.script,
                hasDescription: !!template.description,
                createMode: false,
                validate: [],
                isValidating: false
            }
        }
    };
};


const updateGroupTemplate = (template, options) => {
    return (dispatch) => {
        dispatch({type: types.GROUP_EDITOR_UPDATE, payload: {template, options}});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };
};

const clearGroupEditor = () => {
    return (dispatch) => {
        dispatch({type: types.GROUP_EDITOR_CLEAR});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT})
    };
};

const setGroupLikertMode = (template, isLikert) => {
    return {
        type: types.GROUP_EDITOR_UPDATE_TYPE,
        questionGroupType: isLikert ? groupType.GROUP_TYPE_LIKERT : groupType.GROUP_TYPE_DEFAULT
    };
};

const checkGroupValue = (filter) => {
    return (dispatch) => {
        dispatch({type: types.GROUP_EDITOR_VALUE_VALIDATING});
        dispatch(checkQuestionGroupExists(filter))
    };
};

const checkQuestionGroupExists = (filter) => {
    let thunk = (dispatch) => {
        TemplateApi.checkQuestionGroupExists(filter).then((data) => {
            dispatch({type: types.GROUP_EDITOR_VALUE_EXISTS, list: data.collection});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__groupExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};

const validateGroupNameAndQualifierUnique = (name, qualifier) => {

    let nameQualifier = qualifier === null ? "" : qualifier;

    let thunk = (dispatch) => {
        TemplateApi.checkTemplateNameAndQualifierExists(TEMPLATE.TYPE.GROUP, name, nameQualifier).then((data) => {
            dispatch({type: types.GROUP_EDITOR_VALUE_EXISTS, list: data.collection, depth: 0});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__questionExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};

// ---------------------------------------------------------------------------------------------------------------------
// Groups LIKERT and NON-LIKERT will have associated questions
// ---------------------------------------------------------------------------------------------------------------------

const addGroupQuestion = (question, template) => {
    return (dispatch) => {
        dispatch({type: types.GROUP_EDITOR_ADD_QUESTION, question: question});
        dispatch({type: types.GROUP_EDITOR_UPDATE, payload: {template}});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };
};

const removeGroupQuestion = (index) => {
    return (dispatch) => {
        dispatch({type: types.GROUP_EDITOR_REMOVE_QUESTION, index: index});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };
};

const reorderGroupQuestion = (isUp, currentIndex, template) => {
    return (dispatch) => {
        dispatch({type: types.GROUP_EDITOR_REORDER, isUp: isUp, currentIndex: currentIndex});
        dispatch({type: types.GROUP_EDITOR_UPDATE, payload: {template}});
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };
};


export {
    initializeGroupEditorCreate,
    initializeGroupEditorEdit,
    updateGroupTemplate,
    clearGroupEditor,
    setGroupLikertMode,
    checkGroupValue,
    validateGroupNameAndQualifierUnique,
    addGroupQuestion,
    removeGroupQuestion,
    reorderGroupQuestion
};
