import * as types from "../constants/actionConstants";

const initialState = {
    template: {
        templateKey: "",
        name: "",
        nameQualifier: null,
        description: "",
        script: "",
        metadata: {}
    },
    questionKeys: [],
    options: {
        hasScript: false,
        hasDescription: false,
        validate: [],
        isValidating: false
    }
};

export default function groupEditorReducer(state = initialState, action) {
    let addQuestion;
    let newState;
    let newAnswerOptions;
    let newTemplate;
    let target;
    let removed;
    switch (action.type) {
        case types.GROUP_EDITOR_INITIALIZE:
            return {
                ...state,
                ...action.payload
            };

        case types.GROUP_EDITOR_UPDATE:
            return {
                ...state,
                template: {
                    ...state.template,
                    ...action.payload.template
                },
                options: {
                    ...state.options,
                    ...action.payload.options
                }
            };

        case types.GROUP_EDITOR_CLEAR:
            return {
                ...initialState
            };

        case types.QUESTION_TEMPLATE_CREATE_SUCCESS:
            addQuestion = [
                ...state.questionKeys,
                action.questionTemplate.templateKey
            ];
            return {
                ...state,
                questionKeys: addQuestion
            };

        case types.GROUP_EDITOR_ADD_QUESTION:// eslint-disable-line no-case-declarations
            addQuestion = [
                ...state.questionKeys,
                action.question
            ];
            return {
                ...state,
                questionKeys: addQuestion
            };

        case types.GROUP_EDITOR_REMOVE_QUESTION:// eslint-disable-line no-case-declarations
            let removeQuestion = state.questionKeys.filter((element, index) => {
                return index !== action.index;
            });
            return {
                ...state,
                questionKeys: removeQuestion
            };

        case types.GROUP_EDITOR_REORDER:// eslint-disable-line no-case-declarations
            let questionKeys = [...state.questionKeys];
            target = (action.isUp)? -1 : 1;
            target = target + action.currentIndex;

            if (target < 0) { target=0;}
            else if (target > questionKeys.length -1) {target = questionKeys.length -1;}

            removed = questionKeys.splice(action.currentIndex, 1);
            questionKeys.splice(target, 0, removed[0]);
            return {
                ...state,
                questionKeys
            };

        case types.GROUP_EDITOR_VALUE_EXISTS:
            return {
                ...state,
                options: {
                    ...state.options,
                    validate: action.list,
                    isValidating: false
                }
            };
        case types.GROUP_EDITOR_VALUE_VALIDATING:
            return {
                ...state,
                options: {
                    ...state.options,
                    isValidating: true
                }
            };

        case types.GROUP_EDITOR_UPDATE_TYPE:
            newState = {...state};

            newTemplate = {
                ...state.template,
                questionGroupType: action.questionGroupType,
                answerOptionTemplates: []
            };

            // Clear out the selected questions to ensure that only Likert questions go in a Likert group and vice versa
            newState = {
                ...newState,
                template: newTemplate,
                questionKeys: []
            };
            return newState;

        case types.ANSWER_EDITOR_ADD_GROUP_ANSWER:
            newState = {...state};

            newAnswerOptions = [
                ...state.template.answerOptionTemplates,
                action.answer
            ];

            newTemplate = {
                ...state.template,
                answerOptionTemplates: newAnswerOptions
            };

            newState = {
                ...newState,
                template: newTemplate
            };

            return newState;

        case types.ANSWER_EDITOR_UPDATE_GROUP_ANSWER:
            newState = {...state};

            newAnswerOptions = [
                ...state.template.answerOptionTemplates
            ];

            newAnswerOptions[action.index] = action.answer;

            newTemplate = {
                ...state.template,
                answerOptionTemplates: newAnswerOptions
            };

            newState = {
                ...newState,
                template: newTemplate
            };
            return newState;

        case types.ANSWER_EDITOR_REMOVE_GROUP_ANSWER:// eslint-disable-line no-case-declarations
            newState = {...state};

            newAnswerOptions = [
                ...state.template.answerOptionTemplates
            ];

            newAnswerOptions = state.template.answerOptionTemplates.filter((element, index) => {
                return index !== action.index;
            });

            newTemplate = {
                ...state.template,
                answerOptionTemplates: newAnswerOptions
            };

            newState = {
                ...newState,
                template: newTemplate
            };

            return newState;

        case types.ANSWER_EDITOR_REORDER_GROUP_ANSWER:// eslint-disable-line no-case-declarations
            newState = {...state};

            newAnswerOptions = [
                ...state.template.answerOptionTemplates
            ];

            target = (action.isUp)? -1 : 1;
            target = target + action.currentIndex;

            if (target < 0) { target=0;}
            else if (target > newAnswerOptions.length -1) {target = newAnswerOptions.length -1;}

            removed = newAnswerOptions.splice(action.currentIndex, 1);
            newAnswerOptions.splice(target, 0, removed[0]);

            newTemplate = {
                ...state.template,
                answerOptionTemplates: newAnswerOptions
            };

            newState = {
                ...newState,
                template: newTemplate
            };

            return newState;

        default:
            return state;
    }
}
