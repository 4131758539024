import * as TEMPLATE from "../constants/templateConstants";

/**
 * Build pageable part of URL string
 * @return {string}
 */
export function FormatPageable(pageable, columnNameMapper) {

    // pagination API for components is 0 indexed

    let sort = columnNameMapper[pageable.name];

    let pagableStr = "&page=" + (pageable.pageNumber - 1 || "0").toString()
        + "&size=" + (pageable.pageSize || "")
        + "&sort=" + (sort || "") + "," + (pageable.direction || "");

    return pagableStr;
}


/**
 * Build filter part of URL string
 * @return {string}
 */
export function FormatFilter(filter) {

    let tagsStr = "";
    for (let i = 0; i < filter.tags.length; i++) {
        if (filter.tags[i].id === undefined) {
            tagsStr = (tagsStr === "") ? filter.tags[i] : (tagsStr + "," + filter.tags[i]);
        }
        else {
            tagsStr = (tagsStr === "") ? filter.tags[i].id : (tagsStr + "," + filter.tags[i].id);
        }

    }

    let filterStr = "?name=" + (filter.title || "")
    + (filter.nameQualifier !== "" ? ("&nameQualifier=" + (filter.nameQualifier || "" )) : "")
    + (filter.updatedBy !== "" ? ("&updatedBy=" + (filter.updatedBy || "" )) : "")
    + (filter.status !== "" ? ("&status=" + (filter.status || "" )) : "")
    + (filter.type !== "" ? ("&type=" + (filter.type || "" )) : "")
    + (filter.notType !== "" ? ("&type=" + (filter.notType || "" )) : "")
    + (tagsStr !== "" ? ("&tags=" + (tagsStr || "" )) : "")
    + (filter.integrationType !== "" ? ("&integrationType=" + (filter.integrationType || "" )) : "")
    + (filter.outstanding ? "&isOutstanding=true" : "");
    //    + "&currentFlag=" + (filter.currentFlag || false);  not sure needed since endpoint used determines value

    return filterStr;
}


/**
 * Build pageable and filter part of URL string from a pageable and filter object
 * @return {string}
 */
export function FormatPageableAndFilter(pageable, filter, columnNameMapper) {

    return FormatFilter(filter) + FormatPageable(pageable, columnNameMapper);
}


/**
 * Build pageable and filter part of URL string from filter object only
 * @return {string}
 */
export function FormatPageAndFilterFromFilter(filter, columnNameMapper) {

    let str = FormatFilter(filter) + FormatPageable(filter, columnNameMapper);

    return str;
}


export function pageableObj(pageNumber, pageSize, name, direction) {

    let obj = {pageNumber: pageNumber, pageSize: pageSize, name: name, direction: direction};

    return obj;
}


export function pageableObjFromFilter(filter) {

    let obj = {pageNumber: filter.pageNumber, pageSize: filter.pageSize, name: filter.name, direction: filter.direction};

    return obj;
}


export function pageableObjFromFilterSetPageOne(filter) {

    let obj = {pageNumber: 1, pageSize: filter.pageSize, name: filter.name, direction: filter.direction};

    return obj;
}








