import React, {Component} from "react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import StopIcon from "@mui/icons-material/Stop";
import ArgoTextFieldHook from "../common/ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "../common/ArgoTextFieldSelectHook";
import * as color from "../../constants/colorConstants";

class ArgoColorInput extends Component {

    constructor(props) {
        super(props);

        let value = props.initToObj;

        if (props.initToObj === null) {
            this.state = color.colorDefaultObj
        }
        else {
            this.state = {
                backgroundColor: "",
                backgroundImage: "",
                colorType: value.colorType,
                colorIndex: value.colorIndex,
                colorEntry: value.colorEntry,
                color: value.color,
                opacityEntry: value.opacityEntry,
                opacity: value.opacity,
                colorTypes: [],
                colorValues: [],
                colorSelectHasFocus: false
            };
        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }


    onTextChange = (event, fieldName) => {

        let value = event.target.value;
        let stateObj = {[fieldName]: value};

        switch(fieldName) {
            case "opacityEntry":
                stateObj = {'opacityEntry': value, 'opacity': value + "%"};
                break;
            case "colorEntry":
                stateObj = {'colorEntry': value};
                break;
            default:
                console.log("ArgoColorInput.js  Switch default E01"); // eslint-disable-line
        }

        if (this.props.mode === "title") {
            this.setState(stateObj, () => {this.props.setColor(this.getColorObj())});
        }
        else {
            this.setState(stateObj, () => {this.props.setColor(this.props.fieldName, this.state)});
        }
    };

    // onSelectChange = (event, fieldName, menuItemText) => {
    //
    //     let value = event.target.value;
    //
    //     this.setState({[fieldName]: value});
    // }

    onSelectColorChange = (fieldName, event) => {

        //let selectedValue = event.target.value;
        let stateObj = {[fieldName]: event.target.value};

        switch(fieldName) {
            case "colorType":
                switch (event.target.value) {
                    case "default":
                        stateObj = {'colorType': event.target.value, 'colorEntry': "black", 'color': "#000000", 'colorIndex': "0"};
                        break;
                    case "select":
                        stateObj = {'colorType': event.target.value, 'colorEntry': "black", 'color': "#000000", 'colorIndex': "0"};
                        break;
                    case "hex":
                        stateObj = {'colorType': event.target.value, 'colorEntry': "#000000", 'color': "#000000", 'colorIndex': "0"};
                        break;
                    case "rgb":
                        stateObj = {'colorType': event.target.value, 'colorEntry': "rgb(0,0,0)", 'color': "rgb(0,0,0)", 'colorIndex': "0"};
                        break;
                    default:
                        console.log("ArgoColorInput.js  Switch default E02"); // eslint-disable-line
                }
                break;
            case "colorValue":

                let index = color.standard.findIndex(object => {return object.color === event.target.value.color;});  // Get index of selection

                let selectedValue = color.standard[index].hex;
                stateObj = {'color': selectedValue, 'colorIndex': index};
                break;
            // no default
        }

        if (this.props.mode === "title") {
            this.setState(stateObj, () => {this.props.setColor(this.getColorObj())});
        }
        else {
            this.setState(stateObj, () => {this.props.setColor(this.props.fieldName, this.state)});
        }

    };


    getColorObj = () => {
        let colorObj = {
            "backgroundColor": "",
            "backgroundImage": "",
            "colorType": this.state.colorType,
            "colorIndex": this.state.colorIndex,
            "colorEntry": this.state.colorEntry,
            "color": this.state.color,
            "opacityEntry": this.state.opacityEntry,
            "opacity": this.state.opacity
        };

        return colorObj;
    };


    colorSelectFocused = () => {
        this.setState({colorSelectHasFocus: true})
    };

    colorSelectBlured = () => {
        this.setState({colorSelectHasFocus: false})
    };


    render() {

        return (
            <React.Fragment>

                <ArgoTextFieldSelectHook
                    fileName="ArgoColorInput"
                    fieldName="colorType"
                    index={this.props.index}
                    label={this.props.colorLabel}
                    value={this.state.colorType}
                    width="120px"
                    onChange={this.onSelectColorChange.bind(this, "colorType")}
                    menuItems={color.colorEntryTypes}
                    disabled={this.props.disabled}
                />

                <div style={{width: "20px"}}></div>

                {
                    (this.state.colorType !== 'default') ?
                        (this.state.colorType === 'select') ?
                            <div id="ArgoColorInput-color-selector" style={{marginBottom: "8px"}}>
                                <div style={{height: "10px", fontSize: "12px", color: this.state.colorSelectHasFocus ? "blue" : "#B0B0B0"}}>Color Value</div>
                                <div style={{display: 'flex'}}>
                                    <Select id={"color-input-color-name" + this.props.index}
                                                 style={{width: "200px", paddingTop: "7px"}}
                                                 label="Color Value"
                                                 variant="standard"
                                                 value={(this.state.colorType === "select") ? color.standard[this.state.colorIndex] : ""}
                                                 onChange={this.onSelectColorChange.bind(this, "colorValue")}
                                                 onFocus={this.colorSelectFocused}
                                                 onBlur={this.colorSelectBlured}
                                                 disabled={this.props.disabled}
                                    >
                                        {
                                            color.standard.map((c, index) => <MenuItem key={index} value={color.standard[index]}>
                                            {
                                                <div title={c.name} style={{display: "flex", alignItems: "center"}}><div style={{display: "flex", alignItems: "center"}}><StopIcon style={{color: c.color}}/></div><div style={{color: c.color}}>{c.name}</div></div>
                                            }
                                            </MenuItem>)
                                        }
                                    </Select>
                                    <div style={{width: "20px"}}></div>
                                </div>
                                {this.props.height !== '' ? <div style={{width: "20px", height: this.props.height}}></div> : ''}
                            </div>
                            :
                            <div style={{display: 'flex'}}>
                                <ArgoTextFieldHook
                                    fileName="ArgoColorInput"
                                    fieldName="colorEntry"
                                    index={this.props.index}
                                    label={this.state.colorType === 'hex' ? "e.g. #FF0000" : "e.g. rgb(255,0,0)"}
                                    value={this.state.colorEntry}
                                    onChange={this.onTextChange}
                                    width={{width: 200}}
                                    disabled={this.props.disabled}
                                />
                                <div style={{width: "20px"}}></div>
                            </div>
                        :
                        <div></div>
                }

                {this.props.opacityFlag ?
                        <ArgoTextFieldHook
                            fileName="ArgoColorInput"
                            fieldName="opacityEntry"
                            index={this.props.index}
                            type="number"
                            label="Opacity%"
                            value={this.state.opacityEntry}
                            onChange={this.onTextChange}
                            width={80}
                            disabled={this.props.disabled}
                        />
                    :
                    <div></div>
                }

            </React.Fragment>
        );
    }
}

ArgoColorInput.defaultProps = {
    index: 0,
    mode: "title",
    colorLabel: "Color",
    opacityFlag: false,
    initToObj: null,
    largeHexEntry: true,
    disabled: false,
    height: ''
};

ArgoColorInput.propTypes = {
    index: PropTypes.number,
    mode: PropTypes.string,
    colorLabel: PropTypes.string,
    fieldName: PropTypes.string,
    opacityFlag: PropTypes.bool,
    setColor: PropTypes.func,
    initToObj: PropTypes.object,
    largeHexEntry: PropTypes.bool,
    disabled: PropTypes.bool,
    height: PropTypes.string
};

export default ArgoColorInput;
