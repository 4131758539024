import {axiosWrap} from "./TemplateApi";
import * as system from "../constants/systemConstants";

const GET = "get";
class MetadataDefinitionApi {

    static getMetadataDefinitions() {
        return axiosWrap(GET , `${system.QD_API_URL}metadataDefinitions`)
            .then((response) => {
                return response.data;
            });
    }

    static getEntityMetadataDefinitions(entityType) {
        return axiosWrap(GET , `${system.QD_API_URL}metadataDefinitions/${entityType}`)
            .then((response) => {
                return response.data;
            });
    }
}

export default MetadataDefinitionApi;