import React, {useState, useEffect, useContext} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArgoButtonHook from "./ArgoButtonHook";
import {BUILD_KEY, BUILD_NUMBER, BUILD_TIMESTAMP, GIT_REVISION, GIT_BRANCH} from "../../constants/buildConstants";
import UserContext from "../../UserContext"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArgoNavigate from "./ArgoNavigate";
import {QD_URL} from "../../constants/systemConstants";


export default function ArgoAboutDialogHook(props) {
    const [open, setOpen] = useState(false);

    const theme = useContext(UserContext); // From blueTheme.js

    const fileName = props.fileName === undefined ? "NoFileName" : props.fileName;

    const uniqueIdentifier = fileName + "-ArgoAboutDialogHook";

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        setOpen(props.open);
    });

    const handleClose = () => {
        props.close();
    };

    return (
        <div>
            <Dialog
                id={uniqueIdentifier}
                open={open}
                onClose={handleClose}
                fullWidth={true}
                PaperProps={{
                    sx: {
                        backgroundColor: "white",
                        boxShadow: "none",
                        width: "500px",
                        fontFamily: theme.fontFamily
                    },
                }}
            >
                <DialogTitle id={uniqueIdentifier + "-DialogTitle"} sx={{fontWeight: "bold"}}>
                    {"About"}
                </DialogTitle>
                <DialogContent style={{paddingBottom: "0"}}>
                    <DialogContentText id={uniqueIdentifier + "-DialogContentText"} style={{color: "rgba(0, 0, 0, 0.87)"}}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Build Number: " + BUILD_NUMBER}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Build Key: " + BUILD_KEY}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Build Timestamp: " + BUILD_TIMESTAMP}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Git Branch: " + GIT_BRANCH}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={"Git Revision: " + GIT_REVISION}/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ArgoButtonHook
                        fileName="ArgoAboutDialogHook"
                        fieldName="close"
                        variant="text"
                        label="Close"
                        width={100}
                        onClick={handleClose}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}
