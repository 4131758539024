import React, {Component} from 'react';
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as groupTemplateActions from "../../../actions/groupTemplateActions";
import QuestionContainer from "../questions/QuestionContainer";
import GroupEditor from "./GroupEditor";


class GroupContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            templateKey: ""
        };
    }

    componentDidMount() {
        let templateKey = "";
        if (!this.props.templateKey) {
            templateKey = this.props.match.params.templateKey;
        } else {
            templateKey = this.props.templateKey;
        }
        this.setState({templateKey: templateKey});
        this.props.groupTemplateActions.getGroupTemplate(templateKey);
    }

    getHtml() {
        let html;

        if (this.props.template !== undefined) {
            html = (
                <div id="GroupContainer-container-div">
                    <Card>
                        <CardHeader
                            title={"Group: " + (this.props.template[this.state.templateKey] ? this.props.template[this.state.templateKey].name : "")}
                            titleTypographyProps={{color: "#29b173"}}
                            subtitle={"Key: "+this.state.templateKey}
                            actAsExpander
                        >
                            {/*<GroupReview template={this.props.template[this.state.templateKey]} />*/}
                        </CardHeader>
                        <CardContent expandable>
                            <GroupEditor template={this.props.template[this.state.templateKey]} width="90%" scrollTo="GroupContainer-container-div"/>
                        </CardContent>
                        <CardContent>
                            {
                                this.props.template[this.state.templateKey] !== undefined ?
                                    this.props.template[this.state.templateKey].questionKeys.map(function (questionKey) {
                                        return <QuestionContainer key={questionKey} templateKey={questionKey} />;
                                    })
                                    : ""
                            }
                        </CardContent>
                    </Card>
                </div>
            );
        } else {
            html = <div />;
        }
        return html;
    }

    render() {
        return this.getHtml();
    }

}

GroupContainer.defaultProps = {
    match: {},
    groupTemplateActions: {},
    templateKey: "",
    template: undefined
};

GroupContainer.propTypes = {
    templateKey: PropTypes.string,
    template: PropTypes.object,
    match: PropTypes.object,
    groupTemplateActions: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return {
        groupTemplateActions: bindActionCreators(groupTemplateActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        template: state.groupTemplate
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupContainer);
