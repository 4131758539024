import IntegrationApi from "../api/IntegrationApi";
import {closeDrawer} from "./drawerActions";
import {initGetTemplateList} from "./templateListActions";
import * as types from "../constants/actionConstants";
import * as TEMPLATE from "../constants/templateConstants";


export function getIntegration(id) {
    return (dispatch) => {
        dispatch({type: types.INTEGRATION_GET_INIT, id});
        IntegrationApi.getIntegrationById(id).then((integration) => {
            dispatch({type: types.INTEGRATION_GET_SUCCESS, integration});
        });
    };
}

export function createIntegrationAndCloseDrawer(integration, filter) {
    return (dispatch) => {
        IntegrationApi.createIntegration(integration).then((integration) => {
            dispatch({type: types.INTEGRATION_CREATE_SUCCESS, integration});
            dispatch(closeDrawer());
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.INTEGRATION, TEMPLATE.MODE.ALL));
        });
    };
}

export function updateIntegrationAndCloseDrawer(integration, filter) {
    return (dispatch) => {
        IntegrationApi.saveIntegration(integration).then((integration) => {
            dispatch({type: types.INTEGRATION_UPDATE_SUCCESS, integration});
            dispatch(closeDrawer());
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.INTEGRATION, TEMPLATE.MODE.ALL));
        });
    };
}

export function deleteIntegration(id, filter) {
    return (dispatch) => {
        IntegrationApi.deleteIntegration(id).then(() => {
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.INTEGRATION, TEMPLATE.MODE.ALL));
        });
    };
}

