import * as types from "../constants/actionConstants";

const initialState = {
    init: false,
    clone: false
};

function questionnaireInitReducer(state = initialState, action) {
    switch (action.type) {
        case types.QUESTIONNAIRE_TEMPLATE_INIT:
            return {
                init: true,
                clone: false
            };

        case types.QUESTIONNAIRE_TEMPLATE_CLONE:
            return {
                init: true,
                clone: true
            };

        case types.QUESTIONNAIRE_TEMPLATE_INIT_CLEAR:
            return {
                ...state,
                init: false
            };

        case types.QUESTIONNAIRE_TEMPLATE_CLONE_CLEAR:
            return {
                ...state,
                clone: false
            };

        default:
            return state;
    }
}

export default questionnaireInitReducer;
