import * as React from "react";

function SvgPositive(props) {
  return (
    <svg width={40} height={40} {...props}>
      <defs>
        <rect id="positive_svg__a" x={0} y={0} width={36} height={36} rx={18} />
        <path id="positive_svg__c" d="M5 0v2.999L8 3v2H5v3H3V5H0V3h3V0h2z" />
      </defs>
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <mask id="positive_svg__b" fill="#fff">
          <use xlinkHref="#positive_svg__a" />
        </mask>
        <rect
          stroke="#979797"
          strokeWidth={1.8}
          x={0.9}
          y={0.9}
          width={34.2}
          height={34.2}
          rx={17.1}
        />
        <g mask="url(#positive_svg__b)">
          <path fill={props.fill} fillOpacity={props.opacity} d="M-.3 0h36v36h-36z" />
        </g>
        <g mask="url(#positive_svg__b)">
          <g transform="translate(14 14)">
            <mask id="positive_svg__d" fill="#fff">
              <use xlinkHref="#positive_svg__c" />
            </mask>
            <use fill="#F4F4F4" xlinkHref="#positive_svg__c" />
            <g mask="url(#positive_svg__d)" fill="#FFF">
              <path d="M-2-2h12v12H-2z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgPositive;
