import React, {Component}from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Chip from "@mui/material/Chip";
import {clearQuestionTemplateSearchTags} from "../actions/questionEditorActions";
import {setFilter} from "../actions/setFilter";
import {pageableObjFromFilter} from "../utilities/PageableAndFilterUtilities";
import * as TEMPLATE from "../constants/templateConstants";


class TableView extends Component {

    deleteChip = (whichChip) => {

        // If a loading function was passed then call it to set the loading animation
        // because deleting a chip will do a call to server to get the list diplayed with new filter conditions
        // NOTE: All lists should use loading animations
        if (typeof this.props.setLoadingState === "function") {
            this.props.setLoadingState(function () {}, null, "FILTER");
        }

        let filter = {...this.props.pageFilter};

        filter.pageNumber = 1;
        filter.title = this.props.pageFilter.title;
        filter.nameQualifier = this.props.pageFilter.nameQualifier;
        filter.updatedBy = this.props.pageFilter.updatedBy;
        filter.status = this.props.pageFilter.status;
        filter.type = this.props.pageFilter.type;
        filter.integrationType = this.props.pageFilter.integrationType;
        filter.outstanding = this.props.pageFilter.outstanding;
        filter.tags = this.props.pageFilter.tags;
        filter.pageSize = filter.pageSize === undefined ? 10 : filter.pageSize;

        switch(whichChip) {
            case "outstanding":
                filter[whichChip] = false;
                break;
            case "tags":
                this.props.clearQuestionTemplateSearchTags(2); // NOTE: Using question depth 2 to store tags in filter !!!
                filter[whichChip] = [];
                break;
            default:
                filter[whichChip] = "";
        }

        this.props.setFilter(pageableObjFromFilter(filter), filter, this.props.templateType, this.props.templateMode);
    };


    render() {

        let filter = this.props.pageFilter;

        let chipBgColor = "#f2f2f2";

        return (
           <div id="TableView-chip-display-outer-div" style={{border: "1px solid lightgray", padding: "10px", boxShadow: "2px 4px whitesmoke"}}>
               <div id="TableView-chip-display-inner-div" style={{display: 'flex',  justifyContent: "right"}}>
                   {this.props.filter !== undefined ? <div style={{display: 'inline-flex'}}>
                       <div style={{marginTop: '4px'}}>{filter.title ? <Chip key={'title'} variant="outlined" sx={{backgroundColor:  chipBgColor}} onDelete={() => this.deleteChip("title")} label={"Name"}/> : ""}</div>
                       <div style={{width: filter.title ? "6px" : "0px"}}/>
                       <div style={{marginTop: '4px'}}>{filter.nameQualifier ? <Chip key={'nameQualifier'} variant="outlined" sx={{backgroundColor:  chipBgColor}} onDelete={() => this.deleteChip("nameQualifier")} label={"Qualifier"}/> : ""}</div>
                       <div style={{width: filter.nameQualifier.title ? "6px" : "0px"}}/>
                       <div style={{marginTop: '4px'}}>{filter.status ? <Chip key={'status'} variant="outlined" sx={{backgroundColor:  chipBgColor}} onDelete={() => this.deleteChip("status")} label={"Status"}/> : ""}</div>
                       <div style={{width: filter.status ? "6px" : "0px"}}/>
                       <div style={{marginTop: '4px'}}>{filter.type ? <Chip key={'type'} variant="outlined" sx={{backgroundColor:  chipBgColor}} onDelete={() => this.deleteChip("type")} label={"Type"}/> : ""}</div>
                       <div style={{width: filter.type ? "6px" : "0px"}}/>
                       <div style={{marginTop: '4px'}}>{filter.integrationType ? <Chip key={'integrationType'} variant="outlined" sx={{backgroundColor:  chipBgColor}} onDelete={() => this.deleteChip("integrationType")} label={"Integration Type"}/> : ""}</div>
                       <div style={{width: filter.integrationType ? "6px" : "0px"}}/>
                       <div style={{marginTop: '4px'}}>{filter.updatedBy ? <Chip key={'updated'} variant="outlined" sx={{backgroundColor:  chipBgColor}} onDelete={() => this.deleteChip("updatedBy")} label={"Updated By"}/> : ""}</div>
                       <div style={{width: filter.updatedBy ? "6px" : "0px"}}/>
                       <div style={{marginTop: '4px'}}>{filter.tags.length > 0 ? <Chip key={'tags'} variant="outlined" sx={{backgroundColor:  chipBgColor}} onDelete={() => this.deleteChip("tags")} label={"Tags"}/> : ""}</div>
                       <div style={{width: filter.tags.length > 0 ? "6px" : "0px"}}/>
                       <div style={{marginTop: '4px'}}>{filter.outstanding ? <Chip key={'outstanding'} variant="outlined" sx={{backgroundColor:  chipBgColor}} onDelete={() => this.deleteChip("outstanding")} label={"Outstanding Only"}/> : ""}</div>
                       <div style={{width: filter.outstanding ? "6px" : "0px"}}/>
                       <div>{this.props.filter}</div>
                   </div> : ""}
               </div>

               {this.props.children}
            </div>
       );
   }
}

TableView.propTypes = {
    children: PropTypes.object,
    currentPath: PropTypes.string,
    filter: PropTypes.object,
    setFilter: PropTypes.func,
    pageState: PropTypes.func,
    templateType: PropTypes.string,
    templateMode: PropTypes.string,
    setLoadingState: PropTypes.func,
};

function mapStateToProps(state, props) {
    let filterName = TEMPLATE.FILTER[props.templateMode][props.templateType];
    return {
        pageFilter: props.filter !== undefined ? state.filter[filterName] : state.filter // Use templateType & Mode to get name of filter
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFilter, clearQuestionTemplateSearchTags}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TableView);



// ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ

//  let screenHeight = window.screen.height;
//
// let cardHeaderSize = 100;
// let cardBodySize = 10 * 50;
// let cardFooterSize = 60;
// let cardHeight = (cardHeaderSize + cardBodySize + cardFooterSize) + "px";
//
//  let test = "Total width/height: " + window.screen.width + "*" + window.screen.height + "<br>" +
//      "Available width/height: " + window.screen.availWidth + "*" + window.screen.availHeight + "<br>" +
//      "Color depth: " + window.screen.colorDepth + "<br>" +
//      "Color resolution: " + window.screen.pixelDepth;
//
//  let userAgent = navigator.userAgent;
//  let browserName;
//
//  if (userAgent.match(/chrome|chromium|crios/i)){
//      browserName = "chrome";
//  }else if (userAgent.match(/firefox|fxios/i)){
//      browserName = "firefox";
//  }  else if (userAgent.match(/safari/i)){
//      browserName = "safari";
//  }else if (userAgent.match(/opr\//i)){
//      browserName = "opera";
//  } else if (userAgent.match(/edg/i)){
//      browserName = "edge";
//  }else{
//      browserName="No browser detection";
//  }

//var user = window.detect.parse(window.navigator.userAgent);
