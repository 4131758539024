import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {TableGrid, TableView} from '../../containers';
import FilterPopOver from "../dialogs/filters/FilterPopOver";
import {
    getTemplateList,
    initGetTemplateList,
    updatePageAndGetList,
    updateSortAndGetList
} from "../../actions/templateListActions";
import ArgoListTitleAndLoadingAnimationHook from "../common/ArgoListTitleAndLoadingAnimationHook";
import * as TEMPLATE from "../../constants/templateConstants";
import * as SYSTEM from "../../constants/systemConstants";


const pageTitle = {
    "questionnaire": "Questionnaire",
    "category": "Category",
    "group": "Group",
    "question": "Question",
    "action": "Action"
};

class TemplateVersionsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            templateKey: null,
            loading: false,
            filter: false
        };
    }

    componentDidMount(){
        this.setState({loading: true});
    }

    componentWillReceiveProps(nextProps) {

        // Called twice, first pass set filter false, then on second call list is loaded and loading animation can be stopped
        if (this.state.filter) {
            this.setState({filter: false});
        }
        else if (this.state.loading) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemCOnstants.js
                let delay = setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false});
            }
        }
    }

    // Used to test loading animation rendering
    delayedSetLoadingFalse = () => {
        this.setState({loading: false});
    }

    setLoadingStateTrue = (callback, parm1 = null, calledFrom = "") => {
        // If a prameter is passed then pass it to call back, should be the template of the item to be saved edit or created
        if (parm1 === null) {
            this.setState({loading: true, filter: false}, () => callback());
        } else {
            this.setState({loading: true, filter: false}, () => callback(parm1));
        }
    }

    onPageChange = (page, size, event) => {
        this.setState({loading: true, filter: true},() => this.props.updatePageAndGetList(page, size, this.props.filter, this.props.templateType, this.props.templateMode, event));
    };

    onSortChange = (sort, direction) => {
        this.setState({loading: true, filter: true},() => this.props.updateSortAndGetList(sort, direction, this.props.filter, this.props.templateType, this.props.templateMode));
    };

    render()
    {

        let customActions = [
            {
                func: this.props.filter.defaultActions.edit,
                label: "Edit"
            }
        ];

        return (
            <div id="TemplateVersionsList-container-div" style={{
                height: "100%",
                paddingTop: "15px",
                paddingLeft: "15px",
                paddingBottom: "15px",
                paddingRight: "15px"
            }}>

                <ArgoListTitleAndLoadingAnimationHook
                    fileName="TemplateVersionsList"
                    listTitle={pageTitle[this.props.templateType] + " Versions"}
                    loading={this.state.loading}
                    filter={this.state.filter}
                    showOutstandingAlert={false}
                />

                <div>&nbsp;</div>

                <div id="TemplateVersionsList-title-container-div"
                     style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                    <div style={{fontSize: "18px", fontWeight: "500"}}>{"Versions List For " + pageTitle[this.props.templateType] + ":"}</div>
                    <div style={{width: "20px"}}>&nbsp;</div>
                    <div style={{color: "gray"}}>Name:</div>
                    <div>&nbsp;</div>
                    <div style={{fontWeight: "500"}}>{this.props.filter.templateName}</div>

                    <div style={{width: "20px"}}>&nbsp;</div>
                    <div style={{color: "gray"}}>Qualifier:</div>
                    <div>&nbsp;</div>
                    <div style={{fontWeight: "500"}}>{this.props.filter.templateNameQualifier}</div>
                </div>

                <div>&nbsp;</div>

        <TableView
                    templateType={this.props.templateType}
                    templateMode={this.props.templateMode}
                    setLoadingState={this.setLoadingStateTrue}
                    filter={<FilterPopOver page={TEMPLATE.FILTER[this.props.templateMode][this.props.templateType]}
                                           templateType={this.props.templateType}
                                           templateMode={this.props.templateMode}
                                           filterName={TEMPLATE.FILTER[this.props.templateMode][this.props.templateType]}
                                           submitValue={this.props.getTemplateList}
                                           statuses={["All", "Draft", "Published"]}
                                           setLoadingState={this.setLoadingStateTrue}
                    />}>
                    <TableGrid displayType={this.props.templateType}
                               dataSource={this.props.versionsList}
                               customActions={customActions}
                               pageState={this.props.filter}
                               onPageChange={this.onPageChange}
                               sortState={this.props.filter}
                               onSortChange={this.onSortChange}
                               nameDrawer={this.props.filter.defaultActions.review}
                               templateType={this.props.templateType}
                               templateMode={TEMPLATE.MODE.VERSIONS}
                               showStartDate={this.props.templateType === TEMPLATE.TYPE.QUESTIONNAIRE}
                               showEndDate={this.props.templateType === TEMPLATE.TYPE.QUESTIONNAIRE}
                               showVersionCount={false}
                    />
                </TableView>
            </div>
        );
    }
}


TemplateVersionsList.defaultProps = {
    templateMode: TEMPLATE.MODE.VERSIONS,
};

TemplateVersionsList.propTypes = {
    templateMode: PropTypes.string,
    templateType: PropTypes.string,
};

function mapStateToProps(state, props) {
    return {
        questionnaireTypeDisplayed: state.settings.questionnaireTypeDisplayed,
        versionsList: state.templateVersions,
        filter: state.filter[TEMPLATE.FILTER[props.templateMode][props.templateType]]
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getTemplateList, updatePageAndGetList, initGetTemplateList, updateSortAndGetList}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateVersionsList);
