import React, {Component} from 'react';
import PropTypes from "prop-types";
import ArgoToolbarHook from "./ArgoToolbarHook";
import CategoryReview from "../qd/categories/CategoryReview";
import GroupReview from "../qd/groups/GroupReview";
import QuestionReview from "../qd/questions/QuestionReview";
import * as TEMPLATE from "../../constants/templateConstants";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {popCallBack, pushCallBack} from "../../actions/reviewTemplateActions";


class ArgoReviewTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            childComponent: true
        };
    }

    componentDidMount() {
        // let page = document.getElementById("ArgoReviewTemplate-container-div");
        // if (page) {
        //     page.scrollIntoView(false);
        // }

        if (this.props.callbacks.length === 0) this.setState({childComponent: false})
        this.props.pushCallBack(this.props.handleToolbarButtonClickFunction);
    }

    onCallback = (event, fieldname, buttonTitle) => {
        //calling last callback function before it is removed
        this.props.callbacks[this.props.callbacks.length - 1](event, fieldname, buttonTitle);
        this.props.popCallBack();
    }

    render() {

        let fieldname = "review-back";
        let scrollTo = "ArgoReviewTemplate-height-div";
        return (
            <div id="ArgoReviewTemplate-container-div" style={{height: "100%"}}>
                {!this.state.childComponent ?
                    <div style={{width: "100%"}}>
                        <ArgoToolbarHook
                            fileName="ArgoReviewTemplate"
                            fieldName={fieldname}
                            buttonTitles={["BACK"]}
                            onClick={this.onCallback}
                            width={this.props.width}
                        />
                        <div id="ArgoReviewTemplate-height-div" style={{height: "50px"}}></div>
                    </div>
                    : ""}
                <div style={{height: "100%"}}>
                {
                        this.props.templateType === TEMPLATE.TYPE.CATEGORY ?
                           <CategoryReview templateKey={this.props.templateKey} scrollTo={scrollTo}/>
                        :
                        this.props.templateType === TEMPLATE.TYPE.GROUP ?
                            <GroupReview templateKey={this.props.templateKey} scrollTo={scrollTo}/>
                        :
                            <QuestionReview templateKey={this.props.templateKey} scrollTo={scrollTo}/>
                    }
                </div>
            </div>
        )
    }
}

ArgoReviewTemplate.defaultProps = {
    width: "100%"
};

ArgoReviewTemplate.propTypes = {
    templateType: PropTypes.string,
    templateKey: PropTypes.string,
    handleToolbarButtonClickFunction: PropTypes.func,
    width: PropTypes.string
};

function mapStateToProps(state, props) {
    return {
        callbacks: state.reviewTemplate.callbacks
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({pushCallBack, popCallBack}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArgoReviewTemplate);
