import * as types from "../constants/actionConstants";
import * as metadataTemplateTypes from "../constants/metadataTemplateTypes";

const initialState = {
    isLoading: false,
    [metadataTemplateTypes.CategoryTemplate]: {collection: []},
    [metadataTemplateTypes.GroupTemplate]: {collection: []},
    [metadataTemplateTypes.QuestionTemplate]: {collection: []},
    [metadataTemplateTypes.AnswerTemplate]: {collection: []},
    [metadataTemplateTypes.ActionTemplate]: {collection: []},
};

function metadataDefinitionReducer(state = initialState, action) {
    let newState = {};
    switch (action.type) {
        case types.METADATA_DEFINITION_LIST_GET_INIT:
            newState = {
                ...state,
                isLoading: true
            };

            return newState;
        case types.METADATA_DEFINITION_LIST_GET_SUCCESS:

            newState = {
                ...state,
                isLoading: false,
                [action.entityType]: action.list
            };

            return newState;
        default:
            return state;
    }
}

export default metadataDefinitionReducer;
