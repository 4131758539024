import React, {useContext} from 'react';
import Button from "@mui/material/Button";
import UserContext from "../../UserContext"
import * as Colors from "@mui/material/colors";


export default function ArgoExpandCollapseButtonHook(props) {

    const theme = useContext(UserContext); // From blueTheme.js

    const fileName = props.fileName === undefined ? "NoFileName" : props.fileName;
    const fieldName = props.fieldName === undefined ? "expand-or-collapse-all" : props.fieldName;

    const uniqueIdentifier = fileName + "-ArgoButtonHook-" + fieldName;

    const variant = props.variant === undefined ? "contained" : props.variant;
    const toolbar = props.toolbar === undefined ? false : props.toolbar;  // Toolbars have a gray background and need wome special color tweaking

    const label = props.expandAll ? "Expand All" : "Collapse All";

    const paddingTop = props.paddingTop === undefined ? "0px" : props.paddingTop;

    const width = props.width === undefined ? 140 : props.width;

    const fullWidth = props.fullWidth === undefined ? ((width === 0)) : false;

    const disabled = props.disabled === undefined ? false : props.disabled;

    const autofocus = props.autofocus === undefined ? false : props.autofocus;

    const onClick = props.onClick === undefined ? function () {} : props.onClick;

    // button variant = contained ----------------------------------------------------------------------------------------------------------------

    const containedTextColor = props.containedTextColor === undefined ? 'white' : props.containedTextColor;
    const containedBgColor = props.containedBgColor === undefined ? Colors.blue[500] : props.containedBgColor;

    const containedHoverTextColor = props.containedHoverTextColor === undefined ? '#010203' : props.containedHoverTextColor;
    const containedHoverBgColor = props.containedHoverBgColor === undefined ? Colors.blue[500] : props.containedHoverBgColor;

    const handleOnClick = (event) => {
        onClick(event, props.fieldName)
    };

    return (
        <div style={{paddingTop: paddingTop}}>
            <Button
                id={uniqueIdentifier}
                name={uniqueIdentifier}
                variant={variant}
                sx={{
                    color: containedTextColor,
                    backgroundColor: containedBgColor,
                    fontFamily: theme.fontFamily,
                    width: width,
                    "&:hover": {
                        color: containedHoverTextColor,
                        backgroundColor: containedHoverBgColor,
                    }
                }}
                onClick={handleOnClick}
                disabled={disabled}
                fullWidth={fullWidth}
                autoFocus={autofocus}
            >
                {label}
            </Button>
        </div>
    );
}
