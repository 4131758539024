import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {ButtonGroup} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import MenuItem from "@mui/material/MenuItem";
import ListItemText from '@mui/material/ListItemText'
import Select from "@mui/material/Select";
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ArgoListPagingControlsHook from "./ArgoListPagingControlsHook";
import ArgoTextFieldHook from "./ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import ArgoMultipleSelectCheckmarksHook from "./ArgoMultipleSelectCheckmarksHook";
import ArgoButtonHook from "./ArgoButtonHook";
import {setFilter} from "../../actions/setFilter";
import {updatePageAndGetList} from "../../actions/templateListActions";
import {getTagDDLB} from "../../actions/tagActions";
import {pageableObjFromFilter} from "../../utilities/PageableAndFilterUtilities";
import {toLocale} from '../../utilities/dateFormats';
import * as TEMPLATE from "../../constants/templateConstants";
import * as SYSTEM from "../../constants/systemConstants";
import ArgoListTitleAndLoadingAnimationHook from "./ArgoListTitleAndLoadingAnimationHook";


class ArgoSelectExisting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            nameQualifier: "",
            updatedBy: "",
            selectedTags: [],
            sortBy: "nameAsc",
            selectedTemplateKey: "",
            loading: true,
            filter: false
        };
    }

    componentDidMount() {

        // Only questions have a tags filter
        if (this.props.mode === TEMPLATE.TYPE.QUESTION) {
            this.props.getTagDDLB(pageableObjFromFilter(this.props.filterTags), this.props.filterTags);
        }

        let tempSortBy = "";

        if (this.props.filter.title === "Name") {
            tempSortBy = "name"
        }
        else {
            tempSortBy = "lastModifiedDate";
        }

        this.setState({
            name: this.props.filter.title,
            nameQualifier: this.props.filter.nameQualifier,
            updatedBy: this.props.filter.updatedBy,
            selectedTags: this.props.filter.tags,
            sortBy: tempSortBy + this.props.filter.direction.charAt(0).toUpperCase() + this.props.filter.direction.slice(1),  //trick to uppercase first char
            selectedTemplateKey: "",
            loading: true
        }, this.updateFilter);
    }

    componentWillReceiveProps(nextProps) {
        // Called twice, first pass set filter false, then on second call list is loaded and loading animation can be stopped
        if (this.state.filter) {
            this.setState({filter: false});
        }
        else if (this.state.loading) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemCOnstants.js
                let delay = setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false});
            }

            //this.setState({loading: false,});
        }
    }

    // Used to test loading animation rendering
    delayedSetLoadingFalse = () => {
        this.setState({loading: false});
    }

    addTemplate = (e) => {
        this.props.onAddTemplate({templateKey: this.state.selectedTemplateKey});
        this.resetFilter()
    };


    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        this.setState({[fieldName]: value});
    }

    // handleTextFieldChange = (event, value, fieldName) => {
    //     let value = event.target.value;
    //
    //     this.setState({
    //         [event.target.name]: value,
    //     });
    // };

    handleSelectTagsOnChange = (event, fieldName, selectedIdCommaDelimitedString) => {

        this.setState({selectedTags: selectedIdCommaDelimitedString});
    };

    onSelectChange = (event, fieldName, menuItemText) => {

        let value = event.target.value;

        this.setState({[fieldName]: value});

    }

    handleSelectTemplateOnChange = (event, value) => {

        let formattedSelectedMenuItem = "";

        for (let i=0; i<this.props.filter.list.length; i++) {
            if (this.props.filter.list[i].templateKey === event.target.value) {

                let row = this.props.filter.list[i];

                formattedSelectedMenuItem = <div style={{display: "flex"}}>
                    <div style={{color: "black", width: "36%"}}>{row.name}</div>
                    <div style={{width: '2%'}}>&nbsp;</div>
                    <div style={{color: "silver", width: "20%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{row.nameQualifier}</div>
                    <div style={{width: '2%'}}>&nbsp;</div>
                    <div style={{color: "silver", width: "20%"}}>{this.props.mode === TEMPLATE.TYPE.QUESTION ? row.questionType : row.questionGroupType}</div>
                    <div style={{width: '2%'}}>&nbsp;</div>
                    <div style={{color: "silver", width: "20%"}}>{toLocale(row.lastModifiedDate)}</div>
                </div>;
                break;
            }
        }

        this.setState({selectedTemplateKey: event.target.value});
    };

    onPageChange = (page, size, event) => {
        this.setState({loading: true, filter: true},() => this.props.updatePageAndGetList(page, size, this.props.filter, this.props.filterType, TEMPLATE.MODE.CURRENT, event));
    };


    filter = () => {
        this.setState({loading: true, filter: true},() =>this.updateFilter());
    };


    resetFilter = () => {
        this.setState({
            name: "",
            nameQualifier: "",
            updatedBy: "",
            selectedTags: [],
            sortBy: "nameAsc",
            selectedTemplateKey: "",
            loading: true
        }, this.updateFilter);

    };


    updateFilter = () => {

        let filter = {...this.props.filter};

        filter.pageNumber = 1;

        let sortObj = this.getSortAndDirection(this.state.sortBy);
        filter.name = sortObj.sort;
        filter.direction = sortObj.direction;

        filter.mode = this.props.mode;
        filter.title = this.state.name;
        filter.nameQualifier = this.state.nameQualifier;
        filter.updatedBy = this.state.updatedBy;
        filter.tags = this.state.selectedTags;

        if (this.props.useTypeFilter) {
            filter.type = this.props.likert ? "LIKERT_RADIO" : "!LIKERT_RADIO";
        }
        else {
            filter.type = "";
        }

        this.props.setFilter(pageableObjFromFilter(filter), filter, this.props.filterType, TEMPLATE.MODE.CURRENT);
    };


    getSortAndDirection = (sortByValue) => {
        let sort = "";
        let direction = "";

        switch(sortByValue) {
            case "nameAsc":
                sort = "Name";
                direction = "asc";
                break;
            case "nameDesc":
                sort = "Name";
                direction = "desc";
                break;
            case "lastModifiedDateAsc":
                sort = "Updated by";
                direction = "asc";
                break;
            case "lastModifiedDateDesc":
                sort = "Updated by";
                direction = "desc";
                break;
            default:
                sort = "";
                direction = "";
                break;
        }

        return {sort: sort, direction: direction}
    };

    convertToDisplayText(questionType) {
        let displayName = '';
        switch (questionType) {
            case 'CHECKBOX': displayName = 'Checkbox';
                break;
            case 'YES_NO': displayName = 'Yes/No';
                break;
            case 'RADIO': displayName = 'Single Select';
                break;
            case 'LIKERT_RADIO': displayName = 'Likert';
                break;
            case 'NUMBER': displayName = 'Number';
                break;
            case 'PHONE': displayName = 'Phone';
                break;
            case 'DATE': displayName = 'Date';
                break;
            case 'DATE_RANGE': displayName = 'Date Range';
                break;
            case 'EMAIL': displayName = 'Email';
                break;
            case 'FREE_TEXT': displayName = 'Free Text';
                break;
            case 'LIKERT': displayName = 'Likert';
                break;
            case 'ADDRESS_FULL': displayName = 'Address';
                break;
            case 'CURRENCY': displayName = 'Currency';
                break;
            case 'ZIPPOSTAL_ONLY': displayName = 'ZIP';
                break;
            case 'STATE_ONLY': displayName = 'STATE';
                break;
            default: displayName = '';
        }
        return displayName;
    }


    formatMenuItem = (row, index) => {
        let item = "";

        let name = row.name;
        if (row.name.length > 90) {
            name = row.name.substring(0,90).trim() + "..." ;
        }
        switch(this.props.mode) {
            case TEMPLATE.TYPE.CATEGORY:
                item = (<MenuItem key={row.templateKey} value={row.templateKey}>
                    <ListItemText style={{color: "black", width: "36%"}}>{name}</ListItemText>
                    <ListItemText style={{width: '2%'}}>&nbsp;</ListItemText>
                    <ListItemText style={{color: "black", width: "20%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{row.nameQualifier}</ListItemText>
                    <ListItemText style={{width: '2%'}}>&nbsp;</ListItemText>
                    <ListItemText style={{color: "black", width: "20%"}}>{toLocale(row.lastModifiedDate)}</ListItemText>
                </MenuItem>);
                break;
            case TEMPLATE.TYPE.GROUP:
            case TEMPLATE.TYPE.QUESTION:
                item = (<MenuItem key={row.templateKey} value={row.templateKey}>
                    <ListItemText sx={{color: "black", width: "36%"}}>{name}</ListItemText>
                    <ListItemText sx={{width: '2%'}}>&nbsp;</ListItemText>
                    <ListItemText sx={{color: "black", width: "20%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{row.nameQualifier}</ListItemText>
                    <ListItemText sx={{width: '2%'}}>&nbsp;</ListItemText>
                    <ListItemText sx={{color: "black", width: "20%"}}>{this.props.mode === TEMPLATE.TYPE.QUESTION ? this.convertToDisplayText(row.questionType) : this.convertToDisplayText(row.questionGroupType)}</ListItemText>
                    <ListItemText sx={{width: '2%'}}>&nbsp;</ListItemText>
                    <ListItemText sx={{color: "black", width: "20%"}}>{toLocale(row.lastModifiedDate)}</ListItemText>
                </MenuItem>);
                break;
            default:
                break;
        }

        return item;
    };

    renderSelectedTags = (values) => {

        switch (values.length) {
            case 0:
                return "";
            case 1:
                let tagName = "";
                for (let x = 0; x < this.props.filterTags.list.length; x++) {
                    if (this.props.filterTags.list[x].id === values[0]) {
                        tagName = this.props.filterTags.list[x].name;
                        break;
                    }
                }
                return tagName;
            default:
                return values.length + " tags selected";
        }
    };

    renderSelectedTemplate = (values) => {

        let selectedTemplateDisplay = "";

        for (let x = 0; x < this.props.filter.list.length; x++) {
            if (this.props.filter.list[x].templateKey === values) {
                let row = this.props.filter.list[x];
                selectedTemplateDisplay = (<MenuItem key={row.templateKey} value={row.templateKey}>{
                    <div style={{display: "flex"}}>
                        <div style={{color: "black"}}>{row.name}</div>
                        <div style={{width: "15px"}}>&nbsp;</div>
                        {row.nameQualifier === null ? "" : <div style={{color: "gainsboro", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{"Qualifier: "}</div>}
                        {row.nameQualifier === null ? "" : <div style={{width: "6px"}}>&nbsp;</div>}
                        {row.nameQualifier === null ? "" : <div style={{color: "gray", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{row.nameQualifier}</div>}
                        {this.props.mode === TEMPLATE.TYPE.CATEGORY ? "" : <div style={{width: "15px"}}>&nbsp;</div>}
                        {this.props.mode === TEMPLATE.TYPE.CATEGORY ? "" : <div style={{color: "gainsboro"}}>{"Type: "}</div>}
                        {this.props.mode === TEMPLATE.TYPE.CATEGORY ? "" : <div style={{width: "6px"}}>&nbsp;</div>}
                        {this.props.mode === TEMPLATE.TYPE.CATEGORY ? "" : <div style={{color: "gray"}}>{this.props.mode === TEMPLATE.TYPE.QUESTION ? row.questionType : row.questionGroupType}</div>}
                        <div style={{width: "15px"}}>&nbsp;</div>
                        <div style={{color: "gainsboro"}}>{"Last Modified: "}</div>
                        <div style={{width: "6px"}}>&nbsp;</div>
                        <div style={{color: "gray"}}>{toLocale(row.lastModifiedDate)}</div>
                    </div>}</MenuItem>);


                //row.name + "  (" + row.nameQualifier + ") (" + row.questionType + ") (" + toLocale(row.lastModifiedDate) + ")";
                break;
            }
        }

        return selectedTemplateDisplay;
    };


    render() {

        if (this.props.useTypeFilter) {
            if ((this.props.likert && this.props.filter.type === "!LIKERT_RADIO") || (!this.props.likert && this.props.filter.type === "LIKERT_RADIO")) {
                this.updateFilter();
            }
        }

        let componentTitle = "";
        let selectHoverHelp = "";
        let floatingTextLabel = "";

        switch(this.props.mode) {
            case "category":
                componentTitle = "Select a category:";
                selectHoverHelp = "Format: Title, Qualifier, Last Modified Date";
                floatingTextLabel = "Selected category";
                break;
            case "group":
                let xxxd = this.state.selectedTemplateKey;
                // debugger;
                componentTitle = "Select a group:";
                selectHoverHelp = "Format: Title, Qualifier, Type, Last Modified Date";
                floatingTextLabel = "Selected group";
                break;
            case "question":
                componentTitle = "Select a " + (this.props.likert ? "likert " : "") + this.props.mode + ":";
                selectHoverHelp = "Format: Title, Qualifier, Type, Last Modified Date";
                floatingTextLabel = "Selected question";
                break;
            default:
                break;
        }

        return (
            <div id="ArgoSelectExisting-page-container-div" style={{width: "100%"}}>
                {/*<div id="ArgoSelectExisting-container-div" style={{width: "100%", height: "30px"}}></div>*/}

                <Card id="ArgoSelectExisting-container-card"style={{width: "100%", border: "1px solid", borderColor: "black", boxShadow: "3px 5px lightgray", paddingRight: this.props.paddingRight}}>
                    <CardContent id="ArgoSelectExisting-container-card-content" style={{width: "98%"}}>

                        <div id="ArgoSelectExisting-container-card-content-container-div"style={{width: "100%"}}>

                            <ArgoListTitleAndLoadingAnimationHook
                                fileName="ArgoSelectExisting"
                                listTitle={componentTitle}
                                loading={this.state.loading}
                                filter={this.state.filter}
                                showOutstandingAlert={false}/>

                            {/*<div style={{display: "flex", width: "100%"}}>*/}
                            {/*    <div style={{fontWeight: "bold"}}>{componentTitle}</div>*/}
                            {/*</div>*/}

                            <div style={{display: "flex", width: "100%"}}>

                                <ArgoTextFieldHook
                                    fileName="ArgoSelectExisting"
                                    fieldName="name"
                                    label={"Name"}
                                    width="200px"
                                    value={this.state.name}
                                    onChange={this.onTextChange}
                                />

                                <div style={{width: "20px"}}>&nbsp;</div>

                                <ArgoTextFieldHook
                                    fileName="ArgoSelectExisting"
                                    fieldName="nameQualifier"
                                    label={"Qualifier"}
                                    width="140px"
                                    value={this.state.nameQualifier}
                                    onChange={this.onTextChange}
                                />

                                <div style={{width: "20px"}}>&nbsp;</div>

                                <ArgoTextFieldHook
                                    fileName="ArgoSelectExisting"
                                    fieldName="updatedBy"
                                    label={"Updated by"}
                                    width="140px"
                                    value={this.state.updatedBy}
                                    onChange={this.onTextChange}
                                />

                                <div style={{width: "20px"}}>&nbsp;</div>

                                {/*--------------------------------------------------------------------------------*/}
                                {/*-- Only Questions use the Tags DDLB*/}
                                {/*--------------------------------------------------------------------------------*/}

                                <div style={{paddingBottom: "7px"}}>
                                    <ArgoMultipleSelectCheckmarksHook
                                        fileName="ArgoSelectExisting"
                                        fieldName="selectedTags"
                                        label={"Tags"}
                                        width={200}
                                        value={this.state.nameQualifier}
                                        valueProperty="id"
                                        textProperty="name"
                                        menuItems={this.props.filterTags.list}
                                        onChange={this.handleSelectTagsOnChange}
                                    />
                                </div>

                                {/*{(this.props.mode === TEMPLATE.TYPE.QUESTION) && <FormControl style={{width: "200px"}}>*/}
                                {/*    <InputLabel id="ArgoSelectExisting-input-tags-label">{"Tags"}</InputLabel>*/}
                                {/*    <Select*/}
                                {/*        labelId="ArgoSelectExisting-tags-labelId"*/}
                                {/*        id="ArgoSelectExisting-tags-id"*/}
                                {/*        variant="standard"*/}
                                {/*        multiple*/}
                                {/*        value={this.state.selectedTags}*/}
                                {/*        onChange={this.handleSelectTagsOnChange.bind(this)}*/}
                                {/*        renderValue={(selected) => selected.join(', ')}*/}
                                {/*    >*/}
                                {/*        {this.props.filterTags.list && this.props.filterTags.list.map((tag) => (*/}
                                {/*            <MenuItem key={tag.id} value={tag.id}>*/}
                                {/*                <Checkbox checked={this.state.selectedTags.indexOf(tag.name) > -1}/>*/}
                                {/*                <ListItemText primary={tag.name} />*/}
                                {/*            </MenuItem>*/}
                                {/*        ))}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>}*/}

                                <div style={{width: "20px"}}>&nbsp;</div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoSelectExisting"
                                    fieldName="sortBy"
                                    label="Sort by"
                                    value={this.state.sortBy}
                                    onChange={this.onSelectChange}
                                    fullWidth={false}
                                    width="200px"
                                    menuItems={[
                                        {value: "nameAsc", text: "Name (ascending)"},
                                        {value: "nameDesc", text: "Name (descending)"},
                                        {value: "lastModifiedDateAsc", text: "Last updated (ascending)"},
                                        {value: "lastModifiedDateDesc", text: "Last updated (descending)"}
                                    ]}
                                />

                                <div style={{width: "20px"}}>&nbsp;</div>

                                <div id="ArgoSelectExisting-ButtonGroup-container-div" style={{display: "flex", marginLeft:"auto", paddingTop: "8px"}}>

                                    <ButtonGroup id="ArgoSelectExisting-ButtonGroup" variant="outlined" style={{display: "flex", marginLeft: "auto"}}>

                                        <div style={{marginLeft: "auto"}}>
                                            <ArgoButtonHook
                                                fileName="ArgoSelectExisting"
                                                fieldName="filter"
                                                variant="text"
                                                label="Filter"
                                                paddingTop="5px"
                                                width={140}
                                                onClick={this.filter.bind(this)}
                                            />
                                        </div>

                                        <div>&nbsp;</div>

                                        <div style={{display: "flex", marginLeft: "auto"}}>
                                            <ArgoButtonHook
                                                fileName="ArgoSelectExisting"
                                                fieldName="reset"
                                                variant="text"
                                                label="Reset"
                                                width={140}
                                                paddingTop="5px"
                                                onClick={this.resetFilter.bind(this)}
                                            />
                                        </div>

                                        <div>&nbsp;</div>

                                        <div style={{display: "flex", marginLeft: "auto"}}>
                                            <ArgoButtonHook
                                                fileName="ArgoSelectExisting"
                                                fieldName="add-selected"
                                                label="Add Selected"
                                                width={140}
                                                onClick={this.addTemplate.bind(this)}
                                                paddingTop="5px"
                                                disabled={this.state.selectedTemplateKey === ""}
                                            />
                                        </div>
                                    </ButtonGroup>
                                </div>
                            </div>

                            {/*--------------------------------------------------------------------------------*/}
                            {/*-- DDLB Select template.. Categories, Groups or Questions
                                    {/*--------------------------------------------------------------------------------*/}

                            <div id="ArgoSelectExisting-container-template-select"  title={selectHoverHelp} style={{width: '100%'}}>
                                <FormControl fullWidth>
                                    <InputLabel id="ArgoSelectExisting-template-input-label" sx={{paddingLeft: "-15px"}}>{floatingTextLabel}</InputLabel>
                                    <Select
                                        labelId="ArgoSelectExisting-template-labelId"
                                        id="ArgoSelectExisting-template"
                                        variant="standard"
                                        value={this.state.selectedTemplateKey !== undefined ? this.state.selectedTemplateKey : ""}
                                        onChange={this.handleSelectTemplateOnChange.bind(this)}
                                        renderValue={this.renderSelectedTemplate.bind(this)}
                                    >
                                        {this.props.filter.list && this.props.filter.list.map((row, index) =>
                                            this.formatMenuItem(row, index)
                                        )}
                                    </Select>
                                </FormControl>

                                <div>&nbsp;</div>

                            </div>

                            {/*--------------------------------------------------------------------------------*/}
                            {/*-- Paging controls, Page Size Hidden, always 10 item rows
                                    {/*--------------------------------------------------------------------------------*/}

                            <ArgoListPagingControlsHook
                                hidePageSize={true}
                                pageState={this.props.filter}
                                onPageChange={this.onPageChange}
                            />
                        </div>

                    </CardContent>
                </Card>
            </div>
        );
    }
}

ArgoSelectExisting.defaultProps = {
    mode: TEMPLATE.TYPE.QUESTION,
    likert: false,
    useTypeFilter: false,
    paddingRight: "30px",
};

ArgoSelectExisting.propTypes = {
    mode: PropTypes.string,
    likert: PropTypes.bool,
    useTypeFilter: PropTypes.bool,
    onAddTemplate: PropTypes.func.isRequired,
    paddingRight: PropTypes.string
};

function mapStateToProps(state, props) {

    let filterType = "";

    switch(props.mode) {
        case TEMPLATE.TYPE.CATEGORY:
            filterType = TEMPLATE.TYPE.SELECT_EXISTING_CATEGORY;
            break;
        case TEMPLATE.TYPE.GROUP:
            filterType = TEMPLATE.TYPE.SELECT_EXISTING_GROUP;
            break;
        case TEMPLATE.TYPE.QUESTION:
            filterType = TEMPLATE.TYPE.SELECT_EXISTING_QUESTION;
            break;
        default:
            break;
    }

    return {
        filterType: filterType,
        filter: state.filter[TEMPLATE.FILTER.current[filterType]] || {},
        filterTags: state.filter[TEMPLATE.FILTER.all.selectExistingTags] || {}
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getTagDDLB,
        setFilter,
        updatePageAndGetList
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArgoSelectExisting);
