import * as types from "../constants/actionConstants";

const initialState = [];

function tagListReducer(state = initialState, action) {
    switch (action.type) {
        case types.TAG_LIST_GET_SUCCESS:
            return action.list;

        default:
            return state;
    }
}

export default tagListReducer;
