import * as types from "../constants/actionConstants";

const initialState = {
    message: "",
};

export default function portalResponseReducer(state = initialState, action) {
    switch (action.type) {

        case types.TOKEN_CLEAR:
        case types.TOKEN_REJECT_KEY:
            return {
                message: action.message,
            };

        default:
            return state;
    }
}

