import React from 'react';
import PropTypes from "prop-types";
//import ArrowBackIcon from "material-ui/svg-icons/navigation/arrow-back";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';

class HelpDrawer extends React.Component {

    closeDrawer = () => {
        this.props.closeDrawer();
    };

    render() {
        return (
            <div style={{height: "100%"}}>
                <ArgoAppBar
                    title={"Help"}
                    showMenuArrowButton="LEFT"
                    onLeftIconButtonTouchTap={this.closeDrawer}
                    isDrawer={true}
                    width={helpProps.width}
                />

                {/*<AppBar id="app-bar-help-drawer"*/}
                {/*        onLeftIconButtonTouchTap={this.cleanClose}*/}
                {/*        title={"Help"}*/}
                {/*        iconElementLeft={<IconButton id="help-drawer-arrow-back"><ArrowBackIcon /></IconButton>}*/}
                {/*/>*/}
                <ClickAwayListener onClickAway={this.closeDrawer}>
                    <Card expanded style={{height: "100%"}}>
                        <CardContent expandable style={{paddingTop: 0, height: "100%"}}>
                            {<iframe title="help" ref="iframe" style={{width: "100%", height: "100%"}}
                                     src={this.props.src} frameBorder="0"/>}
                        </CardContent>
                    </Card>
                </ClickAwayListener>
            </div>
        );
    }
}

HelpDrawer.propTypes = {
    closeDrawer: PropTypes.func,
    src: PropTypes.string
};

const helpProps = {
    openSecondary: true,
    width: "60%",
    docked: false,
    anchor: "right"
};

export default HelpDrawer;
export {helpProps};




































//
// import React from 'react';
// import PropTypes from "prop-types";
// import Drawer from '@mui/material/Drawer';
// import ArgoAppBar from "../common/ArgoAppBar";
//
//
// class HelpDrawer extends React.Component {
//     constructor(props) {
//         super(props);
//     }
//
//     closeDrawer = () => {
//         this.props.closeDrawer();
//     };
//
//     render() {
//
//         return (
//             <div>
//                 <Drawer
//                     variant="temporary"
//                     open={this.props.open}
//                     anchor="right"
//                     PaperProps={{
//                         sx: { width: this.props.width, backgroundColor: "white" }
//                     }}
//                 >
//                     <ArgoAppBar title={"Help"} showMenuArrowButton="LEFT" onLeftIconButtonTouchTap={this.closeDrawer} isDrawer={true}/>
//
//                     <div style={{position: "fixed", top: "75px", width: "100%", height: "100%", paddingLeft: "15px"}}>
//                         <iframe id="argo-help-iframe"
//                                 nameid="argo-help-iframe"
//                                 title="help"
//                                 width="100%"
//                                 height="100%"
//                                 src={this.props.src}
//                                 style={{border: "none"}}
//                         />
//                     </div>
//
//                 </Drawer>
//             </div>
//         );
//     }
// }
//
//
// HelpDrawer.defaultProps = {
//     opensecondary: true,
//     width: "60%"
// };
//
// HelpDrawer.propTypes = {
//     opensecondary: PropTypes.bool,
//     width: PropTypes.string,
//     closeDrawer: PropTypes.func,
//     src: PropTypes.string
// };
//
// export default HelpDrawer;
//
