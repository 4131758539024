import * as types from "../constants/actionConstants";

const initialState = {};

function questionnaireTemplateReducer(state = initialState, action) {
    switch (action.type) {
        case types.QUESTIONNAIRE_TEMPLATE_GET_SUCCESS:
        case types.QUESTIONNAIRE_TEMPLATE_CREATE_SUCCESS:
        case types.QUESTIONNAIRE_TEMPLATE_UPDATE_SUCCESS:
        case types.QUESTIONNAIRE_TEMPLATE_CLONE_SUCCESS:
            return {
                ...state,
                [action.questionnaireTemplate.templateKey]: {
                    ...action.questionnaireTemplate
                }
            };

        case types.QUESTIONNAIRE_TEMPLATE_CLEAR:
            return {
                ...initialState
            };

        default:
            return state;
    }
}

export default questionnaireTemplateReducer;
