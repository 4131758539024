import * as types from "../constants/actionConstants";

const initialState = {
    open: false,
    view: null,
    props: {
        opensecondary: true
    },
    template: {},
    templateKey: "",
    templateId: null
};

export default function openCloseSecondaryDrawerReducer(state = initialState, action) {

    switch (action.type) {
        case types.OPEN_DRAWER:
            return {
                ...state,
                open: true,
                props: action.props,
                view: action.view,
                template: action.template,
                templateKey: action.templateKey,
                templateId: action.templateId
            };
        case types.CLOSE_DRAWER:
            return {...initialState};

        default:
            return state;
    }
}
