import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Colors from "@mui/material/colors";
import {
    addAnswer,
    updateAnswer,
    removeAnswer
} from "../../actions/answerEditorActions";
import AnswerEditor from "../qd/answers/AnswerEditor";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import ArgoButtonHook from "./ArgoButtonHook";
import ArgoTextFieldHook from "./ArgoTextFieldHook";
import ArgoCheckboxHook from "./ArgoCheckboxHook";
import * as portal from "../../utilities/portal";
import * as validate from "../../utilities/validate";
import * as icon from "../../constants/iconConstants";
import * as answerTypes from "../../constants/answerTypeConstants";
import * as color from "../../constants/colorConstants";
import {RESPONSIVE_LABELS_YES_TEXT} from "../../constants/iconConstants";


class ArgoLikertAnswerGenerator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answersError: null,
            toolbarReviewButtonDisabled: false,
            metadataErrorText: null,
            likertErrorText: null,
            descDisplayMetadataValue: "",
            descDisplayMetadataValueObj: null,
            likertAddAnswerTemplate: "2",
            likertAddAnswerTemplateFormat: "radios",
            likertMode: icon.LIKERT_ICON_MODE_DEFAULT,
            likertAnswerTextDisplay: icon.LIKERT_ICON_TITLES_ALL,
            likertResponsiveLabels: icon.LIKERT_ICON_LABELS_NO_TEXT,
            alignAnswersRight: false,
            alignQuestionsAndAnswers: false,
            likertTitles: "None",
            likertTitleLeft: "",
            likertTitleCenter: "",
            likertTitleRight: ""
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.template) {
            this.pageInit(nextProps);
        }
    }

    componentWillUnmount() {

    }

    setStateCallback = (stateObj) => {
        this.setState(stateObj);
        // , this.checkForErrorsOnPage(this.props)
    }

    pageInit = (currProps) => {

        let tempLikertTitles = "None";
        let tempLikertTitleLeft = "";
        let tempLikertTitleCenter = "";
        let tempLikertTitleRight = "";
        let tempAlignAnswersRight = false;
        let tempAlignQuestionsAndAnswers = false;

        if (currProps.template.metadata.hasOwnProperty(portal.USE_LIKERT_GRAYBAR_HEADER)) {
            tempLikertTitles = "Graybar"
        }
        if (currProps.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_LEFT)) {
            tempLikertTitleLeft = currProps.template.metadata[portal.DISPLAY_DEF_LIKERT_TITLE_LEFT];
            if ( tempLikertTitles !== "Graybar") {
                tempLikertTitles = "Standard";
            }
        }
        if (currProps.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_CENTER)) {
            tempLikertTitleCenter = currProps.template.metadata[portal.DISPLAY_DEF_LIKERT_TITLE_CENTER];
            if ( tempLikertTitles !== "Graybar") {
                tempLikertTitles = "Standard";
            }
        }
        if (currProps.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_RIGHT)) {
            tempLikertTitleRight = currProps.template.metadata[portal.DISPLAY_DEF_LIKERT_TITLE_RIGHT];
            if ( tempLikertTitles !== "Graybar") {
                tempLikertTitles = "Standard";
            }
        }
        if (currProps.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT)) {
            tempAlignAnswersRight = true;
        }
        if (currProps.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_QUESTION_ALIGN_WITH_ANSWERS)) {
            tempAlignQuestionsAndAnswers = true;
        }

        this.setState({
            likertTitles: tempLikertTitles,
            likertTitleLeft: tempLikertTitleLeft,
            likertTitleCenter: tempLikertTitleCenter,
            likertTitleRight: tempLikertTitleRight,
            alignAnswersRight: tempAlignAnswersRight,
            alignQuestionsAndAnswers: tempAlignQuestionsAndAnswers,
        });
    };


    handleMetadataChange = (metadata) => {
        let updatedTemplate = {...this.props.template};
        updatedTemplate.metadata = metadata;
        this.props.templateUpdateFunction(updatedTemplate);
    };


    checkForErrorsOnScreen = (props) => {

        const isValidating = props.options.isValidating;
        let errorText = {};

        let answersInvalid = false;
        let answersRequired = false;

        // props.options.validate.forEach(template => {
        //     if (template.name.toUpperCase() === RemoveExtraWhitespace(name).toUpperCase() && template.templateKey.toUpperCase() !== templateKey.toUpperCase()) {
        //         nameExists = true;
        //     }
        //     if (template.templateKey.toUpperCase() === templateKey.toUpperCase() && props.options.isEditCreate) {
        //         keyExists = true;
        //     }
        // });



        // Validate answers only if this is a Likert Group
        if (this.props.isLikert && !this.validateAnswers(props.answerOptionTemplates)) {
            errorText["answersError"] = "Answer name is required.";
            answersInvalid = true;
        }


        if (this.props.isLikert && props.answerOptionTemplates.length === 0) {
            errorText["likertErrorText"] = "At least one answer is required.";
            answersRequired = true;
        }
        else {
            errorText["likertErrorText"] = " ";
        }

        let metadataValid = validate.metadata(props.template.metadata, errorText);

        //if (Object.keys(errorText).length > 0 || this.state.toolbarReviewButtonDisabled) {
        //    errorText.disabled = !(nameIsValid && (!templateKey || templateKeyIsValid) && !nameExists && !keyExists
        //        && !descriptionInvalid && !answersInvalid && !answersRequired && !isValidating && metadataValid);
        //    this.setState(errorText);
        //}
    };


    onButtonClickAddLikertAnswers = (e) => {
debugger;
        if (this.state.likertAddAnswerTemplateFormat === "squares") {
            this.setMetadata(portal.DISPLAY_DEF_RADIO_AS_BUTTON_GROUP, "");
        }

        if (this.state.likertAddAnswerTemplateFormat === "circles") {
            this.setMetadata(portal.DISPLAY_DEF_BUTTON_AS_CIRCLE, "");
        }

        if (this.state.likertAddAnswerTemplateFormat === "circles" && this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS) {
            this.setMetadata(portal.DISPLAY_DEF_BUTTON_AS_CIRCLE_COLORS, "");
        }

        if (this.state.likertAddAnswerTemplateFormat === "circles" && this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS_ALT) {
            this.setMetadata(portal.DISPLAY_DEF_BUTTON_AS_CIRCLE_COLORS_ALT, "");
        }


        let iconType = "";
        //let iconMode = ""; // Fill, No Title
        let iconAnswerTextDisplay = "";
        let iconResponsiveLabel = "";
        let answerOptionText = "";
        //let iconColor = "";

        let answersToAdd = parseInt(this.state.likertAddAnswerTemplate, 10);
        for (let i = 0; i < answersToAdd; i++) {

            switch (this.state.likertAddAnswerTemplateFormat) {
                case "radios":

                    if (this.state.likertResponsiveLabels === icon.RESPONSIVE_LABELS_YES_TEXT) {
                        this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+(answersToAdd === 11 ? 0 : 1)).toString(), index: i, metadata: {[icon.ICON_POSITION]: iconAnswerTextDisplay, [icon.ICON_TYPE]: icon.TYPE_RADIO, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [portal.USE_LIKERT_RESPONSIVE_LABEL]: icon.getLabel(answersToAdd, i)}}, this.props.questionDepth);
                    }
                    else {
                        this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+(answersToAdd === 11 ? 0 : 1)).toString(), index: i, metadata: {[icon.ICON_POSITION]: iconAnswerTextDisplay, [icon.ICON_TYPE]: icon.TYPE_RADIO, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay}}, this.props.questionDepth);
                    }

                    break;
                case "thumbs":

                    iconAnswerTextDisplay = (this.state.likertAnswerTextDisplay === icon.LIKERT_ICON_TITLES_NONE) ? icon.POSITION_ICON_NO_TITLE : icon.POSITION_ICON;

                    switch (i) {
                        case 0:
                            iconType = icon.TYPE_THUMBS_UP;
                            this.props.addAnswer(this.props.groupTemplateMode, {optionText: "Yes", index: i, metadata: {[icon.ICON_POSITION]: iconAnswerTextDisplay, [icon.ICON_TYPE]: iconType, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay}});
                            break;
                        case 1:
                            iconType = icon.TYPE_THUMBS_DOWN;
                            this.props.addAnswer(this.props.groupTemplateMode, {optionText: "No", index: i, metadata: {[icon.ICON_POSITION]: iconAnswerTextDisplay, [icon.ICON_TYPE]: iconType, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay}});
                            break;
                        // no default
                    }
                    break;
                case "stars":

                    switch (this.state.likertAnswerTextDisplay) {
                        case icon.LIKERT_ICON_TITLES_ALL:
                            iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL : icon.POSITION_ICON;
                            break;
                        case icon.LIKERT_ICON_TITLES_LR:
                            if (i === 0 || i === (answersToAdd - 1)) {
                                iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL : icon.POSITION_ICON;
                            } else {
                                iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL_NO_TITLE : icon.POSITION_ICON_NO_TITLE;
                            }
                            break;
                        case icon.LIKERT_ICON_TITLES_LCR:
                            if (i === 0 || i === ((answersToAdd - 1)/2) || i === (answersToAdd - 1)) {
                                iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL : icon.POSITION_ICON;
                            } else {
                                iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL_NO_TITLE : icon.POSITION_ICON_NO_TITLE;
                            }
                            break;
                        case icon.LIKERT_ICON_TITLES_NONE:
                            iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL_NO_TITLE : icon.POSITION_ICON_NO_TITLE;
                            break;
                        // no default
                    }

                    if (this.state.likertResponsiveLabels === icon.RESPONSIVE_LABELS_YES_TEXT) {
                        this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+1).toString(), index: i, metadata: {[icon.ICON_POSITION]: iconAnswerTextDisplay, [icon.ICON_TYPE]: icon.TYPE_STAR, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT, [portal.USE_LIKERT_RESPONSIVE_LABEL]: icon.getLabel(answersToAdd, i)}});
                    }
                    else {
                        this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+1).toString(), index: i, metadata: {[icon.ICON_POSITION]: iconAnswerTextDisplay, [icon.ICON_TYPE]: icon.TYPE_STAR, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT}});
                    }
                    break;
                case "facesA":
                case "facesD":

                    if (this.state.likertAddAnswerTemplateFormat === "facesA") {

                        switch (i) {
                            case 0:
                                iconType = icon.TYPE_SENTIMENT_VERY_DISSATISFIED;
                                iconResponsiveLabel = icon.getLabel(answersToAdd, i);
                                break;
                            case 1:
                                iconType = (answersToAdd === 3) ? icon.TYPE_SENTIMENT_NEUTRAL : icon.TYPE_SENTIMENT_DISSATISFIED;
                                iconResponsiveLabel = icon.getLabel(answersToAdd, i);
                                break;
                            case 2:
                                iconType = (answersToAdd === 3) ? icon.TYPE_SENTIMENT_VERY_SATISFIED : icon.TYPE_SENTIMENT_NEUTRAL;
                                iconResponsiveLabel = icon.getLabel(answersToAdd, i);
                                break;
                            case 3:
                                iconType = icon.TYPE_SENTIMENT_SATISFIED;
                                iconResponsiveLabel = icon.getLabel(answersToAdd, i);
                                break;
                            case 4:
                                iconType = icon.TYPE_SENTIMENT_VERY_SATISFIED;
                                iconResponsiveLabel = icon.getLabel(answersToAdd, i);
                                break;
                            // no default
                        }

                    } else {

                        switch (i) {
                            case 0:
                                iconType = icon.TYPE_SENTIMENT_VERY_SATISFIED;
                                iconResponsiveLabel = icon.getLabel(5, (answersToAdd === 3) ? 3 : 4);
                                break;
                            case 1:
                                iconType = (answersToAdd === 3) ? icon.TYPE_SENTIMENT_NEUTRAL : icon.TYPE_SENTIMENT_SATISFIED;
                                iconResponsiveLabel = icon.getLabel(5, (answersToAdd === 3) ? 2 : 3);
                                break;
                            case 2:
                                iconType = (answersToAdd === 3) ? icon.TYPE_SENTIMENT_VERY_DISSATISFIED : icon.TYPE_SENTIMENT_NEUTRAL;
                                iconResponsiveLabel = icon.getLabel(5, (answersToAdd === 3) ? 1 : 2);
                                break;
                            case 3:
                                iconType = icon.TYPE_SENTIMENT_DISSATISFIED;
                                iconResponsiveLabel = icon.getLabel(5, 1);
                                break;
                            case 4:
                                iconType = icon.TYPE_SENTIMENT_VERY_DISSATISFIED;
                                iconResponsiveLabel = icon.getLabel(5, 0);
                                break;
                            // no default
                        }

                    }

                    switch (this.state.likertAnswerTextDisplay) {
                        case icon.LIKERT_ICON_TITLES_ALL:
                            iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL : icon.POSITION_ICON;
                            break;
                        case icon.LIKERT_ICON_TITLES_LR:
                            if (i === 0 || i === (answersToAdd - 1)) {
                                iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL : icon.POSITION_ICON;
                            } else {
                                iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL_NO_TITLE : icon.POSITION_ICON_NO_TITLE;
                            }
                            break;
                        case icon.LIKERT_ICON_TITLES_LCR:
                            if (i === 0 || i === ((answersToAdd - 1)/2) || i === (answersToAdd - 1)) {
                                iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL : icon.POSITION_ICON;
                            } else {
                                iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL_NO_TITLE : icon.POSITION_ICON_NO_TITLE;
                            }
                            break;
                        case icon.LIKERT_ICON_TITLES_NONE:
                            iconAnswerTextDisplay = (this.state.likertMode === icon.LIKERT_ICON_MODE_FILL) ? icon.POSITION_ICON_FILL_NO_TITLE : icon.POSITION_ICON_NO_TITLE;
                            break;
                        // no default
                    }

                    if (this.state.likertResponsiveLabels === icon.RESPONSIVE_LABELS_YES_TEXT) {
                        this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+1).toString(), index: i, metadata: {[icon.ICON_POSITION]: iconAnswerTextDisplay, [icon.ICON_TYPE]: iconType, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT, [portal.USE_LIKERT_RESPONSIVE_LABEL]: iconResponsiveLabel}});
                    }
                    else {
                        this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+1).toString(), index: i, metadata: {[icon.ICON_POSITION]: iconAnswerTextDisplay, [icon.ICON_TYPE]: iconType, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT}});
                    }
                    break;
                case "squares":

                    // Squares have no titles by default.  To add titles is group level metadata for LCR.

                    if (this.state.likertResponsiveLabels === icon.RESPONSIVE_LABELS_YES_TEXT) {

                        if (this.state.likertAnswerTextDisplay === icon.LIKERT_ICON_TITLES_NONE) {
                            this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+(answersToAdd === 11 ? 0 : 1)), index: i, metadata: {[icon.ICON_POSITION]: icon.POSITION_ICON, [icon.ICON_TYPE]: icon.TYPE_SQUARE, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT, [portal.USE_LIKERT_RESPONSIVE_LABEL]: icon.getLabel(answersToAdd, i), [portal.DISPLAY_DEF_HIDE_BUTTONGROUP_NAME]: ""}});
                        } else {
                            this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+(answersToAdd === 11 ? 0 : 1)), index: i, metadata: {[icon.ICON_POSITION]: icon.POSITION_ICON, [icon.ICON_TYPE]: icon.TYPE_SQUARE, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT, [portal.USE_LIKERT_RESPONSIVE_LABEL]: icon.getLabel(answersToAdd, i)}});
                        }

                    }
                    else {

                        if (this.state.likertAnswerTextDisplay === icon.LIKERT_ICON_TITLES_NONE) {
                            this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+1).toString(), index: i, metadata: {[icon.ICON_POSITION]: icon.POSITION_ICON, [icon.ICON_TYPE]: icon.TYPE_SQUARE, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT, [portal.DISPLAY_DEF_HIDE_BUTTONGROUP_NAME]: ""}});
                        } else {
                            this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+1).toString(), index: i, metadata: {[icon.ICON_POSITION]: icon.POSITION_ICON, [icon.ICON_TYPE]: icon.TYPE_SQUARE, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT}});
                        }

                    }

                    // this.setMetadata([portal.DISPLAY_DEF_RADIO_AS_BUTTON_GROUP]], ""); will be called on recieve props
                    break;
                case "circlesN2P":
                case "circlesP2N":

                    if (answersToAdd === 7) {

                        switch (i) {
                            case 0:
                                iconType = (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? icon.TYPE_OUTLINED_DOUBLE_NEGATIVE : icon.TYPE_OUTLINED_DOUBLE_POSITIVE;
                                break;
                            case 1:
                                iconType = (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? icon.TYPE_OUTLINED_NEGATIVE : icon.TYPE_OUTLINED_POSITIVE;
                                break;
                            case 2:
                                iconType = icon.TYPE_OUTLINED_NEUTRAL;
                                break;
                            case 3:
                                iconType = icon.TYPE_OUTLINED_NEUTRAL;
                                break;
                            case 4:
                                iconType = icon.TYPE_OUTLINED_NEUTRAL;
                                break;
                            case 5:
                                iconType = (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? icon.TYPE_OUTLINED_POSITIVE : icon.TYPE_OUTLINED_NEGATIVE;
                                break;
                            case 6:
                                iconType = (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? icon.TYPE_OUTLINED_DOUBLE_POSITIVE : icon.TYPE_OUTLINED_DOUBLE_NEGATIVE;
                                break;
                            // no default
                        }

                    } else if (answersToAdd === 5) {

                        switch (i) {
                            case 0:
                                iconType = (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? icon.TYPE_OUTLINED_DOUBLE_NEGATIVE : icon.TYPE_OUTLINED_DOUBLE_POSITIVE;
                                break;
                            case 1:
                                iconType = (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? icon.TYPE_OUTLINED_NEGATIVE : icon.TYPE_OUTLINED_POSITIVE;
                                break;
                            case 2:
                                iconType = icon.TYPE_OUTLINED_NEUTRAL;
                                break;
                            case 3:
                                iconType = (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? icon.TYPE_OUTLINED_POSITIVE : icon.TYPE_OUTLINED_NEGATIVE;
                                break;
                            case 4:
                                iconType = (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? icon.TYPE_OUTLINED_DOUBLE_POSITIVE : icon.TYPE_OUTLINED_DOUBLE_NEGATIVE;
                                break;
                            // no default
                        }

                    } else {

                        switch (i) {
                            case 0:
                                iconType = (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? icon.TYPE_OUTLINED_NEGATIVE : icon.TYPE_OUTLINED_POSITIVE;
                                break;
                            case 1:
                                iconType = icon.TYPE_OUTLINED_NEUTRAL;
                                break;
                            case 2:
                                iconType = (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? icon.TYPE_OUTLINED_POSITIVE : icon.TYPE_OUTLINED_NEGATIVE;
                                break;
                            // no default
                        }

                    }

                    answerOptionText = icon.getLabel(answersToAdd, (this.state.likertAddAnswerTemplateFormat === "circlesN2P") ? i : (answersToAdd - (i+1)));

                    switch (this.state.likertAnswerTextDisplay) {
                        case icon.LIKERT_ICON_TITLES_ALL:
                            iconAnswerTextDisplay = icon.POSITION_ICON;
                            break;
                        case icon.LIKERT_ICON_TITLES_LR:
                            if (i === 0 || i === (answersToAdd - 1)) {
                                iconAnswerTextDisplay = icon.POSITION_ICON;
                            } else {
                                iconAnswerTextDisplay = icon.POSITION_ICON_NO_TITLE;
                            }
                            break;
                        case icon.LIKERT_ICON_TITLES_LCR:
                            if (i === 0 || i === ((answersToAdd - 1)/2) || i === (answersToAdd - 1)) {
                                iconAnswerTextDisplay = icon.POSITION_ICON;
                            } else {
                                iconAnswerTextDisplay = icon.POSITION_ICON_NO_TITLE;
                            }
                            break;
                        case icon.LIKERT_ICON_TITLES_NONE:
                            iconAnswerTextDisplay = icon.POSITION_ICON_NO_TITLE;
                            break;
                        // no default
                    }

                    if (this.state.likertResponsiveLabels === icon.RESPONSIVE_LABELS_YES_TEXT) {
                        this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+1).toString(), index: i, metadata: {[icon.ICON_POSITION]: iconAnswerTextDisplay, [icon.ICON_TYPE]: iconType, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT, [portal.USE_LIKERT_RESPONSIVE_LABEL]: answerOptionText}});
                    }
                    else {
                        this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+1).toString(), index: i, metadata: {[icon.ICON_POSITION]: iconAnswerTextDisplay, [icon.ICON_TYPE]: iconType, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT}});
                    }

                    break;
                case "circles":

                    // Circles have no titles by default.  To add titles is group level metadata for LCR.

                    if (answersToAdd === 11 && (this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS || this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS_ALT)) {

                        if (this.state.likertResponsiveLabels === icon.RESPONSIVE_LABELS_YES_TEXT)  {
                            this.props.addAnswer(this.props.groupTemplateMode, {optionText: i, index: i, metadata: {[icon.ICON_POSITION]: icon.POSITION_ICON, [icon.ICON_TYPE]: icon.TYPE_CIRCLE, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT, [portal.USE_LIKERT_RESPONSIVE_LABEL]: icon.getLabel(answersToAdd, i)}});
                        } else {
                            this.props.addAnswer(this.props.groupTemplateMode, {optionText: i, index: i, metadata: {[icon.ICON_POSITION]: icon.POSITION_ICON, [icon.ICON_TYPE]: icon.TYPE_CIRCLE, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT}});
                        }

                    } else {

                        if (this.state.likertResponsiveLabels === icon.RESPONSIVE_LABELS_YES_TEXT) {

                            if (this.state.likertAnswerTextDisplay === icon.LIKERT_ICON_TITLES_NONE) {
                                this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+(answersToAdd === 11 ? 0 : 1)).toString(), index: i, metadata: {[icon.ICON_POSITION]: icon.POSITION_ICON, [icon.ICON_TYPE]: icon.TYPE_CIRCLE, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT, [portal.USE_LIKERT_RESPONSIVE_LABEL]: icon.getLabel(answersToAdd, i), [portal.DISPLAY_DEF_HIDE_BUTTONGROUP_NAME]: ""}});
                            } else {
                                this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+(answersToAdd === 11 ? 0 : 1)).toString(), index: i, metadata: {[icon.ICON_POSITION]: icon.POSITION_ICON, [icon.ICON_TYPE]: icon.TYPE_CIRCLE, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT, [portal.USE_LIKERT_RESPONSIVE_LABEL]: icon.getLabel(answersToAdd, i)}});
                            }

                        }
                        else {

                            if (this.state.likertAnswerTextDisplay === icon.LIKERT_ICON_TITLES_NONE) {
                                this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+(answersToAdd === 11 ? 0 : 1)).toString(), index: i, metadata: {[icon.ICON_POSITION]: icon.POSITION_ICON, [icon.ICON_TYPE]: icon.TYPE_CIRCLE, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT, [portal.DISPLAY_DEF_HIDE_BUTTONGROUP_NAME]: ""}});
                            } else {
                                this.props.addAnswer(this.props.groupTemplateMode, {optionText: (i+(answersToAdd === 11 ? 0 : 1)).toString(), index: i, metadata: {[icon.ICON_POSITION]: icon.POSITION_ICON, [icon.ICON_TYPE]: icon.TYPE_CIRCLE, [icon.ICON_TITLES]: this.state.likertAnswerTextDisplay, [icon.ICON_COLOR]: color.COLOR_DEFAULT}});
                            }

                        }

                    }

                    break;
                // no default
            }

        }

    };


    onButtonClickRemoveLikertAnswers = (event, fieldName) => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_RADIO_AS_BUTTON_GROUP)) {
            delete tempMetadata[portal.DISPLAY_DEF_RADIO_AS_BUTTON_GROUP];
        }

        if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_BUTTON_AS_CIRCLE)) {
            delete tempMetadata[portal.DISPLAY_DEF_BUTTON_AS_CIRCLE];
        }

        if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_BUTTON_AS_CIRCLE_COLORS)) {
            delete tempMetadata[portal.DISPLAY_DEF_BUTTON_AS_CIRCLE_COLORS];
        }

        if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_BUTTON_AS_CIRCLE_COLORS_ALT)) {
            delete tempMetadata[portal.DISPLAY_DEF_BUTTON_AS_CIRCLE_COLORS_ALT];
        }

        // Answer alignments
        if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT)) {
            delete tempMetadata[portal.DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT];
        }
        if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_QUESTION_ALIGN_WITH_ANSWERS)) {
            delete tempMetadata[portal.DISPLAY_DEF_LIKERT_QUESTION_ALIGN_WITH_ANSWERS];
        }

        // Standard or Graybar Headers
        if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_SHOW_GROUP_STANDARD)) {
            delete tempMetadata[portal.DISPLAY_DEF_SHOW_GROUP_STANDARD];
        }
        if (this.props.template.metadata.hasOwnProperty(portal.USE_LIKERT_GRAYBAR_HEADER)) {
            delete tempMetadata[portal.USE_LIKERT_GRAYBAR_HEADER];
        }

        // Standard or Graybar Headers titles
        if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_LEFT)) {
            delete tempMetadata[portal.DISPLAY_DEF_LIKERT_TITLE_LEFT];
        }
        if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_CENTER)) {
            delete tempMetadata[portal.DISPLAY_DEF_LIKERT_TITLE_CENTER];
        }
        if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_RIGHT)) {
            delete tempMetadata[portal.DISPLAY_DEF_LIKERT_TITLE_RIGHT];
        }

        this.deleteMetadata(tempMetadata);

        this.setState(
            {
                alignAnswersRight: false,
                alignQuestionsAndAnswers: false,
                likertTitles: "None",
                likertTitleLeft: "",
                likertTitleCenter: "",
                likertTitleRight: ""
            }
        );

        let numOfAnswers = this.props.answerOptionTemplates.length - 1;

        for (let i = numOfAnswers; i >= 0; i--) {
            this.removeLikertAnswer(i);
        }
    };


    removeLikertAnswer = (index) => {
        this.props.removeAnswer(this.props.groupTemplateMode, index);
    };


    onSelectChange = (event, fieldName, menuItemText) => {

        let selected = event.target.value;

        let tempLikertMode = "";
        let tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_ALL;
        let tempLikertResponsiveLabels = icon.RESPONSIVE_LABELS_NO_TEXT;

        switch (fieldName) {
            case "likertAddAnswerTemplate":
                this.setState({[fieldName]: selected, likertAddAnswerTemplateFormat: "radios", likertMode: icon.LIKERT_ICON_MODE_DEFAULT, likertAnswerTextDisplay: icon.LIKERT_ICON_TITLES_ALL, likertResponsiveLabels: icon.RESPONSIVE_LABELS_NO_TEXT});
                break
            case "likertAddAnswerTemplateFormat":

                if (selected === "stars" || selected === "facesA" || selected === "facesD") {
                    tempLikertMode = icon.LIKERT_ICON_MODE_NO_FILL;
                } else {
                    tempLikertMode = icon.LIKERT_ICON_MODE_DEFAULT;
                }

                if (selected === "radios" || selected === "thumbs") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_ALL;

                } else if (selected === "stars") {

                    if (this.state.likertAddAnswerTemplate === "4" || this.state.likertAddAnswerTemplate === "6") {
                        tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_ALL;
                    } else {
                        tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_ALL;
                    }

                } else if (selected === "facesA" || selected === "facesD") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_ALL;

                } else if (selected === "circles" && this.state.likertAddAnswerTemplate === "11" && (this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS || this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS_ALT)) {

                    tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_NONE;

                } else if (selected === "circles" || selected === "squares") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_EMBEDDED;

                } else {
                    tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_NONE;
                }

                this.setState({[fieldName]: selected, likertMode: tempLikertMode, likertAnswerTextDisplay: tempLikertAnswerTextDisplay, likertResponsiveLabels: icon.RESPONSIVE_LABELS_NO_TEXT});
                break
            case "likertMode":

                if (this.state.likertAddAnswerTemplateFormat === "radios" || this.state.likertAddAnswerTemplateFormat === "thumbs") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_ALL;

                } else if (this.state.likertAddAnswerTemplateFormat === "stars") {

                    if (this.state.likertAddAnswerTemplate === "4" || this.state.likertAddAnswerTemplate === "6") {
                        tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_ALL;
                    } else {
                        tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_ALL;
                    }

                } else if (this.state.likertAddAnswerTemplateFormat === "facesA" || this.state.likertAddAnswerTemplateFormat === "facesD") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_ALL;

                } else if (this.state.likertAddAnswerTemplateFormat === "circles" && this.state.likertAddAnswerTemplate === "11" && (selected === icon.LIKERT_ICON_MODE_COLORS || selected === icon.LIKERT_ICON_MODE_COLORS_ALT)) {

                    tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_NONE;

                } else if (this.state.likertAddAnswerTemplateFormat === "circles" || this.state.likertAddAnswerTemplateFormat === "squares") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_EMBEDDED;

                } else {
                    tempLikertAnswerTextDisplay = icon.LIKERT_ICON_TITLES_NONE;
                }

                this.setState({[fieldName]: selected, likertAnswerTextDisplay: tempLikertAnswerTextDisplay, likertResponsiveLabels: icon.RESPONSIVE_LABELS_NO_TEXT});
                break
            case "likertAnswerTextDisplay":
                this.setState({[fieldName]: selected, likertResponsiveLabels: icon.RESPONSIVE_LABELS_NO_TEXT});
                break
            default:
                this.setState({[fieldName]: selected});
        }
    };

    validateAnswers = (answerOptionTemplates) => {
        for (let answerOptionTemplate of answerOptionTemplates) {
            // Every answer option must have a name, but cannot exceed 255 characters.
            if (answerOptionTemplate.optionText.length === 0 || answerOptionTemplate.optionText.length > 255) {
                // Fail validation
                return false;
            }
        }
        return true;
    };


    setMetadata = (metadataTag, metadataValue) => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        tempMetadata[metadataTag] = metadataValue;

        this.handleMetadataChange(tempMetadata);
    };

    deleteMetadata = (tempMetadata) => {
        this.handleMetadataChange(tempMetadata);
    };


    deleteSingleMetadata = (metadataTag, metadataValue) => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        if (tempMetadata.hasOwnProperty(metadataTag)) {
            delete tempMetadata[metadataTag];
        }

        this.handleMetadataChange(tempMetadata);
    };


    removeTitleMetadata = () => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        if (tempMetadata.hasOwnProperty(portal.DISPLAY_DEF_SHOW_GROUP_STANDARD)) {
            delete tempMetadata[portal.DISPLAY_DEF_SHOW_GROUP_STANDARD];
        }
        if (tempMetadata.hasOwnProperty(portal.USE_LIKERT_GRAYBAR_HEADER)) {
            delete tempMetadata[portal.USE_LIKERT_GRAYBAR_HEADER];
        }
        if (tempMetadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_LEFT)) {
            delete tempMetadata[portal.DISPLAY_DEF_LIKERT_TITLE_LEFT];
        }
        if (tempMetadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_CENTER)) {
            delete tempMetadata[portal.DISPLAY_DEF_LIKERT_TITLE_CENTER];
        }
        if (tempMetadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_RIGHT)) {
            delete tempMetadata[portal.DISPLAY_DEF_LIKERT_TITLE_RIGHT];
        }

        this.handleMetadataChange(tempMetadata);

        this.setState({likertTitleLeft: "", likertTitleCenter: "", likertTitleRight: ""});
    };


    onSelectChangeTitleType = (event, fieldName) => {

        let selected = event.target.value;

        if (selected === "none") {
            this.removeTitleMetadata();
        }
        if (selected === "standard") {
            if (this.props.template.metadata.hasOwnProperty(portal.USE_LIKERT_GRAYBAR_HEADER)) {
                this.deleteSingleMetadata(portal.USE_LIKERT_GRAYBAR_HEADER);
            }

            this.setState({[fieldName]: selected}, () => {this.setMetadata(portal.DISPLAY_DEF_SHOW_GROUP_STANDARD, "")});
        } else {
            this.setState({[fieldName]: selected});
        }

        if (selected === "graybar") {
            if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_SHOW_GROUP_STANDARD)) {
                this.deleteSingleMetadata(portal.DISPLAY_DEF_SHOW_GROUP_STANDARD);
            }

            this.setState({[fieldName]: selected}, () => {this.setMetadata(portal.USE_LIKERT_GRAYBAR_HEADER, "")});
        } else {
            this.setState({[fieldName]: selected});
        }

    };

    onChangeTitle = (event, fieldName) => {

        let value = event.target.value;

        if (value=== "") {
            switch (fieldName) {
                case "likertTitleLeft":
                    if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_LEFT)) {
                        this.deleteSingleMetadata(portal.DISPLAY_DEF_LIKERT_TITLE_LEFT);
                    }
                    break;
                case "likertTitleCenter":
                    if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_CENTER)) {
                        this.deleteSingleMetadata(portal.DISPLAY_DEF_LIKERT_TITLE_CENTER);
                    }
                    break;
                case "likertTitleRight":
                    if (this.props.template.metadata.hasOwnProperty(portal.DISPLAY_DEF_LIKERT_TITLE_RIGHT)) {
                        this.deleteSingleMetadata(portal.DISPLAY_DEF_LIKERT_TITLE_RIGHT);
                    }
                    break;
                // no default
            }
        } else {
            switch (fieldName) {
                case "likertTitleLeft":
                    this.setMetadata(portal.DISPLAY_DEF_LIKERT_TITLE_LEFT, value);
                    break;
                case "likertTitleCenter":
                    this.setMetadata(portal.DISPLAY_DEF_LIKERT_TITLE_CENTER, value);
                    break;
                case "likertTitleRight":
                    this.setMetadata(portal.DISPLAY_DEF_LIKERT_TITLE_RIGHT, value);
                    break;
                // no default
            }
        }

        this.setState({[fieldName]: value});

    };

    onCheckboxChange = (event, fieldName) => {

        var metadataTag = "";

        switch (fieldName) {
            case "alignAnswersRight":
                metadataTag = portal.DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT;
                break;
            case "alignQuestionsAndAnswers":
                metadataTag = portal.DISPLAY_DEF_LIKERT_QUESTION_ALIGN_WITH_ANSWERS;
                break;
            // no default
        }

        this.setState({[fieldName]: event.target.checked}, () => {this.setMetadata(metadataTag, "")});
    };

    answerOptionEditorLikert = () => {

        // if (this.props.answerOptionTemplates === undefined) {
        //     return(<div></div>);
        // }

        return (
            <div style={{width: "100%", minHeight: "min-content"}}>

                <AnswerEditor
                    groupTemplateMode={this.props.groupTemplateMode}
                    questionDepth={this.props.questionDepth}
                    disableRemoveIcon={true}
                    isLikertAnswer={true}
                    displayAsLikert={this.props.displayAsLikert}
                    templateKey={this.props.template.templateKey}
                    hasAnswers={this.props.answerOptionTemplates > 0}
                    template={this.props.template}
                    answerOptionTemplates={this.props.answerOptionTemplates}
                    answerEditorSetStateCallback={this.setStateCallback}
                    setStateCallback={this.props.setStateCallback}
                      // displayAsMulticolor={this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS || this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS_ALT}
                      //groupTemplateMode={this.props.groupTemplateMode}
                      // setAnswerMetadataErrorText={this.props.setMetadataErrorText}
                />

                {/* If there are answers then show the REMOVE ANSWERS button else show ADD ANSWERS component generator*/}

                {this.props.answerOptionTemplates.length > 0 ?
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: 'center'
                    }}>
                        <div style={{alignItems: "left", paddingTop: '14px'}}>

                            <ArgoButtonHook
                                fileName="ArgoLikertAnswerGenerator"
                                fieldName="remove-answers"
                                variant="contained"
                                label={this.props.answerOptionTemplates.length === 1 ? "Remove Answer" : "Remove Answers"}
                                paddingTop="0px"
                                width={140}
                                containedTextColor={'white'}
                                containedBgColor={Colors.blue[500]}
                                containedHoverTextColor={'#010203'}
                                containedHoverBgColor={Colors.blue[500]}
                                buttonPadding={'6px 5px'}
                                onClick={this.onButtonClickRemoveLikertAnswers}
                            />

                        </div>
                    </div>

                    :

                    <div id="argo-likert-components-container-div" style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: 'center'}}>

                        <ArgoButtonHook
                            fileName="ArgoLikertAnswerGenerator"
                            fieldName="add-answers"
                            variant="contained"
                            label={this.state.likertAddAnswerTemplate === "1" ? "Add Answer" : "Add Answers"}
                            paddingTop="0px"width={140}
                            containedTextColor={'white'}
                            containedBgColor={Colors.blue[500]}
                            containedHoverTextColor={'#010203'}
                            containedHoverBgColor={Colors.blue[500]}
                            onClick={this.onButtonClickAddLikertAnswers}
                        />

                        <div style={{alignItems: "left", width: "20px"}}></div>

                        <ArgoTextFieldSelectHook
                            fileName="ArgoLikertAnswerGenerator"
                            fieldName="likertAddAnswerTemplate"
                            label="Template"
                            value={this.state.likertAddAnswerTemplate}
                            onChange={this.onSelectChange}
                            width="175px"
                            menuItems={answerTypes.numberOfAnswersList}
                        />

                        <div style={{alignItems: "left", width: "20px"}}></div>

                        {/* ICON TYPES ALLOWED DEPEND ON THE NUMBER OF ANSWERS SELECTED */}

                        <ArgoTextFieldSelectHook
                            fileName="ArgoLikertAnswerGenerator"
                            fieldName="likertAddAnswerTemplateFormat"
                            label="Style"
                            value={this.state.likertAddAnswerTemplateFormat}
                            onChange={this.onSelectChange}
                            width="250px"
                            menuItems={answerTypes.buildAddAnswerTemplateMenuItemsArray(this.state.likertAddAnswerTemplate)}
                        />

                        <div style={{alignItems: "left", width: "20px"}}></div>

                        <ArgoTextFieldSelectHook
                            fileName="ArgoLikertAnswerGenerator"
                            fieldName="likertMode"
                            label="Mode"
                            value={this.state.likertMode}
                            onChange={this.onSelectChange}
                            width="250px"
                            valueProperty={"text"}
                            menuItems={answerTypes.getLikertModes(this.state.likertAddAnswerTemplateFormat, this.state.likertAddAnswerTemplate)}
                        />

                        <div style={{alignItems: "left", width: "20px"}}></div>

                        <ArgoTextFieldSelectHook
                            fileName="argo-likert-components"
                            fieldName="likertAnswerTextDisplay"
                            label="Answer Text Display"
                            value={this.state.likertAnswerTextDisplay}
                            onChange={this.onSelectChange}
                            width="240px"
                            valueProperty={"text"}
                            menuItems={answerTypes.getLikertAnswerTextDisplay(this.state.likertAddAnswerTemplateFormat, this.state.likertAddAnswerTemplate, this.state.likertMode)}
                        />

                        <div style={{alignItems: "left", width: "20px"}}></div>

                        <ArgoTextFieldSelectHook
                            fileName="argo-likert-components"
                            fieldName="likertResponsiveLabels"
                            label="Responsive Labels"
                            value={this.state.likertResponsiveLabels}
                            onChange={this.onSelectChange}
                            width="150px"
                            valueProperty={"text"}
                            menuItems={answerTypes.getLikertResponsiveLabels(this.state.likertAddAnswerTemplateFormat)}
                        />

                    </div>
                }
            </div>
        );

    };


    answerTitleEditorLikert = () => {

        return (
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: 'center', width: '100%'}}>

                <div style={{width: '20px'}}>&nbsp;</div>

                <div>
                    <ArgoCheckboxHook
                        fileName="ArgoLikertAnswerGenerator"
                        fieldName="alignAnswersRight"
                        label="Align answers right"
                        onChange={this.onCheckboxChange}
                        checked={this.state.alignAnswersRight}
                        width="250px"
                        paddingTop="20px"
                    />
                    <span className="notranslate">​</span>
                </div>

                <div style={{alignItems: "left", width: "20px"}}></div>

                {this.state.alignAnswersRight ?
                    <div>
                        <div>
                            <ArgoCheckboxHook
                                fileName="ArgoLikertAnswerGenerator"
                                fieldName="alignQuestionsAndAnswers"
                                label="Align questions with answers"
                                onChange={this.onCheckboxChange}
                                checked={this.state.alignQuestionsAndAnswers}
                                width="300px"
                                paddingTop="20px"
                            />
                            <span className="notranslate">​</span>
                        </div>

                        <div style={{alignItems: "left", width: "20px"}}></div>

                    </div> : ""
                }

                <ArgoTextFieldSelectHook
                    fileName="ArgoLikertAnswerGenerator"
                    fieldName="likertTitles"
                    label="Titles"
                    value={this.state.likertTitles}
                    onChange={this.onSelectChangeTitleType}
                    width="200px"
                    paddingBottom="4px"
                    menuItems={[
                        {value: "None", text: "None"},
                        {value: "Standard", text: "Standard"},
                        {value: "Graybar", text: "Graybar"}
                    ]}
                />

                {this.state.likertTitles !== "None" ?

                    <React.Fragment>
                        <div style={{width: '25px'}}>
                            &nbsp;
                        </div>

                        <div>
                            <ArgoTextFieldHook
                                fileName="ArgoLikertAnswerGenerator"
                                fieldName="likertTitleLeft"
                                label="Left"
                                value={this.state.likertTitleLeft}
                                onChange={this.onChangeTitle}
                                width="200px"
                                divContainerHeight="52px"
                            />
                            <span className="notranslate">​</span>
                        </div>

                        <div style={{width: '25px'}}>
                            &nbsp;
                        </div>

                        <div>
                            <ArgoTextFieldHook
                                fileName="ArgoLikertAnswerGenerator"
                                fieldName="likertTitleCenter"
                                label="Center"
                                value={this.state.likertTitleCenter}
                                onChange={this.onChangeTitle}
                                width="200px"
                                divContainerHeight="52px"
                            />
                            <span className="notranslate">​</span>
                        </div>

                        <div style={{width: '25px'}}>
                            &nbsp;
                        </div>

                        <div>
                            <ArgoTextFieldHook
                                fileName="ArgoLikertAnswerGenerator"
                                fieldName="likertTitleRight"
                                label="Right"
                                value={this.state.likertTitleRight}
                                onChange={this.onChangeTitle}
                                width="200px"
                                divContainerHeight="52px"
                            />
                            <span className="notranslate">​</span>
                        </div>

                    </React.Fragment> : ""}


                <div style={{height: '20px'}}>
                    &nbsp;
                </div>
            </div>
        );

    };


    render() {

        if (this.props.groupTemplateMode) {
            if (this.props.isLikert) {
                return (
                    <div>
                        <div>
                            {((this.props.answerOptionTemplates?.length > 0) ? this.answerTitleEditorLikert() : "")}
                        </div>

                        <div>
                            {this.answerOptionEditorLikert()}
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div>
                    {this.props.fileName === "QuestionEditor" ?
                        <div>
                            {this.answerOptionEditorLikert()}
                        </div>
                        :
                        <div>
                            {(this.props.isLikert ? this.answerTitleEditorLikert() : "")}
                        </div>
                    }
                </div>
            );
        }

    }
}

ArgoLikertAnswerGenerator.defaultProps = {
    groupTemplateMode:  false,
    isLikert: false,
    questionDepth: 1
};

ArgoLikertAnswerGenerator.propTypes = {
    fileName: PropTypes.string,
    groupTemplateMode:  PropTypes.bool,
    componentMode: PropTypes.string,
    template: PropTypes.object,
    templateUpdateFunction: PropTypes.func,
    displayAsLikert: PropTypes.bool,
    isLikert: PropTypes.bool,
    answerOptionTemplates: PropTypes.array,
    answerErrorCallback: PropTypes.func,
    questionDepth: PropTypes.number,
    setMetadataErrorText: PropTypes.func,
    setStateCallback: PropTypes.func
};

function mapStateToProps(state, props) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addAnswer,
        updateAnswer,
        removeAnswer
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArgoLikertAnswerGenerator);
