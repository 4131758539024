import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import GroupReview from "../qd/groups/GroupReview";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class GroupReviewDrawer extends Component {

    render() {

        return (
            <ClickAwayListener onClickAway={(event) => {
                // Checks is target is BODY first because portal preview DDLBs cause target to be body and we shouldn't close on that
                if (event.target.tagName !== 'BODY') this.props.closeDrawer()
            }}>
                <div id="GroupReviewDrawer-container-div" style={{height: "100%"}}>
                    <ArgoAppBar
                        title="Review Group"
                        showMenuArrowButton="LEFT"
                        onLeftIconButtonTouchTap={this.props.closeDrawer}
                        isDrawer={true}
                        width={groupReviewDrawerProps.width}
                    />

                    <div style={{height: '64px'}}></div>

                    <div id="GroupReviewDrawer-GroupReview-container-div">
                        <GroupReview
                            templateKey={this.props.templateKey}
                            templateId={this.props.templateId}
                            scrollTo="GroupReviewDrawer-GroupReview-container-div"
                            width={groupReviewDrawerProps.width}
                        />
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

GroupReviewDrawer.propTypes = {
    templateKey: PropTypes.string,
    templateId: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer}, dispatch);
}

const groupReviewDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(null, mapDispatchToProps)(GroupReviewDrawer);
export {groupReviewDrawerProps};
