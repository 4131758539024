import axios from 'axios';
import * as system from "../constants/systemConstants";
import {errorResponse} from "../utilities/errorResponse";
import store from "../store/store";
import {getToken} from "../actions/loginActions";
import {FormatPageableAndFilter} from "../utilities/PageableAndFilterUtilities";


export const axiosWrap = function(method, url, data = null, options = {}) {

    let config = {
        url: url,
        method: method,
        headers: {
            "Content-Type": "application/json;charset=UTF-8"
        },
        ...options
    };

    if (data) {
        config.data = data;
    }

    let authorizationToken = getToken();
    if (authorizationToken) {
        config.headers["Authorization"] = "Bearer " + authorizationToken;
    }

    return axios.request(config).catch((error) => {
        if (error){
            errorResponse(error, store.dispatch);
        }
    });
};

const GET = "get";
const PUT = "put";
const POST = "post";
const DELETE = "delete";

// converts UI column name to database equivalent
const columnNameMapper = {
    "Name": "name",
    "Qualifier": "nameQualifier",
    "Last updated": "lastModifiedDate",
    "Updated by": "lastModifiedBy",
    "Type": "questionType",
    "Start date": "startDate",
    "End date": "endDate",
    "Outstanding": "outstandingFlag"
};

const columnNameMapper2 = {
    "Name": "name",
    "Qualifier": "nameQualifier",
    "Last updated": "lastModifiedDate",
    "Updated by": "lastModifiedBy",
    "Type": "type",
    "Start date": "startDate",
    "End date": "endDate",
    "Outstanding": "outstandingFlag"
};

const templateApiMapper = {
    "questionnaire": "questionnaire-templates",
    "category": "question-category-templates",
    "group": "question-group-templates",
    "question": "question-templates",
    "action": "questionnaire-action-templates",
    "tag": "search-tags",
    "integration": "integrations",
    "integrationLogs": "integrations/logs",
};


class TemplateApi {
    static getAuthorizationToken(username, password, rememberMe) {
        let data = {
            username,
            password,
            rememberMe
        };

        data = JSON.stringify(data);

        return axiosWrap(POST, system.QD_API_AUTH_URL, data)
            .then((response) => {
                return response.data; //data is in the form of {"id_token": jfaslka}
            });
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Questionnaires !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
    // -----------------------------------------------------------------------------------------------------------------

    static getCurrentQuestionnaire(questionnaireKey) {
        return axiosWrap(GET,
            system.QD_API_URL +
            "questionnaire-templates/" +
            questionnaireKey +
            "/current")
            .then((response) => {
                return response.data;
            });
    }


    static getQuestionnaireById(id) {
        return axiosWrap(GET,
            system.QD_API_URL +
            "questionnaire-templates/" +
            id)
            .then((response) => {
                return response.data;
            });
    }


    static createQuestionnaireTemplate(questionnaireTemplate) {
        return axiosWrap(POST, system.QD_API_URL +
            "questionnaire-templates", questionnaireTemplate)
            .then((response) => {
                    return response.data;
                }
            );
    }


    static updateQuestionnaireTemplate(questionnaireTemplate) {
        return axiosWrap(PUT,
            system.QD_API_URL +
            "questionnaire-templates/" +
            questionnaireTemplate.templateKey, questionnaireTemplate)
            .then((response) => {
                return response.data;
            });
    }


    static cloneQuestionnaireTemplate(template) {
        return axiosWrap(PUT,
            system.QD_API_URL + "questionnaire-templates/" + template.id + "/clone", { "nameQualifier": template.nameQualifier, "notes": template.notes})
            .then((response) => {
                return response.data;
            });
    }


    static checkQuestionnaireActionsExists(filter) {
        let url = `${system.QD_API_URL}questionnaire-action-templates/exists?`;
        if (filter.templateKey) {
            url = url + `templateKey=${filter.templateKey}&`;
        }
        if (filter.name) {
            url = url + `exactName=${filter.name}&`;
        }

        return axiosWrap(GET, url)
            .then((response) => {
                return response.data;
            });
    }


    static checkQuestionnaireExists(filter) {
        let url = `${system.QD_API_URL}questionnaire-templates/exists?`;
        if (filter.templateKey) {
            url = url + `templateKey=${filter.templateKey}&`;
        }
        if (filter.name) {
            url = url + encodeURI(`exactName=${filter.name}&`);
        }

        return axiosWrap(GET, url)
            .then((response) => {
                return response.data;
            });
    }


    static checkQuestionnaireNameQualifierExists(nameQualifier, id) {
        let url = `${system.QD_API_URL}questionnaire-templates/${id}/clone/validate?nameQualifier=${nameQualifier}`;

        return axiosWrap(GET, url)
            .then((response) => {
                return response.data;
            });
    }


    static publishQuestionnaire(id, publishDTO) {
        return axiosWrap(PUT, system.QD_API_URL + `questionnaire-templates/${id}/publish`, publishDTO);
    }


    static retireQuestionnaire(id, retireDTO) {
        return axiosWrap(PUT, system.QD_API_URL + `questionnaire-templates/${id}/retire`, retireDTO);
    }


    static deleteQuestionnaire(id, deleteDTO) {
        return axiosWrap(DELETE, system.QD_API_URL + `questionnaire-templates/${id}`, deleteDTO);
    }


    static getQuestionnaireGraph(id) {
        return axiosWrap(GET, system.QD_API_URL + `questionnaire-templates/${id}/graph`)
            .then((response) => {
                return response.data;
            });
    }


    static changeQuestionnaireDates(id, changeDateDTO) {
        return axiosWrap(PUT, system.QD_API_URL + `questionnaire-templates/${id}/changeEffectiveDates`, changeDateDTO);
    }


    static getOutstandingQuestionnaireTemplateCount(){
        return axiosWrap(GET, system.QD_API_URL + `questionnaire-templates/count-outstanding`)
            .then((response) => {
                return response.data;
            });
    }


    static getQuestionnaireReference(jsonObj) {
        return axiosWrap(POST, system.QD_API_URL +
            "questionnaire-reference", jsonObj)
            .then((response) => {
                    return response.data;
                }
            );
    }

    static getMetadataRequiredTokens(templateID) {
        return axiosWrap(GET, system.QD_API_URL + `questionnaire-reference/configuredTokens/${templateID}`).then(response => {
            return response.data
        })
    }

    //</editor-fold>
    // -----------------------------------------------------------------------------------------------------------------


    // -----------------------------------------------------------------------------------------------------------------
    // Categories !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
    // -----------------------------------------------------------------------------------------------------------------


    // Get the current category for specified templatekey
    static getCurrentCategory(templatekey) {
        return axiosWrap(GET, system.QD_API_URL + "question-category-templates/" + templatekey + "/current")
            .then((response) => {
                return response.data;
            });
    }


    static getCategoryById(id) {
        return axiosWrap(GET,
            system.QD_API_URL +
            "question-category-templates/" +
            id)
            .then((response) => {
                return response.data;
            });
    }


    static createCategoryTemplate(categoryTemplate) {
        return axiosWrap(POST,system.QD_API_URL +
            "question-category-templates", categoryTemplate)
            .then((response) => {
                    return response.data;
                }
            );
    }


    static updateCategoryTemplate(categoryTemplate) {
        return axiosWrap(PUT,
            system.QD_API_URL +
            "question-category-templates/" +
            categoryTemplate.templateKey, categoryTemplate)
            .then((response) => {
                return response.data;
            });
    }



    //</editor-fold>
    // -----------------------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------------------
    // Groups !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
    // -----------------------------------------------------------------------------------------------------------------

    static getGroupById(id) {
        return axiosWrap(GET,
            system.QD_API_URL +
            "question-group-templates/" +
            id)
            .then((response) => {
                return response.data;
            });
    }


    // Get the current group for specified templatekey
    static getCurrentGroup(templatekey) {
        return axiosWrap(GET, system.QD_API_URL + "question-group-templates/" + templatekey + "/current")
            .then((response) => {
                return response.data;
            });
    }


    static createGroupTemplate(groupTemplate) {
        return axiosWrap(POST,
            system.QD_API_URL +
            "question-group-templates/", groupTemplate)
            .then((response) => {
                    return response.data;
                }
            );
    }


    static saveGroupTemplate(groupTemplate) {
        return axiosWrap(PUT,
            system.QD_API_URL +
            "question-group-templates/" +
            groupTemplate.templateKey, groupTemplate)
            .then((response) => {
                    return response.data;
                }
            );
    }

    //</editor-fold>
    // -----------------------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------------------
    // Questions !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
    // -----------------------------------------------------------------------------------------------------------------


    // Get the current group for specified templatekey
    static getCurrentQuestion(templatekey) {
        return axiosWrap(GET, system.QD_API_URL + "question-templates/" + templatekey + "/current")
            .then((response) => {
                return response.data;
            });
    }

    // Get the specific group by ID
    static getQuestionById(id) {
        return axiosWrap(GET,
            system.QD_API_URL +
            "question-templates/" +
            id)
            .then((response) => {
                return response.data;
            });
    }


    static getQuestionnaireList() {
        return axiosWrap(GET,system.QD_API_URL +
            "questionnaire-templates/latest")
            .then((response) => {
                return response.data;
            });
    }


    static saveQuestionTemplate(questionTemplate) {
        return axiosWrap(PUT, system.QD_API_URL + "question-templates/" + questionTemplate.templateKey, questionTemplate)
            .then((response) => {
                return response.data;
            });
    }


    static saveQuestionTemplateTags(questionTemplate) {
        return axiosWrap(PUT, system.QD_API_URL + "question-templates/tags/" + questionTemplate.templateKey, questionTemplate)
            .then((response) => {
                return response.data;
            });
    }


    static createQuestionTemplate(questionTemplate) {
        return axiosWrap(POST, system.QD_API_URL + "question-templates", questionTemplate)
            .then((response) => {
                return response.data;
            });
    }


    //</editor-fold>
    // -----------------------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------------------
    // Actions !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
    // -----------------------------------------------------------------------------------------------------------------

    static getActionById(id) {
        return axiosWrap(GET,
            system.QD_API_URL + "questionnaire-action-templates/" + id)
            .then((response) => {
                return response.data;
            });
    }


    static getCurrentAction(actionKey) {
        return axiosWrap(GET,
            system.QD_API_URL + "questionnaire-action-templates/" + actionKey + "/current")
            .then((response) => {
                return response.data;
            });
    }


    static saveActionTemplate(actionTemplateObject) {
        return axiosWrap(PUT, system.QD_API_URL + "questionnaire-action-templates/" + actionTemplateObject.templateKey
            , actionTemplateObject)
            .then((response) => {
                return response.data;
            });
    }


    static createActionTemplate(actionTemplateObject) {
        return axiosWrap(POST, system.QD_API_URL + "questionnaire-action-templates"
            , actionTemplateObject)
            .then((response) => {
                return response.data;
            });
    }


    //</editor-fold>
    // -----------------------------------------------------------------------------------------------------------------

    static getActionTypes() {
        return axiosWrap(GET, system.QD_API_URL + `/questionnaire-action-types`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }


    static getSettings() {
        return axiosWrap(GET, system.QD_API_URL + `settings`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }


    static getTemplateNotes(key){
        return axiosWrap(GET, system.QD_API_URL + `questionnaire-templates/${key}/notes`)
            .then((response) => {
                return response.data;
            });
    }


    static getQuestionnaireStatistics(id){
        return axiosWrap(GET, system.QD_API_URL + `questionnaire-reference/statistic/${id}`)
            .then((response) => {
                return response.data;
            });
    }


    static getQuestionnaireStatisticsAll(termplateKey){
        return axiosWrap(GET, system.QD_API_URL + `questionnaire-reference/statistic/aggregate/${termplateKey}`)
            .then((response) => {
                return response.data;
            });
    }


    static getTemplateExport(template){
        return axiosWrap(GET, system.QD_API_URL + `questionnaire-templates/${template.id}/xml`, {responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', template.name + '.xml');
                link.click();
            });
    }


    static checkTemplateNameAndQualifierExists(templateType, name, nameQualifier) {

        let url = `${system.QD_API_URL}${templateApiMapper[templateType]}/exists?`;

        if (nameQualifier === "") {
            url = url + encodeURI(`exactName=${name}`);
        } else {
            url = url + encodeURI(`exactName=${name}&nameQualifier=${nameQualifier}`);
        }

        return axiosWrap(GET, url)
            .then((response) => {
                return response.data;
            });
    }


    static checkQuestionExists(filter) {
        let url = `${system.QD_API_URL}question-templates/exists?`;
        if (filter.templateKey) {
            url = url + `templateKey=${filter.templateKey}&`;
        }
        if (filter.name) {
            url = url + encodeURI(`exactName=${filter.name}&`);
        }

        return axiosWrap(GET, url)
            .then((response) => {
                return response.data;
            });
    }

    static checkQuestionCategoryExists(filter) {
        let url = `${system.QD_API_URL}question-category-templates/exists?`;
        if (filter.templateKey) {
            url = url + `templateKey=${filter.templateKey}&`;
        }
        if (filter.title) {
            url = url + encodeURI(`exactName=${filter.title}&`);
        }

        return axiosWrap(GET, url)
            .then((response) => {
                return response.data;
            });
    }

    static checkQuestionGroupExists(filter) {
        let url = `${system.QD_API_URL}question-group-templates/exists?`;
        if (filter.templateKey) {
            url = url + `templateKey=${filter.templateKey}&`;
        }
        if (filter.title) {
            url = url + encodeURI(`exactName=${filter.title}&`);
        }

        return axiosWrap(GET, url)
            .then((response) => {
                return response.data;
            });
    }

    // -----------------------------------------------------------------------------------------------------------------
    // TEMPLATE GENERIC !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
    // -----------------------------------------------------------------------------------------------------------------
//  + FormatPageableAndFilter(pageable, filter, mapper)
    // Get list of templates (NOTE: This is used for Tags and Integrations)
    static getTemplateList(pageable, filter, templateType) {

        const mapper = templateType === 'question' ? columnNameMapper : columnNameMapper2;

        let str = "";

        // Get all actions when pagable is NOT null
        if (pageable !== null) {
            str = FormatPageableAndFilter(pageable, filter, mapper);
        }

        return axiosWrap(GET, system.QD_API_URL + templateApiMapper[templateType] + str)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    // Get list of templates that are current (aka latest)
    static getTemplateListCurrent(pageable, filter, templateType) {

        // When getting itmes for a Select Existing DDLB the filter mode contains the templateType
        let whichTemplateType = (filter.mode === "") ? templateType : filter.mode;

        const mapper = templateType === 'question' ? columnNameMapper : columnNameMapper2;
        let str = "";

        // Get all actions when pagable is NOT null
        if (pageable !== null) {
            str = FormatPageableAndFilter(pageable, filter, mapper);
        }

        return axiosWrap(GET, system.QD_API_URL + templateApiMapper[whichTemplateType] + "/latest" + str)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    // Get list of templates that match the specified templateType templateKey (versions aka history)
    static getTemplateListVersions(pageable, filter, templateType, key) {

        let templateKey = (key === undefined) ? filter.templateKey : key;

        const mapper = templateType === 'question' ? columnNameMapper : columnNameMapper2
        return axiosWrap(GET, system.QD_API_URL + templateApiMapper[templateType] + "/"+ templateKey + "/history" + FormatPageableAndFilter(pageable, filter, mapper))
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }


    static getTemplateReferences(templateType, id) {
        return axiosWrap(GET, system.QD_API_URL + templateApiMapper[templateType] + "/" + id + "/reference")
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    //</editor-fold>
    // -----------------------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------------------
    // MISC !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
    // -----------------------------------------------------------------------------------------------------------------

    static updateScoring(scoringUpdateObj) {
        return axiosWrap(PUT, system.QD_API_URL + "questionnaire-templates/scoring", scoringUpdateObj)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    //</editor-fold>
    // -----------------------------------------------------------------------------------------------------------------
}

TemplateApi.defaultProps = {};

export default TemplateApi;
