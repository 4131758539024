import React, { useState, useEffect } from 'react';
import IconButton from "@mui/material/IconButton";


export default function ArgoIconButtonHook(props) {

    const fileName = props.fileName === undefined ? "file-name" : props.fileName;
    const fieldName = props.fieldName === undefined ? "field-name" : props.fieldName;

    const index = props.index === undefined ? "" : props.index; // If field is built in a loop index is required to know which field to match up with
    const depth = props.depth === undefined ? "" : props.depth; // Question Depth

    const uniqueIdentifier = fileName + "-icon-button-" + fieldName + index + depth;

    const size = props.size === undefined ? "medium" : props.size;

    const disabled = props.disabled === undefined ? false : props.disabled;


    const icon = props.icon;

    const color = props.color === undefined ? "black" : props.color;
    const marginTop = props.marginTop === undefined ? "0px" : props.marginTop;

    const onClick = props.onClick === undefined ? function () {} : props.onClick;


    const handleOnClick = (event) => {
        onClick(event, props.fieldName, index, depth);
    };

    return (
        <IconButton id={uniqueIdentifier}
                    name={uniqueIdentifier}
                    size={size}
                    onClick={handleOnClick}
                    disabled={disabled}
                    style={{marginTop: marginTop, color: color}}>
            {icon}
        </IconButton>
    );
}
