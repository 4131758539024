import * as types from "../constants/actionConstants";


const initialState = {

};


function integrationLogReducer(state = initialState, action) {
    switch (action.type) {
        case types.INTEGRATION_LOG_GET_SUCCESS:
            return action.integrationLog;

        default:
            return state;
    }
}

export default integrationLogReducer;