import {notificationOpen} from "../actions/notificationActions";
import {clearToken, getToken} from "../actions/loginActions";
import {QD_API_AUTH_URL} from "../constants/systemConstants";
import {logout} from "../actions/loginActions";
//import {closeDrawer} from "../actions/drawerActions";

export const errorResponse = function(error, dispatch) {
    let message = error.message;

    if (error.response && error.response.data) {
        let data = error.response.data;
        message = data.description || data.message;
    }

    console.log(message);// eslint-disable-line

    let isLoggedIn = getToken();
    if ((error.response && error.response.status === 401) || !isLoggedIn) {

        let loginMessage = (error.config.url === QD_API_AUTH_URL)?
            "The username and password combination did not match. Please try again." :
            "Session expired. Please sign in." ;

        dispatch(clearToken(loginMessage));
    }
    else if (error.response && error.response.status === 403) {
        // THis happens when a user on the server switches betweeen admin and portal
        dispatch(logout());
    }
    else {
        dispatch(notificationOpen(message, error));
    }

    throw error;
};
