import {validateQuestionNameAndQualifierUnique} from "../actions/questionEditorActions";
import * as TEMPLATE from "../constants/templateConstants";

export function templateNameUnique(props, errorMessagePrefix, name = "name", ) {

    let nameErrorText = " ";
    let nameUpper = "";

    if(props.template) nameUpper = props.template[name] === null ? "" : removeExtraWhitespace(props.template[name]).toUpperCase();
    else if (props.integration) nameUpper = props.integration[name] === null ? "" : removeExtraWhitespace(props.integration[name]).toUpperCase();

    let nameExists = false;
    props.options.validate.forEach(template => {
        if (template[name].toUpperCase() === nameUpper) {
            nameExists = true;
        }
    });

    // NOTE: All text fields max length should be set as maxLength in ArgoTextFieldHook so user can NOT enter to many characters and generate an error.

    if (nameExists) {
        nameErrorText = errorMessagePrefix + " already exists.";
    } else if (nameUpper.length === 0) {
        nameErrorText = errorMessagePrefix + " is required.";
    }

    return nameErrorText;
}


export function templateNameAndQualiferUnique(props, errorMessagePrefix) {

    const nameUpper = props.template.name === null ? "" : removeExtraWhitespace(props.template.name.toUpperCase());
    const nameQualifierUpper = props.template.nameQualifier === null ? "" : removeExtraWhitespace(props.template.nameQualifier.toUpperCase());

    let nameErrorText = " ";

    // Admin used to allow user entry of keys and later added nameQualifier, a collection is returned from
    // validateQuestionNameAndQualifierUnique http://localhost:8081/qd2/api/v1/question-templates/exists?exactName=
    // The endpoint is also used for other reasons that could return more then one item in the collection
    // ie.. Versions, Tags, types, statuses. In the spedific case for name=X & nameQualifier=Y only one template should be returned in the collection
    let nameAndQualifierExists = false;
    props.options.validate.forEach(template => {
        if ((template.name.toUpperCase() === nameUpper)&&((template.nameQualifier === null ? "" : template.nameQualifier.toUpperCase()) === nameQualifierUpper)) {
            nameAndQualifierExists = true;
        }
    });

    // NOTE: All text fields max length should be set as maxLength in ArgoTextFieldHook so user can NOT enter to many characters and generate an error.

    if (nameAndQualifierExists) {
        if (nameQualifierUpper === "") {
            nameErrorText = errorMessagePrefix + " already exists.";
        } else {
            nameErrorText = errorMessagePrefix + " and qualifier combination already exists.";
        }
    } else if (nameUpper.length === 0) {
        nameErrorText = errorMessagePrefix + " is required.";
    }

    return nameErrorText;
}


export function answerOptions (answerOption) {

    let errorText = " ";

    if (answerOption.optionText.length === 0) {
        errorText = "Answer text cannot be blank.";
    }

    return errorText;
};


/**
 * Validates metadata object for templates
 * @param entries
 * @param errorText Object to be set as the state to pass error messages down to the component.
 * @returns {boolean} True indicates that the metadata is valid.
 * If the metadata is NOT valid, then errorText.metadataErrorText will be updated and the function returns false.
 */

export function metadata(entries) {

    let keys =  Object.keys(entries);

    // check if any keys are empty
    let hasEmptyKey = keys.findIndex(key => key === "") >= 0;

    let metadataErrorText = hasEmptyKey ? "Property names cannot be blank." : " ";

    return metadataErrorText;
}


/**
 * Removed all extra whitespace from a string.
 * Words will only have a single space between them.
 * There will be no whitespace before or after characters in the string.
 * @return {string}
 */
export function removeExtraWhitespace(value) {

    if (value != null && value !== undefined) {
        let trimmedValue = value.trim();

        // Collapse multiple whitespaces in a row to a single space
        trimmedValue = trimmedValue.replace(/\s+/g, " ");

        return trimmedValue;
    } else {
        return value;
    }

}


/**
 * @return {boolean}
 */
export function notNull(value) {
    return typeof value !== 'undefined' && value && value !== -1;
}
