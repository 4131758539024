import * as React from "react";

function SvgNeutral(props) {
  return (
    <svg width={40} height={40} {...props}>
      <defs>
        <rect id="neutral_svg__a" x={0} y={0} width={36} height={36} rx={18} />
      </defs>
      <use
        fill={props.fill}
        fillOpacity={props.opacity}
        xlinkHref="#neutral_svg__a"
        transform="translate(2 2)"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgNeutral;
