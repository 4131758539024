import {format, parseISO, parse} from 'date-fns';

export function toLocale(date) {

    if (!date){
        return "";
    } else {
        return format(parseISO(date), "MM/dd/yyyy");
    }
}


// Convert date to ISO 8601 (YYYY-MM-DD) date string, accounting for current timezone
export function formatIso(date) {
    return (new Date(`${date.toDateString()} 12:00:00 +0000`)).toISOString().substring(0, 10);
}


export function formatDateDisplay(dateStr, type) {
    let jsDate = parseISO(dateStr);

    let formattedDate = "";
    if (dateStr) {
        switch (type) {
            case "Time":
            case "TIME":
                formattedDate = format(jsDate, "h:mm a");
                break;
            case "DateTime":
            case "DATETIME":
                formattedDate = format(jsDate, "MM/dd/yyyy hh:mm:ss:SSS a");
                break;
            case "TimeDate":
            case "TIMEDATE":
                formattedDate = format(jsDate, "h:mm a MM/dd/YYYY");
                break;
            case "YearMonthDay":
                formattedDate = format(jsDate,"yyyyMMdd");
                break;
            default:
                formattedDate = format(jsDate, "MM/dd/yyyy");
                break;
        }
    }

    if (formattedDate === "Invalid date") {
        formattedDate = "";
    }

    return formattedDate;
}



