import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import CategoryEditor from "../qd/categories/CategoryEditor";
import {createCategoryTemplateAndCloseDrawer, saveCategoryTemplateAndCloseDrawer} from "../../actions/categoryTemplateActions";
import {QD_URL} from "../../constants/systemConstants";
import * as TEMPLATE from "../../constants/templateConstants";
import ArgoAppBar from "../common/ArgoAppBar";
import {withRouter} from "../../utilities/withRouter";


class CategoryCreateEditDrawer extends Component {

    closeDrawer = () => {
        this.props.closeDrawer();
    };

    handleRequestSave = (categoryTemplate) => {
        if ((typeof this.props.setLoadingState === "function") && (this.props.templateMode !== "versions")) {
            this.props.setLoadingState(this.save, categoryTemplate);
        }
        else {
            this.save(categoryTemplate);
        }
    };

    save = (categoryTemplate) => {
        if (this.props.createMode) {
            this.props.createCategoryTemplateAndCloseDrawer(categoryTemplate, this.props.filter, this.onCreateSaveSuccess);
        }
        else {
            this.props.saveCategoryTemplateAndCloseDrawer(categoryTemplate, this.props.filter, this.props.versionsFilter, this.props.templateMode);
        }

    };

    onCreateSaveSuccess = () => {
        <withRouter navigate={QD_URL + "/categories"}/>;
    };

    render() {
        return (
            <div id="CategoryCreateEditDrawer-container-div" style={{height: "100%"}}>
                <ArgoAppBar
                    title={this.props.createMode ? "Create Category" : "Edit Category"}
                    showMenuIconButton={false}
                    noIcon={true}
                    isDrawer={true}
                    width={categoryCreateEditDrawerProps.width}
                />

                <div style={{height: '64px'}}></div>

                <CategoryEditor
                    createMode={this.props.createMode}
                    cancel={this.closeDrawer}
                    save={this.handleRequestSave}
                    templateKey={this.props.templateKey}
                    templateId={this.props.templateId}
                    width={categoryCreateEditDrawerProps.width}
                    scrollTo="CategoryCreateEditDrawer-container-div"
                />
            </div>
        );
    }
}

CategoryCreateEditDrawer.defaultProps = {
    createMode: false,
    templateKey: "",
    templateId: null,
    templateMode: ""
};

CategoryCreateEditDrawer.propTypes = {
    createMode: PropTypes.bool,
    setLoadingState: PropTypes.func,
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    templateMode: PropTypes.string,
    filter: PropTypes.object,
    versionsFilter: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer, createCategoryTemplateAndCloseDrawer, saveCategoryTemplateAndCloseDrawer}, dispatch);
}

function mapStateToProps(state) {
    return {
        filter: state.filter[TEMPLATE.FILTER.current.category]
    };
}

const categoryCreateEditDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCreateEditDrawer);

export {categoryCreateEditDrawerProps};
